<mat-dialog-content>
  <button class="button-close" (click)="decline()">
    <mat-icon>close</mat-icon>
  </button>
  <h2 mat-dialog-title>{{ 'delete_message' | transloco }}</h2>
  <div class="warning-container" [innerHTML]="'delete-message-warning' | transloco"></div>
  <div class="message-container">
    <div class="head-container">
      <span
        class="date">{{message.createdAt|date:dateFormatService.getFormat() : null : dateFormatService.getLocale()}}</span>
      <span class="author">{{message?.author?.name}} {{message?.author?.secondName}}</span>
    </div>
    <div>{{message.text}}</div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button type="button" color="primary" (click)="delete()">
    {{ 'delete' | transloco }}
  </button>
  <button mat-button class="modal-button" (click)="decline()">
    {{ 'cancel' | transloco }}
  </button>
</mat-dialog-actions>
