import { Action, createReducer, on } from '@ngrx/store';
import { UserSignUp, UserSignUpError, UserSignUpReset, UserSignUpSuccess } from '../../actions/user.actions';
import { ErrorResponseCreate } from '@ipnote/shared';

export interface StateSignUp {
  waitingServerResponse: boolean;
  error: ErrorResponseCreate;
  success: boolean;
}

export const initialStateSignUp: StateSignUp = {
  waitingServerResponse: false,
  error: null,
  success: null,
};

const signUpReducer = createReducer(
  initialStateSignUp,
  on(UserSignUp, (state) => ({
    ...state,
    waitingServerResponse: true,
    error: null,
  })),
  on(UserSignUpSuccess, (state) => ({
    ...state,
    success: true,
  })),
  on(UserSignUpReset, () => ({
    waitingServerResponse: false,
    error: null,
    success: null,
  })),
  on(UserSignUpError, (state, { error }) => ({
    ...state,
    waitingServerResponse: false,
    error,
  })),
);

export function signUpReducerDefault(state: StateSignUp | undefined, action: Action) {
  return signUpReducer(state, action);
}
