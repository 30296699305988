import { createActionGroup, props } from '@ngrx/store';
import { ErrorResponseCreate } from '@ipnote/shared';
import { IUpdateLogRecordEntity } from '@ipnote/interface';

export const updateHistoryActions = createActionGroup({
  source: 'Update history',
  events: {
    'Get update history': props<{ url: string }>(),
    'Get update history success': props<{ updateHistory: IUpdateLogRecordEntity[] }>(),
    'Get update history failure': props<{ error: ErrorResponseCreate }>(),
  },
});
