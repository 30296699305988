import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IServiceRequestEntity } from '@ipnote/interface';
import { EnumsService } from '../../../page-modules/desk/services/enums/enums.service';

@Component({
  selector: 'app-decline-offer-dialog',
  templateUrl: './decline-offer-dialog.component.html',
  styleUrls: ['./decline-offer-dialog.component.scss']
})
export class DeclineOfferDialogComponent implements OnInit {

  form: FormGroup;

  constructor(public enums: EnumsService, private formBuilder: FormBuilder, public dialogRef: MatDialogRef<DeclineOfferDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {
    serviceRequest: IServiceRequestEntity
  }) {
    this.form = this.formBuilder.group({
      option: [],
      text: [{ value: '', disabled: true }]
    });
  }

  ngOnInit() {
    this.form.get('option').valueChanges.subscribe(reason => {
      if (reason === 'other') {
        this.form.get('text').enable();
      } else {
        this.form.get('text').disable();
      }
    });
  }

  decline() {
    this.dialogRef.close(false);
  }

  delete() {
    this.dialogRef.close(this.form.value);
  }

}
