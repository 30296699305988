<div class="sm:w-[500px] wrapper">
  <div class="title" *ngIf="data.title">
    <h2 mat-dialog-title [innerHTML]="data.title | transloco"></h2>
  </div>
  <button class="button-close" [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
  <mat-dialog-content class="break-words" [innerHTML]="message | transloco"></mat-dialog-content>
  <mat-dialog-actions class="pt-6">
    <div class="space"></div>
    <button mat-button class="modal-button" (click)="decline()" *ngIf="data.slaveButton">
      {{ data.slaveButton }}
    </button>
    <button mat-raised-button color="primary" class="modal-button yes-button" (click)="accept()">
      <span *ngIf="data.primaryButton ">{{ data.primaryButton }}</span>
    </button>
  </mat-dialog-actions>
</div>
