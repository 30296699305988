import { HttpClient } from '@angular/common/http';
import {
  Translation,
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  TRANSLOCO_MISSING_HANDLER,
  TranslocoConfig,
  translocoConfig,
  TranslocoLoader,
  TranslocoMissingHandler,
  TranslocoModule,
  TranslocoService,
} from '@ngneat/transloco';
import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { AppConfiguration } from './configuration/app.configuration';
import { ELanguage } from '@ipnote/enum';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient, private appConfig: AppConfiguration) {}

  getTranslation(lang: string = ELanguage.EN) {
    const url = `/assets/i18n/${lang}.json`;
    return this.http.get<Translation>(url);
  }
}

@Injectable({ providedIn: 'root' })
export class TranslocoMissing implements TranslocoMissingHandler {
  data = [];

  handle(key: string): string {
    if (!this.data.includes(key)) {
      this.data.push(key);
    }
    return key;
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (transloco: TranslocoService, cfg: TranslocoConfig) => () =>
        transloco.load(cfg.defaultLang).toPromise(),
      deps: [TranslocoService, TRANSLOCO_CONFIG],
    },
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        fallbackLang: ELanguage.EN,
        availableLangs: [ELanguage.EN],
        defaultLang: ELanguage.EN,
        reRenderOnLangChange: true,
        prodMode: environment.production,
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    { provide: TRANSLOCO_MISSING_HANDLER, useClass: TranslocoMissing },
  ],
})
export class TranslocoRootModule {}
