import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ITaskEntity } from '@ipnote/interface';

@Injectable({
  providedIn: 'root',
})
export class ChangeTaskService {
  subject$: BehaviorSubject<ITaskEntity> = new BehaviorSubject(null);
  updateTaskSubject = new Subject<void>();

  constructor() {}

  // Метод для подписки на изменения
  get updateTask$() {
    return this.updateTaskSubject.asObservable();
  }

  triggerUpdateTask() {
    this.updateTaskSubject.next();
  }
}
