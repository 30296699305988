export enum OIPSubStage {
  START = 'start',
  SUBSTANTIVE_REQUEST = 'substantive_request',
  FORMAL_EXAMINATION_REQUEST = 'formal_examination_request', // alias refusal of grant
  DO_NOT_TRACK = 'do_not_track',

  // TODO: delete rest sub-stages
  BEFORE_END = 'before_end',
  COULD_BE_CLOSED = 'could_be_closed',
  CLOSED_BUT_CAN_BE_RESTORED = 'closed_but_can_be_restored',

  CLOSED = 'closed',
  RESTORE = 'restore',
  DENIAL = 'denial',
  DENIAL_DISPUTE = 'denial_dispute',
  FINALLY_DENIAL = 'finally_denial',
  WITHDRAW = 'withdraw',
  WITHDRAW_DISPUTE = 'withdraw_dispute',
  FINALLY_WITHDRAWN = 'finally_withdrawn',

  PAYED = 'payed',
}
