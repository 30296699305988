import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-rename-dialog',
  templateUrl: './rename-dialog.component.html',
  styleUrls: ['./rename-dialog.component.scss']
})
export class RenameDialogComponent implements OnInit {
  form: UntypedFormGroup = this.fb.group({
    name: [null, Validators.required]
  });

  constructor(
    public dialogRef: MatDialogRef<RenameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { label: string; value?: string; title?: string },
    private fb: UntypedFormBuilder
  ) {
    this.form.get('name').patchValue(data.value);
  }

  ngOnInit() {
  }

  set() {
    this.dialogRef.close(this.form.get('name').value);
  }
}
