import { Injectable } from '@angular/core';
import { FileType } from '../../../../app-common/dataset/FileType';
import {
  ActionNameEnum,
  BusinessModels,
  ChatFilterEnum,
  ChatType,
  CompanyTypeEnum,
  CounterpartyTaxSystem,
  CounterpartyType,
  Country,
  Currency,
  InventionStages,
  InventionTypes,
  MarkifyMarkFeature,
  MarkifySearchMode,
  OIPDocumentType,
  OIPStage,
  OIPType,
  Period,
  PriceDetailsType,
  ProductStages,
  ProductTypes,
  Protocols,
  PublicPageSteps,
  ReasonOptionEnum,
  RIAAccessMode,
  RIAStage,
  RIASubType,
  RIAType,
  RightTransitionBasics,
  ServiceName,
  StrategyModels,
  TaskFilterEnum,
  TaskStages,
  Territory,
  TrademarkWatchFrequency,
  TrademarkWatchStatus,
  TransactionStage,
  TransactionType,
  TriggerNameEnum,
  TwoFAErrorType,
} from '@ipnote/enum';
import { FolderTypes, InputFolderTypes, OutputFolderTypes } from '@ipnote/type';

@Injectable({
  providedIn: 'root',
})
export class EnumsService {
  oipType = OIPType;
  chatType = ChatType;
  oipDocumentType = OIPDocumentType;
  oipStage = OIPStage;
  folderTypes: FileType[];
  inputFolderTypes = InputFolderTypes;
  outputFolderTypes = OutputFolderTypes;
  counterpartyType = CounterpartyType;
  taxSystems = CounterpartyTaxSystem;
  taskStages = TaskStages;
  riaStages = RIAStage;
  countries = Country;
  territories = Territory;
  protocols = Protocols;

  riaTypes = RIAType;
  riaSubTypes = RIASubType;
  riaAccessModes = RIAAccessMode;
  rightTransitionBasics = RightTransitionBasics;
  productTypes = ProductTypes;
  productStages = ProductStages;
  inventionTypes = InventionTypes;
  inventionStages = InventionStages;
  businessModels = BusinessModels;
  strategyModels = StrategyModels;

  currencies = Currency;
  transactionStages = TransactionStage;
  transactionTypes = TransactionType;
  serviceNames = ServiceName;
  reasonOptionEnum = ReasonOptionEnum;
  companyTypeEnum = CompanyTypeEnum;
  typeChat = ChatFilterEnum;
  taskFilter = TaskFilterEnum;
  publicPageSteps = PublicPageSteps;
  priceDetails = PriceDetailsType;
  period = Period;
  twoFAErrorType = TwoFAErrorType;
  markType = MarkifyMarkFeature;
  trademarkWatchFrequency = TrademarkWatchFrequency;
  trademarkWatchStatus = TrademarkWatchStatus;
  markifySearchMode = MarkifySearchMode;
  triggerNameEnum = TriggerNameEnum;
  actionNameEnum = ActionNameEnum;

  constructor() {
    this.folderTypes = Object.values(FolderTypes).map((value) => {
      return {
        name: value + '_docs',
        value,
        subTypes: Object.values(this[value + 'FolderTypes']),
      };
    });
  }
}
