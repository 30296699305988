import { Injectable } from '@angular/core';
import { RequestQueryBuilder } from '@rewiko/crud-request';
import { forEach } from 'lodash';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { selectStateSelectedCompany } from '#selectors';
import { concatMap, map, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequestQueryBuilderService {
  constructor(
    private store: Store<AppState>
  ) {
  }

  buildRequest(params): Observable<{ [p: string]: string | number | boolean }> {
    return of(params).pipe(
      concatMap(p => of(p).pipe(
        withLatestFrom(this.store.select(selectStateSelectedCompany))
      )),
      map(([param, company]) => {
        const qb = new RequestQueryBuilder();
        if (param.relations && param.relations.length) {
          param.relations.forEach((item) => {
            qb.setJoin({ field: item });
          });
        }
        let searchQuery = Object.create(null);

        if (params?.filter) {
          searchQuery = params.filter
          qb.search(searchQuery);
        }

        if (param.searchToken && param.searchFields) {
          if (!searchQuery?.$and) {
            console.log('create search')
            searchQuery = {$and: []}
          }
          const or = {$or:[]}
          param.searchFields.forEach((searchField) => {
            or.$or.push({
              [searchField]: {
                $contL: param.searchToken
              }
            })
          });
          searchQuery.$and.push(or)
          qb.search(searchQuery);
        }
        if (param.pageSize) {
          qb.setLimit(param.pageSize);
        }
        if (param.pageIndex || param.pageIndex === 0) {
          qb.setPage(param.pageIndex);
        }
        if (param.sort) {
          const parts = param.sort.split(',');
          parts.forEach((part) => {
            const order = part.substring(0, 1) === '-' ? 'DESC' : 'ASC';
            const field = part.replace('-', '');
            qb.sortBy({ field, order });
          });
        }
        if (param.additionalOptions) {
          forEach(param.additionalOptions, (value, key) => {
            qb.queryObject[key] = value;
          });
        }

        if (param.filterObject) {
          forEach(param.filterObject, (value) => {
            qb.setFilter(value);
          });
        }
        return qb.queryObject;
      })
    );
  }
}
