import { Component, Input, OnInit } from '@angular/core';
import { ITaskPricePart } from '@ipnote/interface';
import { PriceDetailService } from '../../../../@vex/services/price-detail.service';

@Component({
  selector: 'app-price-detail',
  templateUrl: './price-detail.component.html',
  styleUrls: ['./price-detail.component.scss']
})
export class PriceDetailComponent implements OnInit {
  @Input() prices: ITaskPricePart[];

  constructor(public priceService: PriceDetailService) {
  }

  ngOnInit(): void {
  }

}
