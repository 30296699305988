import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { catchError, map, of, switchMap } from 'rxjs';
import { UpdateHistoryService } from 'app/app-common/services/update-history/update-history.service';
import { updateHistoryActions } from '../actions';

@Injectable()
export class UpdateHistoryEffects {
  getUpdateHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateHistoryActions.getUpdateHistory),
      switchMap(({ url }) => {
        return this.updateHistoryService.getUpdateHistory(url).pipe(
          map((resp) => {
            return updateHistoryActions.getUpdateHistorySuccess({ updateHistory: resp.data });
          }),
          catchError((error) => {
            return of(updateHistoryActions.getUpdateHistoryFailure({ error }));
          }),
        );
      }),
    );
  });

  constructor(private actions$: Actions, private updateHistoryService: UpdateHistoryService) {}
}
