import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LobbyComponent } from './lobby.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IconModule } from '@visurel/iconify-angular';
import { LobbyRoutingModule } from './lobby-routing.module';
import { AppCommonModule } from '../../app-common/app-common.module';
import { TranslocoModule } from '@ngneat/transloco';
import { SafePipeModule } from 'safe-pipe';
import { SignUpComponent } from './modules/sign-up/sign-up.component';
import { ConfirmEmailComponent } from './modules/confirm-email/confirm-email.component';
import { EmailConfirmationComponent } from './modules/email-confirmation/email-confirmation.component';
import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { SignInComponent } from './modules/sign-in/sign-in.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ForgotPasswordConfirmationComponent } from './modules/forgot-password-confirmation/forgot-password-confirmation.component';
import { CreateFirstCompanyComponent } from './modules/create-first-company/create-first-company.component';
import { CompanyCreatorModule } from '../desk/modules/company-info/company-creator/company-creator.module';
import { MatRadioModule } from '@angular/material/radio';

import { PersonalFieldComponent } from './modules/sign-up/personal-field/personal-field.component';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatGridListModule } from '@angular/material/grid-list';
import { SetUpTwoFactorComponent } from './modules/set-up-two-factor/set-up-two-factor.component';
import { TwoFactorVerificationService } from './modules/set-up-two-factor/two-factor-verification.service';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { CreateTaskHeaderTimeLineComponent } from '../ai-create-task/create-task-header-time-line/create-task-header-time-line.component';
import { IdpSignInComponent } from './modules/idp-sign-in/idp-sign-in.component';
import { FirstPageHeaderComponent } from './modules/first-page-header/first-page-header.component';

@NgModule({
  declarations: [
    LobbyComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    ConfirmEmailComponent,
    EmailConfirmationComponent,
    ForgotPasswordConfirmationComponent,
    CreateFirstCompanyComponent,
    PersonalFieldComponent,
    SetUpTwoFactorComponent,
    IdpSignInComponent,
  ],
  providers: [TwoFactorVerificationService],
  exports: [PersonalFieldComponent, ConfirmEmailComponent, SignInComponent],
  imports: [
    CommonModule,
    AppCommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatSnackBarModule,
    IconModule,
    MatTooltipModule,
    MatButtonModule,
    MatCheckboxModule,
    LobbyRoutingModule,
    TranslocoModule,
    SafePipeModule,
    MatProgressSpinnerModule,
    CompanyCreatorModule,
    MatRadioModule,
    FormsModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatGridListModule,
    NgxIntlTelInputModule,
    CreateTaskHeaderTimeLineComponent,
    FirstPageHeaderComponent,
  ],
})
export class LobbyModule {}
