import { Component, Inject } from '@angular/core';
import { CreateTaskHeaderTimeLineComponent } from '../../../../page-modules/ai-create-task/create-task-header-time-line/create-task-header-time-line.component';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router, RouterLinkWithHref } from '@angular/router';
import { ICompanyEntity } from '@ipnote/interface';
import { Observable } from 'rxjs';
import { selectStateSelectedCompany } from '#selectors';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';

@Component({
  selector: 'app-ai-task-created',
  templateUrl: './ai-task-created.component.html',
  styleUrls: ['./ai-task-created.component.scss'],
  imports: [CreateTaskHeaderTimeLineComponent, MatButtonModule, RouterLinkWithHref],
  standalone: true,
})
export class AiTaskCreatedComponent {
  taskId = this.data.taskId;
  selectedCompany: ICompanyEntity;
  selectedCompany$: Observable<ICompanyEntity> = this.store.select(selectStateSelectedCompany);

  constructor(
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<AiTaskCreatedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { taskId: any },
    private router: Router,
  ) {
    this.selectedCompany$.subscribe((company) => {
      this.selectedCompany = company;
    });
  }

  rewriteTask() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/desk', this.selectedCompany?.id, 'tasks', this.taskId, 'offers']),
    );
    window.open(url, '_blank');
    this.dialogRef.close(null);
  }
}
