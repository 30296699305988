<div style="position: relative; margin: 8px">
  <div>
    <button class="button-close" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div>
    <div class="title-video">
      {{title | transloco}}
    </div>
    <!--    <youtube-player-->
    <!--      [videoId]="id"-->
    <!--      width="830px"-->
    <!--      height="467px"-->
    <!--      [playerVars]="{'controls':1}"-->
    <!--    ></youtube-player>-->
  </div>
  <div>
