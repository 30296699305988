import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import icMail from '@iconify/icons-ic/twotone-mail';
import { filter, map, scan, take, takeWhile } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, timer } from 'rxjs';
import { Store } from '@ngrx/store';
import { StateServerRes } from 'app/store/reducers/user';
import { selectForgotPasswordRequestState } from 'app/store/selectors/user.selectors';
import { fadeInUp400ms } from '@vex/animations/fade-in-up.animation';
import { AuthService } from 'app/app-common/services/auth/auth.service';
import { DialogService } from 'app/app-common/services/dialogs/dialog.service';
import { SetTitleService } from 'app/app-common/services/set-title/set-title.service';
import { AppState } from '#appState';
import { setFormError } from 'app/app-common/utils/set-form-error';
import { UserForgotPassword, UserForgotPasswordError } from 'app/store/actions/user.actions';



@UntilDestroy()
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: [fadeInUp400ms]
})
export class ForgotPasswordComponent {
  public forgotPasswordStore$: Observable<StateServerRes> = this.store.select(selectForgotPasswordRequestState);

  form = new UntypedFormGroup({
    email: new UntypedFormControl(null, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'))
  });

  icMail = icMail;
  seconds;

  constructor(
    private router: Router,
    private auth: AuthService,
    private dialogs: DialogService,
    public titleService: SetTitleService,
    private store: Store<AppState>
  ) {
    this.titleService.setTitle('ipnote_title');

    this.forgotPasswordStore$.pipe(
      filter((state) => state.isSendingRecoveryPassword),
      take(1),
      map((state) => {
        if (!state.error) {
          this.seconds = timer(0, 1000).pipe(
            scan(acc => --acc, 10),
            takeWhile(x => {
              if (x === 0) {
                this.router.navigateByUrl('/auth/log-in');
              }
              return x >= 0;
            })
          );
        } else {

          if (typeof state.error.error.message !== 'string') {
            const formatError = setFormError(this.form, state.error.error.message);
            this.form = formatError.form;
          } else {
            this.dialogs.signInError(state.error).subscribe(dialogResult => {
              this.store.dispatch(UserForgotPasswordError({ error: null }));
            });
          }
        }
      })
    ).subscribe();
  }

  send(): void {
    this.store.dispatch(UserForgotPassword({ email: this.form.value.email }));
  }
}
