<div class="wrapper">
  <div class="title">
    <h2 class="line-clamp-2 whitespace-normal" mat-dialog-title>Are you certain you would like to decline the offer from
      "{{data.serviceRequest.executorCompany.name}}"?</h2>
  </div>
  <button class="button-close" [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
  <div class="content">
    <div class="content-title">
      Kindly indicate the reason for rejecting this offer:
    </div>
    <form [formGroup]="form">
      <mat-radio-group color="primary" class="radio-group" formControlName="option">
        <mat-radio-button [value]="enums.reasonOptionEnum.HIGH_COST">High cost</mat-radio-button>
        <mat-radio-button [value]="enums.reasonOptionEnum.DUE_TO_DELAYS">Due to delays</mat-radio-button>
        <mat-radio-button [value]="enums.reasonOptionEnum.NOT_A_DIRECT_PROVIDER">Not a direct provider
        </mat-radio-button>
        <mat-radio-button [value]="enums.reasonOptionEnum.COMMUNICATION_CHALLENGES">Communication challenges
        </mat-radio-button>
        <mat-radio-button [value]="enums.reasonOptionEnum.DISSATISFIED_WITH_THE_CUSTOMER_SERVICE">Dissatisfied with the
          customer service
        </mat-radio-button>
        <div class="otherReason">
          <mat-radio-button [value]="enums.reasonOptionEnum.OTHER" class="button">Other</mat-radio-button>
          <input formControlName="text" class="other-reason-input" type="text">
        </div>
      </mat-radio-group>
    </form>
  </div>
  <mat-dialog-actions>
    <button mat-button (click)="decline()">
      CANCEL
    </button>
    <button mat-raised-button color="primary"
            [disabled]="form.get('option').value === null || (form.get('option').value === 'other' && form.get('text').value.length <= 0)"
            (click)="delete()">
      DECLINE
    </button>
  </mat-dialog-actions>
</div>
