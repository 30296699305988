<mat-dialog-content>
  <h2 mat-dialog-title>{{ 'invite' | transloco }}</h2>
  <form [formGroup]="form" (ngSubmit)="save()">
    <mat-form-field>
      <mat-label>{{ 'role' | transloco }}</mat-label>
      <mat-select formControlName="role" required>
        <mat-option *ngFor="let type of types" [value]="type">{{type + '_role' | transloco}}</mat-option>
      </mat-select>
    </mat-form-field>
    <ng-container [ngSwitch]="form?.controls?.role?.value">
      <div *ngSwitchCase="userRole.COMPANY_MANAGER">
        <mat-form-field>
          <mat-label>{{ 'manager_role_label_select' | transloco }}</mat-label>
          <mat-select [(value)]="selectedManagerId" required [disableOptionCentering]="true">
            <mat-option id="taskOwnerList" *ngFor="let u of usersManager" [value]="u.id">
              {{u?.name}} {{u?.secondName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngSwitchDefault>
        <mat-form-field class="example-full-width">
          <input matInput [matAutocomplete]="auto" formControlName="email" placeholder="Email">
          <button *ngIf="selectedMovie" matSuffix mat-icon-button aria-label="Clear" (click)="clearSelection()">
            <mat-icon>close</mat-icon>
          </button>
          <button *ngIf="isLoading" matSuffix mat-icon-button>
            <mat-spinner [diameter]="15" class="mx-auto"></mat-spinner>
          </button>
          <mat-autocomplete [panelWidth]="400" #auto="matAutocomplete" (optionSelected)="onSelected($event)">
            <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
            <ng-container *ngIf="!isLoading">
              <mat-option *ngFor="let user of filteredUsers" [value]="user">
                <span><b>{{user.name}}</b> ({{user.email}})</span>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>

        <ng-container *ngIf="form.controls.email.valid">
          <mat-form-field>
            <mat-label>{{ 'user_name' | transloco }}</mat-label>
            <input matInput formControlName="name" required>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'second-name' | transloco }}</mat-label>
            <input matInput formControlName="secondName" required>
          </mat-form-field>
        </ng-container>
      </div>
    </ng-container>

  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button type="button" color="primary" (click)="save()"
          [disabled]="(form.invalid || form.disabled || form.pristine) && (selectedManagerId === null)">
    {{ 'invite' | transloco }}
  </button>
</mat-dialog-actions>

