import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { UserSignInGoogleCallback } from 'app/store/actions/user.actions';
import { DialogService } from '../../../app-common/services/dialogs/dialog.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { selectSignInState } from '../../../store/selectors/user.selectors';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class GoogleResolver implements Resolve<boolean> {
  constructor(private store: Store<AppState>, private dialogs: DialogService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    this.store.dispatch(UserSignInGoogleCallback({ params: route.queryParams }));
    this.store
      .select(selectSignInState)
      .pipe(
        filter((state) => state.error !== null),
        untilDestroyed(this),
      )
      .subscribe((res) => this.dialogs.error(res.error));
    return of(true);
  }
}
