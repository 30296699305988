<div @fadeInUp class="mt-4 flex items-center flex-col justify-center set-up-two-factor">
  <ipnote-first-page-header></ipnote-first-page-header>
  <ng-container *ngIf="step === TwoFactorSteps.SET_UP_TWO_FACTOR">
    <h2 class="text-center font-bold text-lg alert">
      Hey <span class="text-primary-500">{{ userState.user.email }}</span
      >,
    </h2>
    <h2 class="text-center font-bold text-lg mb-4 alert">Set up 2 factor authentication</h2>
    <span class="text-center mb-3 set-up-two-factor__add-text">
      Our commitment to safeguarding your IP has led us to upgrade our security measures with two-factor authentication.
      To further protect your account, please provide your phone number
    </span>
    <form #f="ngForm" [formGroup]="phoneForm" class="w-full my-4">
      <app-phone-field-control
        [required]="true"
        [countryCode]="userState?.user?.phone?.countryCode"
        formControlName="phone"
      >
      </app-phone-field-control>
    </form>
    <button
      class="w-full set-up-two-factor__button"
      [ngClass]="{'white-text': !(phoneForm?.invalid || loading)}"
      [disabled]="phoneForm?.invalid || loading"
      mat-raised-button
      color="primary"
      (click)="send(VerificationCodeType.SMS)"
    >
      Continue
      <mat-icon *ngIf="loading">
        <mat-spinner color="primary" diameter="20"></mat-spinner>
      </mat-icon>
    </button>
    <div class="mt-2 w-full flex justify-end set-up-two-factor__add-text">
      <span (click)="logOut()" class="cursor-pointer text-primary-500"> Not {{ userState?.user?.name }}?</span>
    </div>
  </ng-container>

  <ng-container *ngIf="step === TwoFactorSteps.VERIFICATION">
    <h2 class="font-bold text-lg mb-4">Verification</h2>

    <ng-container *ngIf="codeType === VerificationCodeType.SMS">
      <span class="text-center mb-3 set-up-two-factor__add-text">
        Please enter the code we’ve sent to {{ phoneForm?.controls?.phone?.value?.e164Number }}
      </span>
      <div class="relative">
        <mat-form-field class="w-[80px]" appearance="outline">
          <input
            class="text-center"
            maxlength="4"
            type="email"
            [formControl]="verificationCode"
            matInput
            placeholder="- - - -"
          />
        </mat-form-field>
        <ng-container *ngIf="isInvalidVerificationCode">
          <mat-error class="absolute bottom-[5px] left-[-10px] whitespace-nowrap"> Incorrect code </mat-error>
        </ng-container>
      </div>
      <div class="flex justify-around">
        <button
          mat-button
          color="primary"
          [disabled]="isTimerOn"
          (click)="send(VerificationCodeType.SMS)"
        >Resend code</button>
        <button
          mat-button
          color="primary"
          [disabled]="isTimerOn"
          (click)="setStep(TwoFactorSteps.SET_UP_TWO_FACTOR)"
        >Edit number</button>
      </div>
      <span *ngIf="isTimerOn" class="set-up-two-factor__add-text">
        Resend code in {{ minutes | number : '2.0-0' }}:{{ seconds | number : '2.0-0' }}
      </span>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="showSkipTwoFABlock()">
    <div class="skip-2fa-block text-center">
      <div class="mt-14">You can delay this step three times for two days each;</div>
      <div>
        afterward, phone verification is required.
        <button class="skip-2fa-button" (click)="skipTwoFA()">Skip now</button>.
      </div>
    </div>
  </ng-container>
</div>
