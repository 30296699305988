import { ErrorResponseCreate } from '@ipnote/shared';
import { Action, createReducer, on } from '@ngrx/store';
import { UserConfirmEmail, UserConfirmEmailError, UserConfirmEmailSuccess } from '../../actions/user.actions';
import { IUserEmailConfirmationRes } from '@ipnote/interface';

export interface StateConfirmEmail {
  waitingServerResponse: boolean;
  error: ErrorResponseCreate;
  serverRes: IUserEmailConfirmationRes;
}

export const initialStateConfirmEmail: StateConfirmEmail = {
  waitingServerResponse: false,
  error: null,
  serverRes: null
};

const confirmEmail = createReducer(
  initialStateConfirmEmail,
  on(UserConfirmEmail, (state) => ({
    ...initialStateConfirmEmail,
    waitingServerResponse: true
  })),
  on(UserConfirmEmailSuccess, (state, { result }) => ({
    ...initialStateConfirmEmail,
    serverRes: result
  })),
  on(UserConfirmEmailError, (state, { error }) => ({
    ...initialStateConfirmEmail,
    waitingServerResponse: false,
    error
  }))
);

export function confirmEmailReducerDefault(state: StateConfirmEmail | undefined, action: Action) {
  return confirmEmail(state, action);
}
