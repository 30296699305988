<div class="idp-sign-in flex flex-col space-y-2 md:flex-nowrap md:space-x-2 md:space-y-0 md:flex-row mt-4">
  <button type="button"
          class="idp-sign-in__button google-button w-full md:w-1/2 flex justify-center flex-nowrap items-center"
          (click)="signInGoogle()"
  >
    <mat-icon class="mr-2" [icIcon]="googleIcon"></mat-icon>
    <span class="whitespace-nowrap">Sign in with Google</span>
  </button>
  <button type="button"
          class="idp-sign-in__button linkedIn-button w-full md:w-1/2 flex justify-center flex-nowrap items-center"
          (click)="signInLinkedIn()"
  >
    <mat-icon class="mr-2" [icIcon]="linkedInIcon"></mat-icon>
    <span class="whitespace-nowrap">Sign in with LinkedIn</span>
  </button>
</div>
