import * as company from './reducers/company/index';
import { ActionReducerMap } from '@ngrx/store';

export interface StateCompany {
  companyList: company.StateCompanyList | null;
  companySelect: company.StateCompanySelect | null;
  companyCreate: company.StateCompanyCreate | null;
}

export const reducers: ActionReducerMap<StateCompany> = {
  companyList: company.companyListReducerDefault,
  companySelect: company.companySelectReducerDefault,
  companyCreate: company.companyCreateReducerDefault
};
