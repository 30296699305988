import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseUrlService } from '../../../app-common/services/base-url/base-url.service';
import { Observable } from 'rxjs';
import { Paged } from '../../../dataset/Paged';
import { finalize, flatMap, map, mergeMap } from 'rxjs/operators';
import { SearchInterface } from '../../../app-common/services/search.interface';
import {
  RequestQueryBuilderService
} from '../../../app-common/services/request-query-builder/request-query-builder.service';
import { concatLatestFrom } from '@ngrx/effects';
import { SpinnerViewService } from 'app/app-common/services/spinner/spinner-view.service';

export class ApiSearcher<T> implements SearchInterface {
  constructor(private apiService: ApiService<T>, private filter: any) {
  }

  search(token: string, additionalOptions?: object): Observable<T[]> {
    return this.apiService.getAll(0, 20, null, token, this.filter, null, additionalOptions).pipe(map((res) => res.data || []));
  }

  getAll($in: string[]): Observable<T[]> {
    const items = [];
    return this.apiService.getAll(0, 1000, null, null, { _id: { $in } }, null, null).pipe(
      map((res) => {
        res.data.map((item) => {
          items.push({ object: item });
        });
        return items || [];
      })
    );
  }
}

export class ApiService<T> {
  constructor(
    public http: HttpClient,
    public baseUrl: BaseUrlService,
    public queryBuilder: RequestQueryBuilderService,
    private allUrl: string,
    private entityUrl: string,
    public searchFields: string[],
    public spinnerService: SpinnerViewService
  ) {
  }

  getAll(
    pageIndex: number,
    pageSize: number,
    sort: string,
    searchToken: string,
    filter: object,
    relations: string[],
    additionalOptions?: object,
  ): Observable<Paged<T>> {
    return this.baseUrl.getBaseUrl(this.allUrl).pipe(
      concatLatestFrom(() => [
        this.queryBuilder.buildRequest(
          { pageIndex, pageSize, sort, searchFields: this.searchFields, searchToken, filter, additionalOptions}
        )
      ]),
      mergeMap(([url, params]) => {
        return this.http.get<Paged<T>>(url, { params })
      })
    );
  }

  get(id: number, companyId?: string): Observable<T> {
    return this.baseUrl.getBaseUrl(`${this.entityUrl}/${id}`).pipe(
      concatLatestFrom(() => [
        this.queryBuilder.buildRequest({ companyId })
      ]),
      flatMap(([url, params]) => this.http.get<T>(url, { params }))
    );
  }

  update(id: number, body: any, companyId?: number): Observable<T> {
    this.spinnerService.start()
    return this.baseUrl.getBaseUrl(`${this.entityUrl}/${id}`).pipe(
      concatLatestFrom(() => [
        this.queryBuilder.buildRequest({ companyId })
      ]),
      mergeMap(([url, params]) => this.http.patch<T>(url, body, { params })),
      finalize(() => this.spinnerService.stop())
    );
  }

  create(body: any, companyId?: number): Observable<T> {
    this.spinnerService.start();
    return this.baseUrl.getBaseUrl(this.entityUrl).pipe(
      concatLatestFrom(() => [
        this.queryBuilder.buildRequest({ companyId })
      ]),
      mergeMap(([url, params]) => this.http.post<T>(url, body, { params })),
      finalize(() => this.spinnerService.stop())
    );
  }

  delete(id: number, body?: any) {
    this.spinnerService.start()
    return this.baseUrl.getBaseUrl(`${this.entityUrl}/${id}`).pipe(
      mergeMap((url) => this.http.request<T>('delete', url, { body })),
      finalize(() => this.spinnerService.stop())
    );
  }

  export(): Observable<HttpResponse<Blob>> {
    return this.baseUrl
      .getBaseUrl(`${this.allUrl}/export`)
      .pipe(
        concatLatestFrom(() => [
          this.queryBuilder.buildRequest({})
        ]),
        flatMap(([url, params]) => this.http.get(url, { params, observe: 'response', responseType: 'blob' }))
      );
  }

  import(file: File) {
    return this.baseUrl.getBaseUrl(`${this.allUrl}/import`).pipe(
      flatMap((url) => {
        const fileData = new FormData();
        fileData.append('Content-Type', file.type);
        fileData.append('file', file);
        return this.http.post(url, fileData);
      })
    );
  }

  searcher(filter: any = null): SearchInterface {
    return new ApiSearcher(this,  filter);
  }
}
