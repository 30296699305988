<div @fadeInUp [formGroup]="form" class="form personal-field">
  <div class="flex md:space-x-4 flex-col md:flex-row">
    <mat-form-field class="md:w-1/2">
      <mat-label>{{ "user_name" | transloco }}</mat-label>
      <input formControlName="name" matInput required/>
      <app-form-error [formError]="form.controls['name']?.errors"
                      [errorTextRequired]="'name_is_required' | transloco"
                      [showError]="form.controls['name'].invalid && form.controls['name'].touched">
      </app-form-error>
    </mat-form-field>

    <mat-form-field class="md:w-1/2">
      <mat-label>{{ "second-name" | transloco }}</mat-label>
      <input formControlName="secondName" matInput required/>
      <app-form-error [formError]="form.controls['secondName']?.errors"
                      [errorTextRequired]="'last_name_is_required' | transloco"
                      [showError]="form.controls['secondName'].invalid && form.controls['secondName'].touched">
      </app-form-error>
    </mat-form-field>
  </div>

  <div formGroupName="company" class="flex md:space-x-4 flex-col md:flex-row">
    <mat-form-field class="md:w-1/2">
      <mat-label>{{ "company-name" | transloco }}</mat-label>
      <input formControlName="name" matInput required/>
      <app-form-error [formError]="form.controls.company['controls']['name']?.errors"
                      [errorTextRequired]="'Company name is required' | transloco"
                      [showError]="form.controls.company['controls']['name']?.invalid && form.controls.company['controls']['name'].touched">
      </app-form-error>
    </mat-form-field>

    <mat-form-field class="md:w-1/2">
      <mat-label>{{ 'Company country' | transloco }}</mat-label>
      <mat-select #countrySelect formControlName="country">
        <mat-select-trigger>{{ countrySelect.value | transloco }}</mat-select-trigger>
        <mat-option>
          <ngx-mat-select-search
            [formControl]="countrySearch"
            [placeholderLabel]="'search' | transloco"
            [noEntriesFoundLabel]="'not-found' | transloco"
          ></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
          {{country | transloco}}
        </mat-option>
      </mat-select>
      <app-form-error [formError]="form.controls.company['controls']['country']?.errors"
                      [errorTextRequired]="'Country is required' | transloco"
                      [showError]="form.controls.company['controls']['country']?.invalid && form.controls.company['controls']['country'].touched">
      </app-form-error>
    </mat-form-field>
  </div>
  <div class="flex md:space-x-4 flex-col md:flex-row">
    <div class="w-full md:w-1/2">
      <app-phone-field-control [required]="true" formControlName="phone"></app-phone-field-control>
    </div>
    <div class="w-full md:w-1/2">
      <mat-form-field class="w-full" aria-autocomplete="none">
        <mat-label>{{ "Work email" | transloco }}</mat-label>
        <input formControlName="email" matInput required/>
        <app-form-error [formError]="form.controls['email']?.errors"
                        [errorTextRequired]="'Email address is required' | transloco"
                        [showError]="form.controls['email'].invalid && form.controls['email'].touched">
        </app-form-error>
      </mat-form-field>
    </div>
  </div>

  <mat-form-field>
    <mat-label>{{ "password" | transloco }}</mat-label>
    <input [type]="inputType" formControlName="password" matInput required/>
    <button (click)="toggleVisibility()" mat-icon-button matSuffix type="button"
            matTooltip="{{ 'toggle-visibility' | transloco }}">
      <mat-icon *ngIf="visible" [icIcon]="icVisibility"></mat-icon>
      <mat-icon *ngIf="!visible" [icIcon]="icVisibilityOff"></mat-icon>
    </button>
  </mat-form-field>

  <div class="-mt-3"><span
    [ngClass]="form.controls['password'].invalid && form.controls['password'].touched? 'required red-text':'required'"
    class="more_character text-xs">{{'use_more_characters' | transloco}}</span></div>

  <button
    class="btn-icon__spinner personal-field__button w-full mt-4 h-[40px]"
    [ngClass]="{'white-text': !(form.invalid || (signUpStore$ | async).waitingServerResponse)}"
    mat-raised-button
    type="button"
    [disabled]="form.invalid || (signUpStore$ | async).waitingServerResponse"
    (click)="send()"
  >
    {{ joinButtonText }}
    <mat-spinner *ngIf="(signUpStore$ | async).waitingServerResponse" [diameter]="25"></mat-spinner>
  </button>

  <div class="flex mt-4 footer-block space-x-4 personal-field__add-text">
    <div class="continue" (click)="changeCompanyType()">Continue as
      a {{ companyType === 'customer' ? 'service provider' : 'customer' }}</div>

    <div>
      <span class="pr-1">{{ "already-have-an-account" | transloco }}</span>
      <a class="cursor-pointer" *ngIf="sideNavMode; else elseBlock"
         (click)="stepsHandler.emit(publicPageSteps.SIGN_IN)">{{ "sign-in-here" | transloco }}</a>
      <ng-template #elseBlock>
        <a [routerLink]="['/auth/log-in']">{{ "sign-in-here" | transloco }}</a>
      </ng-template>
    </div>
  </div>
</div>
