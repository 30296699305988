import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompaniesService } from '../../../page-modules/desk/services/companies/companies.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ICompanyEntity } from '@ipnote/interface';

@UntilDestroy()
@Component({
  selector: 'app-invite-company-dialog',
  templateUrl: './invite-company-dialog.component.html',
  styleUrls: ['./invite-company-dialog.component.scss']
})
export class InviteCompanyDialogComponent {
  selectCompany: ICompanyEntity;
  search: string;
  companies: ICompanyEntity[];

  constructor(
    private cd: ChangeDetectorRef,
    public dialogRef: MatDialogRef<InviteCompanyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number,
    private companiesService: CompaniesService
  ) {
    this.getCompanies();
  }

  getCompanies(searchFilter?: string): void {
    this.companiesService
      .getServiceProvider(this.data, null, searchFilter || null)
      .pipe(
        untilDestroyed(this)
      )
      .subscribe((companies) => {
        this.companies = companies;
        this.detect();
      });
  }

  detect(): void {
    this.cd.detectChanges();
  }

  save(): void {
    this.dialogRef.close(this.selectCompany.id);
  }
}
