import { Injectable } from '@angular/core';
import { DialogService } from '../dialogs/dialog.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { HttpErrorResponse } from '@angular/common/http';

@UntilDestroy()
@Injectable()
export class ErrorService {
  constructor(
    private dialogs: DialogService,
    private matDialog: MatDialog,
    private _snackBar: MatSnackBar,
    private store: Store<AppState>,
    private translocoService: TranslocoService,
  ) {}

  handleError(err: HttpErrorResponse, fromTask = false, callback = () => {}): void {
    const code = err.status;
    switch (code) {
      case 402:
        this.dialogs.updateSubscription(err, fromTask, callback);
        break;
      default:
        this.dialogs.error(err);
    }
  }
}
