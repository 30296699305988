import { TaskFlowDialogComponent } from '../task-flow-dialog/task-flow-dialog.component';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { ServiceRequestsService } from 'app/page-modules/desk/services/service-requests/service-requests.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ErrorService } from '../../services/error-service/error-service';
import icStar from '@iconify/icons-bi/star-fill';
import icAccountStart from '@iconify/icons-mdi/account-star';
import { ICompanyEntity, IServiceRequestEntity, ITaskEntity } from '@ipnote/interface';
import { DialogService } from '../../services/dialogs/dialog.service';
import { PriceDetailService } from '../../../../@vex/services/price-detail.service';
import { RelationsService } from '../../services/links/relations.service';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { selectStateSelectedCompany } from '#selectors';
import { EnumsService } from '../../../page-modules/desk/services/enums/enums.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import checkCircle from '@iconify/icons-material-symbols/check-circle';
import { AnalyticsEventEnum, CompanyPublicInfoStage } from '@ipnote/enum';
import { CompaniesService } from '../../../page-modules/desk/services/companies/companies.service';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-confirm-conditions-button',
  templateUrl: './confirm-conditions-button.component.html',
  styleUrls: ['./confirm-conditions-button.component.scss'],
})
export class ConfirmConditionsButtonComponent {
  public selectedCompany$: Observable<ICompanyEntity> = this.store.select(selectStateSelectedCompany);
  selectedCompany;
  icAccountStart = icAccountStart;
  checkCircle = checkCircle;
  icStar = icStar;
  @Input() task: ITaskEntity;
  @Input() position: string;
  executors: ICompanyEntity[];
  @Input() nameKey = 'confirm-conditions';
  @Input() buttonStyle = 'card-action';
  @Input() executorPlaceholder: string;
  @Input() showChoiceExecutor = false;
  isLoading: boolean;
  @Output() showConversation = new EventEmitter<IServiceRequestEntity>();
  @Output() rejectHandler = new EventEmitter<IServiceRequestEntity>();
  @Output() acceptHandler = new EventEmitter<IServiceRequestEntity>();
  @Output() updateTaskHandler = new EventEmitter();
  @Output() savePotential = new EventEmitter<any[]>();
  isOpen = false;
  @ViewChild(TaskFlowDialogComponent, { static: true }) dropdown: TaskFlowDialogComponent;
  @ViewChild('ref') ref: ElementRef<HTMLElement>;
  activeTab = 'potential';
  selectedExecutors: ICompanyEntity[] = [];

  allSelected = false;
  companyPublicEnums = CompanyPublicInfoStage;

  constructor(
    private serviceRequestsService: ServiceRequestsService,
    private errorService: ErrorService,
    public dialogs: DialogService,
    public priceService: PriceDetailService,
    private relationsService: RelationsService,
    private cd: ChangeDetectorRef,
    private store: Store<AppState>,
    public enums: EnumsService,
    private analyticsService: AnalyticsService,
    private companiesService: CompaniesService,
  ) {
    this.selectedCompany$.pipe(untilDestroyed(this)).subscribe((company) => {
      this.selectedCompany = company;
    });
  }

  isPublicProfilePublished(sr: IServiceRequestEntity): boolean {
    return sr?.executorCompany.companyPublicInfoStage === this.companyPublicEnums.PUBLISHED;
  }

  get isOfferExists(): boolean {
    if (!this.serviceRequests || !this.serviceRequests.length) {
      return false;
    }
    return !!this.serviceRequests.filter((sr) => sr.offer?.price).length;
  }

  get serviceRequests(): IServiceRequestEntity[] {
    return this.task?.serviceRequests;
  }

  openPublicInfo(executorCompany: ICompanyEntity, $event: any): void {
    $event.preventDefault();
    $event.stopPropagation();
    if (executorCompany.companyPublicInfoStage === 'published') {
      window.open(`public-page/company-public-info/${executorCompany.id}`);
    }
    return null;
  }

  public showDropdown($event: any) {
    this.getExecutors();
    if (this.nameKey === 'choice-author') {
      // if task has no service request
      this.showChoiceExecutor = true;
    } else {
      this.showChoiceExecutor = false;
    }
    $event.preventDefault();
    $event.stopPropagation();
    this.dropdown.show();
    if (this.nameKey === 'choice-author') {
      this.analyticsService.sendEvent(AnalyticsEventEnum.NEW_TASK_PROVIDER_OPENED, { taskId: this.task.id });
    }
  }

  acceptServiceRequest(sr: IServiceRequestEntity): void {
    this.dropdown.hide();
    this.acceptHandler.emit(sr);
  }

  getExecutors() {
    this.isLoading = true;
    const service = this.task.service?.id ? this.task.service?.id : this.task.serviceId;
    const company = this.task.company?.id ? this.task.company.id : this.task.companyId;
    if (service) {
      this.companiesService
        .getExecutors(service, company, this.task.oipType, this.task.country?.toString(), this.task.id)
        .pipe(map(({ data }) => data))
        .subscribe((res) => {
          this.executors = res;
          this.cd.detectChanges();
          this.isLoading = false;
        });
    }
  }

  rejectServiceRequest(sr: IServiceRequestEntity): void {
    const serviceRequests = this.serviceRequests.filter((serviceRequest) => {
      return serviceRequest?.id !== sr.id;
    });

    if (!serviceRequests.length) {
      this.dropdown.hide();
      this.updateTaskHandler.emit();
    }
    this.rejectHandler.emit(sr);
  }

  handleConversationClick(sr: IServiceRequestEntity): void {
    this.dropdown.hide();
    this.showConversation.emit(sr);
  }

  checkboxSelect(executor: ICompanyEntity): void {
    const index = this.selectedExecutors.findIndex((e) => {
      return e.id === executor.id;
    });
    index >= 0 ? this.selectedExecutors.splice(index, 1) : this.selectedExecutors.push(executor);
  }

  alreadyIn(executor: ICompanyEntity): boolean {
    return this.selectedExecutors.some((e) => {
      return executor.id === e.id;
    });
  }

  handleSavePotential(executors: ICompanyEntity[]): void {
    this.selectedExecutors = [];
    this.savePotential.emit(executors);
    this.dropdown.hide();
    this.showChoiceExecutor = false;
    const analyticProviders = executors.map((executor) => {
      return { providerId: executor.id, providerName: executor.name };
    });
    this.analyticsService.sendEvent(AnalyticsEventEnum.NEW_TASK_PROVIDER_REQUESTED, { choices: analyticProviders });
  }

  showOffersPanel() {
    this.showChoiceExecutor = false;
  }

  showChoiceExecutors() {
    this.showChoiceExecutor = true;
  }

  selectAll(): void {
    if (this.allSelected) {
      this.selectedExecutors = [];
      this.allSelected = false;
    } else {
      this.selectedExecutors.length = 0;
      this.executors.forEach((e) => {
        this.selectedExecutors.push(e);
      });
      this.allSelected = true;
    }
  }
}
