import { ErrorResponseCreate } from '@ipnote/shared';
import { Action, createReducer, on } from '@ngrx/store';
import { UserSignIn, UserSignInError, UserSignInSuccess } from '../../actions';

export interface StateSignIn {
  waitingServerResponse: boolean;
  error: ErrorResponseCreate;
}

export const initialStateSignIn: StateSignIn = {
  waitingServerResponse: false,
  error: null
};

const signInReducer = createReducer(
  initialStateSignIn,
  on(UserSignIn, (state) => ({
    waitingServerResponse: true,
    error: null
  })),
  on(UserSignInSuccess, (state) => ({
    ...initialStateSignIn
  })),
  on(UserSignInError, (state, { error }) => ({
    waitingServerResponse: false,
    error
  }))
);

export function signInReducerDefault(state: StateSignIn | undefined, action: Action) {
  return signInReducer(state, action);
}
