import { createFeature, createReducer, on } from '@ngrx/store';
import { markifyDBsRecencyActions, RouterNavigationStart } from '../actions';
import { IMarkifyDBsRecencyEntity } from '@ipnote/interface';

export interface IMarkifyDBsRecencyState {
  isLoading: boolean;
  error: unknown;
  data: IMarkifyDBsRecencyEntity[];
}

const initialState: IMarkifyDBsRecencyState = {
  isLoading: false,
  error: null,
  data: null,
};

const markifyDBsRecencyFeature = createFeature({
  name: 'markifyDBsRecency',
  reducer: createReducer(
    initialState,
    on(markifyDBsRecencyActions.getMarkifyDbsRecency, (state) => ({ ...state, isLoading: true })),
    on(markifyDBsRecencyActions.getMarkifyDbsRecencySuccess, (state, action) => ({
      ...state,
      isLoading: false,
      data: action.dbsRecency,
    })),
    on(markifyDBsRecencyActions.getMarkifyDbsRecencyFailure, (state, action) => ({
      ...state,
      isLoading: false,
      error: action.error,
    })),
    on(RouterNavigationStart, () => initialState),
  ),
});

export const {
  name: markifyDBsRecencyKey,
  reducer: markifyDBsRececyReducer,
  selectIsLoading: selectMarkifyDBsRecencyIsLoading,
  selectError: selectMarkifyDBsRecencyError,
  selectData: selectMarkifyDBsRecencyData,
} = markifyDBsRecencyFeature;
