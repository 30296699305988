import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from '../base-url/base-url.service';
import { mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { concatLatestFrom } from '@ngrx/effects';
import { RequestQueryBuilderService } from '../request-query-builder/request-query-builder.service';

export interface PreSignedUploadResult {
  url: string;
  fields: {
    [name: string]: any;
  };
}

@Injectable({
  providedIn: 'root',
})
export class PreSignedUploadService {
  constructor(
    private http: HttpClient,
    private baseUrl: BaseUrlService,
    private queryBuilder: RequestQueryBuilderService,
  ) {}

  sign(fileName: string, type: string, path?: string): Observable<PreSignedUploadResult> {
    console.log('encode', encodeURIComponent(fileName));
    return this.baseUrl.getBaseUrl('file-manager/pre-signed').pipe(
      concatLatestFrom(() => [
        this.queryBuilder.buildRequest({
          additionalOptions: { file: fileName, type, ...(path ? { path } : null) },
        }),
      ]),
      mergeMap(([url, params]) =>
        this.http.get<PreSignedUploadResult>(url, {
          params: params,
        }),
      ),
    );
  }
}
