import { Injectable } from '@angular/core';
import { mergeMap, Observable } from 'rxjs';
import { finalize, flatMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from '../../../../app-common/services/base-url/base-url.service';
import { IGetManyDefaultResponse, IServiceRequestEntity, ITaskEntity } from '@ipnote/interface';
import { RequestQueryBuilderService } from '../../../../app-common/services/request-query-builder/request-query-builder.service';
import { SpinnerViewService } from 'app/app-common/services/spinner/spinner-view.service';
import { IPrecalculatedPriceResInterface } from '@ipnote/interface';
import { concatLatestFrom } from '@ngrx/effects';

@Injectable({
  providedIn: 'root',
})
export class ServiceRequestsService {
  constructor(
    private spinnerService: SpinnerViewService,
    private http: HttpClient,
    private baseUrl: BaseUrlService,
    public queryBuilder: RequestQueryBuilderService,
  ) {}

  create(body: any): Observable<IServiceRequestEntity> {
    return this.baseUrl
      .getBaseUrl('service-request')
      .pipe(flatMap((url) => this.http.post<IServiceRequestEntity>(url, body)));
  }

  getPrecalculatedPrice(id: number): Observable<IPrecalculatedPriceResInterface> {
    return this.baseUrl
      .getBaseUrl(`service-request/${id}/precalculated-price`)
      .pipe(mergeMap((url) => this.http.get<IPrecalculatedPriceResInterface>(url)));
  }

  get(id: number, sort?: string, filter?: any): Observable<IGetManyDefaultResponse<IServiceRequestEntity>> {
    return this.baseUrl.getBaseUrl(`service-request?taskId=${id}`).pipe(
      concatLatestFrom(() => [
        this.queryBuilder.buildRequest({
          sort,
          filter,
        }),
      ]),
      mergeMap(([url, params]) => this.http.get<IGetManyDefaultResponse<IServiceRequestEntity>>(url, { params })),
    );
  }

  removeServiceRequest(id: number, body: any): Observable<any> {
    // for client
    return this.baseUrl
      .getBaseUrl(`service-request/${id}`)
      .pipe(flatMap((url) => this.http.request<any>('delete', url, { body })));
  }

  rejectServiceRequest(id: number, body: any): Observable<any> {
    // for provider
    return this.baseUrl
      .getBaseUrl(`service-request/${id}/reject`)
      .pipe(flatMap((url) => this.http.post<any>(url, body)));
  }

  acceptOffer(id: number, body: any): Observable<ITaskEntity> {
    this.spinnerService.start();
    return this.baseUrl.getBaseUrl(`offer/${id}/accept`).pipe(
      mergeMap((url) => this.http.put<ITaskEntity>(url, body)),
      finalize(() => this.spinnerService.stop()),
    );
  }

  // @ts-ignore
  delete(id: number): Observable<Relation> {
    // @ts-ignore
    return this.baseUrl.getBaseUrl(`relation/${id}`).pipe(flatMap((url) => this.http.delete<Relation>(url)));
  }
}
