import { createReducer, on } from '@ngrx/store';
import { MarkifySearchResultObject } from '@ipnote/interface';
import * as TrademarkSearchActions from '../actions/trademark-search.actions';

export interface TrademarkSearchState {
  id: number | null;
  result: MarkifySearchResultObject[] | null;
}

export const initialState: TrademarkSearchState = {
  id: null,
  result: null,
};

export const trademarkSearchReducer = createReducer(
  initialState,
  on(TrademarkSearchActions.loadTrademarkSearchResult, (state, { id, result }) => ({
    ...state,
    id,
    result,
  })),
  on(TrademarkSearchActions.updateTrademarkSearchResult, (state, { updatedResult }) => {
    if (state.result) {
      const updatedResults = state.result.map((result) =>
        result.applicationNumber === updatedResult.applicationNumber ? updatedResult : result,
      );
      return {
        ...state,
        result: updatedResults,
      };
    }
    return state;
  }),
  on(TrademarkSearchActions.clearTrademarkSearchResult, (state) => ({
    ...initialState,
  })),
);
