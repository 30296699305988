import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from '../services/auth/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { Observable } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { selectStateUser } from '../../store/selectors/user.selectors';
import { environment } from '../../../environments/environment';
import { NAME_KEY_THROTTLER } from '@ipnote/const';

@Injectable({
  providedIn: 'root'
})
export class SetKeyThrottlerInterceptor implements HttpInterceptor {

  constructor(
    private auth: AuthService,
    private store: Store<AppState>
  ) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return this.store.select(selectStateUser).pipe(
      take(1),
      map(
        (userState) =>
          !userState.isAuthenticated && (
            req.url.includes(environment.baseUrl) ||
            req.url.includes(environment.chatsBaseUrl) ||
            req.url.includes(environment.authBaseUrl)
          ) && userState.keyThrottler !== null
            ? req.clone({
              headers: req.headers.set(
                NAME_KEY_THROTTLER,
                userState.keyThrottler
              )
            })
            : req
      ),
      mergeMap(newReq => next.handle(newReq))
    );
  }
}
