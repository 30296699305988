import { Component } from '@angular/core';
import linkedInIcon from '@iconify/icons-logos/linkedin-icon';
import googleIcon from '@iconify/icons-logos/google-icon';
import { UserSignInGoogle, UserSignInLinkedIn } from '../../../../store/actions/user.actions';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';

@Component({
  selector: 'app-idp-sign-in',
  templateUrl: './idp-sign-in.component.html',
  styleUrls: ['./idp-sign-in.component.scss'],
})
export class IdpSignInComponent {
  protected readonly linkedInIcon = linkedInIcon;
  protected readonly googleIcon = googleIcon;
  constructor(private store: Store<AppState>) {}

  signInLinkedIn() {
    this.store.dispatch(UserSignInLinkedIn());
  }

  signInGoogle() {
    this.store.dispatch(UserSignInGoogle());
  }
}
