import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseUrlService } from '../base-url/base-url.service';
import { Observable } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { Paged } from '../../../dataset/Paged';
import { Link } from '../../../dataset/Link';
import { ICompanyEntity } from '@ipnote/interface';

@Injectable({
  providedIn: 'root'
})
export class RelationsService {
  constructor(private http: HttpClient, private baseUrl: BaseUrlService) {
  }

  getAll(object: number, filter: any = null): Observable<any[]> {
    let params = new HttpParams().set('object', object.toString());
    if (filter) {
      params = params.set('filter', JSON.stringify(filter));
    }
    return this.baseUrl.getBaseUrl('relations').pipe(
      flatMap((url) => this.http.get<Paged<any>>(url, { params })),
      map((p) => p.data)
    );
  }

  getAllForEntity<T>(id: number, entity: string): Observable<Link[]> {
    return this.getAll(id, { $or: [{ toModel: entity }, { fromModel: entity }] }).pipe(
      map((relations) =>
        relations.map((rel) => {
          const object = rel.fromModel === entity ? rel.from : rel.to;
          const { meta, _id } = rel;
          return { object, meta, _id };
        })
      )
    );
  }

  // getExecutors<T>(service: number, company: number, oipType: string, country: string, taskId: number): Observable<ICompanyEntity[]> {
  //   let params = new HttpParams().set('serviceId', service.toString()).set('companyId', company.toString());
  //   if (oipType) {
  //     params = params.set('oipType', oipType);
  //   }
  //   if (country) {
  //     params = params.set('country', country);
  //   }
  //   if (taskId) {
  //     params = params.set('taskId', taskId);
  //   }
  //
  //   return this.baseUrl.getBaseUrl('companies/executors').pipe(
  //     flatMap((url) =>
  //       this.http.get<ICompanyEntity[]>(url, { params })
  //     )
  //   );
  // }

  create(body: any): Observable<any> {
    return this.baseUrl.getBaseUrl('relation').pipe(flatMap((url) => this.http.post<any>(url, body)));
  }

  update(id: string, body: any): Observable<any> {
    return this.baseUrl.getBaseUrl(`relation/${id}`).pipe(flatMap((url) => this.http.put<any>(url, body)));
  }

  delete(id: string): Observable<any> {
    return this.baseUrl.getBaseUrl(`relation/${id}`).pipe(flatMap((url) => this.http.delete<any>(url)));
  }

  get(id: string): Observable<any> {
    return this.baseUrl.getBaseUrl(`relation/${id}`).pipe(flatMap((url) => this.http.get<any>(url)));
  }
}
