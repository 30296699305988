import { DashboardTaskCategory, TasksTypeData } from '../enums/dashboard-task-category.enum';

export const DASHBOARD_TASK_DATA: TasksTypeData[] = [
  {
    title: DashboardTaskCategory.SELECTION,
    data: [],
  },
  {
    title: DashboardTaskCategory.PAYMENT,
    data: [],
  },
  {
    title: DashboardTaskCategory.IN_WORK,
    data: [],
  },
  {
    title: DashboardTaskCategory.ACCEPTANCE,
    data: [],
  },
];
