import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import icLogo from '@iconify/icons-ic/baseline-apartment';
import { Router } from '@angular/router';
import { ICompanyEntity, IUserEntity, IUserMeRes, IUserSignInRes } from '@ipnote/interface';

@Component({
  selector: 'app-avatar-photo',
  templateUrl: './avatar-photo.component.html',
  styleUrls: ['./avatar-photo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarPhotoComponent implements OnInit {
  constructor(private router: Router, private cdr: ChangeDetectorRef) {}

  icLogo: any = icLogo;

  @Input() user: IUserMeRes | IUserSignInRes | IUserEntity;
  @Input()
  public company: ICompanyEntity;

  @Input()
  public displayType: 'rect' | 'circle' = 'circle';

  public initials: string;
  public circleColor: string;
  @Input() imgUrl: string;
  @Input() name: string;

  private colors = [
    '#EB7181', // red
    '#468547', // green
    '#FFD558', // yellow
    '#3670B2', // blue
    '#145FAC', // blue
  ];

  ngOnInit() {}

  ngOnChanges() {
    if (this.imgUrl) {
      return;
    }
    if (this.name) {
      this.createInitials(this.name);
    }
    if (this.company?.logo) {
      this.imgUrl = this.company.logo;
      this.cdr.detectChanges();
      return;
    }
    if (this.user?.avatar) {
      this.imgUrl = this.user.avatar;
      this.cdr.detectChanges();
      return;
    }

    if (!this.company?.logo && !this.user?.avatar) {
      const name = this.company ? this.company?.name : this.user?.name;
      this.createInitials(name);
      this.cdr.detectChanges();
    }
  }

  openPublicProfile($event: any) {
    $event.stopPropagation();
    $event.preventDefault();
    if (this.company?.id) {
      window.open(
        this.router.createUrlTree(['/public-page/company-public-info', this.company.id]).toString(),
        '_blank',
      );
    }
  }

  private createInitials(name: string): void {
    let initials = '';
    for (let i = 0; i < name?.length; i++) {
      if (name.charAt(i) === ' ') {
        continue;
      }
      if (name.charAt(i) === name.charAt(i).toUpperCase()) {
        initials += name.charAt(i);
        if (initials?.length === 2) {
          break;
        }
      }
    }
    const index = name?.charCodeAt(0) % this.colors.length;
    this.circleColor = this.colors[index];
    this.initials = initials;
  }
}
