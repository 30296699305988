import { Injectable } from '@angular/core';
import { ServerSentEventsService } from './server-sent-events.service';
import { SSE } from 'sse.js';

@Injectable({
  providedIn: 'root'
})
export class ListenSseSubscriptionService {
  constructor(
    private sseService: ServerSentEventsService
  ) {
  }

  public async listen(): Promise<void> {
    const source = new SSE('http://localhost:3000/sse-subscription');
    source.addEventListener('message', (e) => {
      // Assuming we receive JSON-encoded data payloads:
      const payload = JSON.parse(e.data);
    });
    source.stream();

    // const s = await this.sseService.getEventSourceWithGet('http://localhost:3000/sse-subscription', {test: 'hei server'});
    // s.addEventListener('status', (e) => {
    //   console.log('System status is now: ' + e.data);
    // });
    // s.stream();
  }
}
