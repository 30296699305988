import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StateCompanyCreate, StateCompanyList, StateCompanySelect } from '../reducers/company';


export const selectStateCompanyList = createFeatureSelector<StateCompanyList>('companyList');

export const stateCompanySelect = createFeatureSelector<StateCompanySelect>('companySelect');
export const selectStateSelectedCompany = createSelector(
  stateCompanySelect,
  (state) => {
    return state.companySelect;
  });

export const selectStateCompanyCreate = createFeatureSelector<StateCompanyCreate>('companyCreate');
