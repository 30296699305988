import { ChangeDetectorRef, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';

@Component({
  selector: 'app-edit-password-dialog',
  templateUrl: './edit-password-dialog.component.html',
  styleUrls: ['./edit-password-dialog.component.scss']
})
export class EditPasswordDialogComponent {
  form: UntypedFormGroup = this.fb.group({
    oldPassword: [null, Validators.required],
    newPassword: [null, Validators.required]
  });
  inputTypeNewPassword = 'password';
  inputTypeOldPassword = 'password';
  visibleNewPassword = false;
  visibleOldPassword = false;
  icVisibility = icVisibility;
  icVisibilityOff = icVisibilityOff;

  constructor(
    public dialogRef: MatDialogRef<EditPasswordDialogComponent>,
    private fb: UntypedFormBuilder, private cd: ChangeDetectorRef) {
  }

  save() {
    this.dialogRef.close({
      oldPassword: this.form.get('oldPassword').value,
      newPassword: this.form.get('newPassword').value
    });
  }

  toggleVisibility(field: string) {
    if (this['visible' + field]) {
      this['inputType' + field] = 'password';
      this['visible' + field] = false;
      this.cd.markForCheck();
    } else {
      this['inputType' + field] = 'text';
      this['visible' + field] = true;
      this.cd.markForCheck();
    }
  }
}
