import { Component, OnInit } from '@angular/core';
import icCheck from '@iconify/icons-akar-icons/circle-check-fill';
import { MatDialogRef } from '@angular/material/dialog';
import { StripeService } from '../../../../page-modules/desk/services/stripe/stripe.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { ICompanyEntity } from '@ipnote/interface';
import { AppState } from '#appState';
import { Store } from '@ngrx/store';
import { selectStateSelectedCompany } from '#selectors';
import { DialogService } from '../../../services/dialogs/dialog.service';
import { AnalyticsEventEnum } from '@ipnote/enum';

@UntilDestroy()
@Component({
  selector: 'app-free-plan',
  templateUrl: './free-plan.component.html',
  styleUrls: ['./free-plan.component.scss'],
})
export class FreePlanComponent implements OnInit {
  icCheck = icCheck;
  loading: boolean;
  selectedCompany$ = this.store.select(selectStateSelectedCompany);
  selectedCompany: ICompanyEntity;
  stripePrice = 1490;
  priceLoading = false;

  constructor(
    public dialogRef: MatDialogRef<FreePlanComponent>,
    private stripeService: StripeService,
    private analyticsService: AnalyticsService,
    private store: Store<AppState>,
    private dialogs: DialogService,
  ) {
    this.selectedCompany$.subscribe((company) => {
      this.selectedCompany = company;
    });
  }

  ngOnInit(): void {
    if (this.selectedCompany?.stripeCouponId) {
      this.priceLoading = true;
      this.stripeService
        .getCouponInfo(this.selectedCompany.stripeCouponId)
        .pipe(untilDestroyed(this))
        .subscribe(
          (coupon) => {
            this.stripePrice = this.stripePrice - coupon?.amount_off / 100;
          },
          (error) => (this.priceLoading = false),
          () => (this.priceLoading = false),
        );
    }
  }

  close(): void {
    this.dialogRef.close(null);
  }

  openStripe($event): void {
    this.loading = true;
    $event.preventDefault();
    $event.stopPropagation();
    this.stripeService
      .changeStripeSubscription({
        title: 'prime',
        period: 'years',
      })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (value) => {
          window.open(value.invoicePaymentUrl);
          this.analyticsService.sendEvent(AnalyticsEventEnum.UPSELL_CHECKOUT_STARTED, {});
        },
        error: (err) => {
          this.dialogs.error(err);
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        },
      });
    return null;
  }
}
