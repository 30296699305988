import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TransliteService {
  constructor() {
  }

  translite(text) {
    const rus: string[] = 'щ ш ч ц ю я ё ж ъ ы э а б в г д е з и й к л м н о п р с т у ф х ь  '.split(/ +/g);
    const en: string[] = 'shh sh ch cz yu ya yo zh `` y\' e` a b v g d e z i j k l m n o p r s t u f kh `  '.split(/ +/g);

    for (let x = 0; x < rus.length; x++) {
      text = text.split(rus[x]).join(en[x]);
      text = text.split(rus[x].toUpperCase()).join(en[x].toUpperCase());
    }
    return text;
  }
}
