import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChatMessage } from '@ipnote/interface';
import { DateFormatService } from '../../../../@vex/services/date-format.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-edit-message-dialog',
  templateUrl: './edit-message-dialog.component.html',
  styleUrls: ['./edit-message-dialog.component.scss']
})
export class EditMessageDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<EditMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: ChatMessage,
    public dateFormatService: DateFormatService
  ) {
    this.form.get('text').setValue(this.message.text);
  }
  form = new FormGroup({
    text: new FormControl()
  })

  edit(): any {
    this.dialogRef.close(this.form.value);
  }

  decline(): any {
    this.dialogRef.close(false);
  }
}
