<mat-dialog-content>
  <button class="button-close" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
  <div class="title">{{'choice_balance_title' | transloco}}</div>
  <div class="text">{{'choice_balance_text' | transloco}}</div>
  <div class="actions">
    <button mat-raised-button color="primary" class="payment" (click)="pay(accountType.BONUS)">
      {{'bonus_balance' |transloco}}
    </button>
    <button mat-stroked-button color="primary" class="payment" (click)="pay(accountType.MAIN)">
      {{'main_balance' |transloco}}
    </button>
  </div>
</mat-dialog-content>
