import { Component, ElementRef, Inject, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { StripeService } from '../../../page-modules/desk/services/stripe/stripe.service';
import { environment } from '../../../../environments/environment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PricingTableType } from '../../dataset/PricingTable.type';

@Component({
  selector: 'ipnote-pricing-table',
  templateUrl: './pricing-table.component.html',
  styleUrls: ['./pricing-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PricingTableComponent {
  public stripeKey = environment?.stripe?.stripeKey;
  public pricingTableId: string = undefined;
  public clientSecret: string = undefined;

  constructor(
    private stripeService: StripeService,
    public dialogRef: MatDialogRef<PricingTableComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { pricingTable: PricingTableType },
  ) {
    this.pricingTableId = environment?.stripe['pricingTable' + data.pricingTable];

    this.stripeService.createCustomerSessions().subscribe((d) => {
      this.clientSecret = d.client_secret;
    });
  }

  close(): void {
    this.dialogRef.close(null);
  }
}
