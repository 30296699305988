import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { UploadFilesPreviewComponent } from '../../../app-common/components/dialogs/upload-files-preview/upload-files-preview.component';
import { filter } from 'rxjs';

@Directive({
  selector: '[appDragDrop]',
  standalone: true,
})
export class DragDropDirective {
  @Output() files: EventEmitter<File[] | File> = new EventEmitter();

  @HostBinding('style.background') private background;

  constructor(private sanitizer: DomSanitizer, private matDialog: MatDialog) {}

  @HostListener('dragover', ['$event'])
  public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#f8fafc';
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '';
  }

  @HostListener('drop', ['$event'])
  public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '';

    const files: File[] = [];

    for (let i = 0; i < evt.dataTransfer.files.length; i++) {
      const file = evt.dataTransfer.files[i];
      //const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
      files.push(file);
    }

    if (files.length > 0) {
      this.matDialog
        .open(UploadFilesPreviewComponent, {
          width: '500px',
          data: files,
        })
        .afterClosed()
        .pipe(filter((p) => !!p))
        .subscribe((files: File[]) => {
          this.files.emit(files);
        });
    }
  }
}
