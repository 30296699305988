<h1 mat-dialog-title>Upload files</h1>
<div mat-dialog-content>
  <ng-container *ngFor="let file of data">
    <div class="flex items-center flex-nowrap justify-between mb-2">
      <div class="flex items-center flex-nowrap truncate">
        <mat-icon style="color: var(--color-primary-500)" class="mr-2 flex-shrink-0">insert_drive_file</mat-icon>
        <div class="truncate">{{file.name}}</div>
      </div>
      <div class="flex items-center flex-nowrap">
        <div class="inline-block whitespace-nowrap ml-8">{{file.size | fileSize}}</div>
        <mat-icon (click)="deleteFile(file)" style="color: var(--color-primary-500)" class="hover:bg-hover ml-2 inline-block">delete</mat-icon>
      </div>
    </div>
  </ng-container>
</div>

<div class="flex justify-between" mat-dialog-actions>
  <button mat-button type="button" (click)="fileInput.click()">Add</button>
  <div>
    <button mat-button mat-dialog-close (click)="close()">Cancel</button>
    <button mat-button mat-dialog-close (click)="send()" cdkFocusInitial>Send</button>
  </div>
</div>
<input multiple #fileInput type="file" [style.display]="'none'" (change)="fileSelected($event)">
