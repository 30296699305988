import { IEnvironment } from '../app/app-common/dataset/Environment.interface';
import packageJson from '../../../../package.json';

export const environment: IEnvironment = {
  version: packageJson.version,
  production: true,
  hostUrl: '/',
  baseUrl: 'https://api.ipnote.pro',
  appUrl: 'https://app.ipnote.pro',
  filesServerBaseUrl: 'https://api.ipnote.pro/file-manager',
  authBaseUrl: 'https://api.ipnote.pro',
  chatsBaseUrl: 'https://api.ipnote.pro',
  contentBaseUrl: 'https://content.ipnote.pro',
  termsAndServiceUrl: 'https://content.ipnote.pro/manifest.json',
  baseDomain: 'ipnote.pro',
  invoiceTemplate: '5ec618d1acdc900010e82031',
  environment: 'production',
  i18nUrl: 'https://content.ipnote.pro/i18n/{lang}.json',
  googleTag: true,
  profitwell: true,
  sentry: 'https://c33b91d54e764b77b80633eb9d356297@o1114337.ingest.sentry.io/6145797',
  posthog: {
    enable: true,
    token: 'phc_h6YNfezVrrpDNarra05STSeOruhna0Ji17Eq5i9hD69',
    api: 'https://app.posthog.com',
  },
  intercom: {
    enable: false,
    apiBase: 'https://api-iam.intercom.io',
    appId: 'di6xd7xf',
  },
  pipeDrive: {
    enable: true,
    apiToken: '5d7e3c72fb60983c58b12855548e6411bf1d1f5e',
    apiUrl: 'https://api.pipedrive.com/v1',
  },
  stripe: {
    stripeKey:
      'pk_live_51IqsGwHQkctxdv6I1KAt6EjbksElqHVy1uX3oW3kPrbeKuY9KuXMze5PcersV6JFe0nFYmGUD2LuMGEW6KSf16iX00ghuNGhgK',
    pricingTableTrademarkSearch: 'prctbl_1OzHwfHQkctxdv6IcRvbqSw7',
    pricingTablePrimeCustomer: 'prctbl_1OzHJkHQkctxdv6ITDNQ2WNU',
    pricingTablePrimeProvider: 'prctbl_1OzHqfHQkctxdv6IyCaIBv9Q',
  },
  telegramBot: 'https://t.me/ipnote_ai_bot',
};
