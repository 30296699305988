import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';
import { EnumsService } from '../../../page-modules/desk/services/enums/enums.service';

@Component({
  selector: 'app-choice-balance',
  templateUrl: './choice-balance.component.html',
  styleUrls: ['./choice-balance.component.scss']
})
export class ChoiceBalanceComponent {
  // @ts-ignore
  public accountType = this.enums.accountType;

  constructor(private dialogRef: MatDialogRef<ChoiceBalanceComponent>, private enums: EnumsService) {
  }

  close(): void {
    this.dialogRef.close(null);
  }

  pay(account): void {
    this.dialogRef.close(account);
  }
}
