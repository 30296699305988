import { Injectable } from '@angular/core';
import { SSE } from 'sse.js';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { selectStateUser } from '../../../store/selectors/user.selectors';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServerSentEventsService {
  eventSource: SSE;

  constructor(
    private store: Store<AppState>
  ) {
  }

  /**
   * Create an event source of GET request
   * @param API url
   */
  public async getEventSourceWithGet(url: string, formData: any): Promise<SSE> {
    return this.buildEventSource(url, 'GET', formData);
  }

  /**
   * Building the event source
   * @param url  API URL
   * @param meth  (POST, GET, ...etc.)
   * @param formData data
   */
  private async buildEventSource(url: string, meth: string, formData: any): SSE {
    const options = await this.buildOptions(meth, formData);
    this.eventSource = new SSE(url, options);

    // add listener
    this.eventSource.addEventListener('message', (e) => {
      return e.data;
    });

    return this.eventSource;
  }

  /**
   * close connection
   */
  public closeEventSource(): void {
    if (this.eventSource) {
      this.eventSource.close();
    }
  }

  /**
   * Récupération du token à passer dans le header de la requête
   */
  protected async checkAuthorization(): Promise<string> {
    return this.store.select(selectStateUser).pipe(
      take(1),
      map(
        (userState) => userState.isAuthenticated ? `Bearer ${userState.token.accessToken}` : ''
      )
    ).toPromise();
  }

  /**
   * Build query options
   * @param meth POST or GET
   * @param formData data
   */
  private async buildOptions(
    meth: string,
    formData: any
  ): Promise<{
    payload: any;
    method: string;
    headers: string | { Authorization: string };
  }> {
    const auth = await this.checkAuthorization();
    return {
      payload: formData,
      method: meth,
      headers: auth !== '' ? { Authorization: auth } : ''
    };
  }
}
