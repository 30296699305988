import { createAction, props } from '@ngrx/store';
import { ErrorResponseCreate } from '@ipnote/shared';
import { ICompanyEntity, ICreateCompanyDto, IGetManyDefaultResponse } from '@ipnote/interface';
import { HttpErrorResponse } from '@angular/common/http';

/***
 * Company list
 */
export const CompanyListLoad = createAction('[Company] list load data');
export const CompanyListLoadSuccess = createAction(
  '[Company] list load data success',
  props<{
    result: IGetManyDefaultResponse<ICompanyEntity>;
    userId: number;
  }>(),
);
export const CompanyListLoadError = createAction(
  '[Company] list load data error',
  props<{
    error: ErrorResponseCreate;
  }>(),
);
export const CompanyListSetData = createAction('[Company] list set data');
export const CompanyAllReset = createAction('[Company] company all reset');

/***
 *
 */
export const CompanyCreate = createAction('[Company] create', props<{ company: ICreateCompanyDto }>());
export const CompanyCreateSuccess = createAction('[Company] create success', props<{ result: ICompanyEntity }>());
export const CompanyCreateError = createAction('[Company] create error', props<{ error: ErrorResponseCreate }>());

/***
 * Company select
 */
export const LoadCompany = createAction(
  '[Company] load company',
  props<{ companyId: number; navigateToCompany?: boolean }>(),
);
export const LoadCompanySuccess = createAction(
  '[Company] load company success',
  props<{ company: ICompanyEntity; navigateToCompany?: boolean }>(),
);
export const LoadCompanyError = createAction('[Company] load company error', props<{ err: HttpErrorResponse }>());
export const CompanySelect = createAction(
  '[Company] company select',
  props<{
    company: ICompanyEntity;
    isAuth?: boolean;
    thisIsNew?: boolean;
    reloadPage?: boolean;
  }>(),
);
export const CompanySetData = createAction('[Company] company set data', props<{ company: ICompanyEntity }>());
export const CompanySelectReset = createAction('[Company] company select reset');

/***
 * Other actions
 */
export const CompanyShowModalCreate = createAction('[Company] show modal create');
export const ShowProviderOnboarding = createAction('[Company] show provider onboarding');
export const CloseProviderOnboarding = createAction('[Company] close provider onboarding');

export const CompanyCreateRedirect = createAction('[Company] create redirect');
