import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EnumsService } from '../../../page-modules/desk/services/enums/enums.service';
import { PermissionService } from '../../guards/permission.service';
import { ManagerService } from '../../../page-modules/desk/services/manager/manager.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogService } from '../../services/dialogs/dialog.service';
import { debounceTime, distinctUntilChanged, filter, finalize, flatMap, switchMap, take, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from '../../services/base-url/base-url.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { SpinnerViewService } from '../../services/spinner/spinner-view.service';
import { UserRoles } from '@ipnote/enum';

@UntilDestroy()
@Component({
  selector: 'app-invite-user-dialog',
  templateUrl: './invite-user-dialog.component.html',
  styleUrls: ['./invite-user-dialog.component.scss']
})
export class InviteUserDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<InviteUserDialogComponent>,
    private fb: UntypedFormBuilder,
    public enums: EnumsService,
    private permissionService: PermissionService,
    private managerService: ManagerService,
    private dialogs: DialogService,
    private http: HttpClient,
    private baseUrl: BaseUrlService,
    private spinnerService: SpinnerViewService
  ) {
  }

  userRole = UserRoles;
  usersManager: any[] = [];
  selectedManagerId = null;
  types = [
    UserRoles.COMPANY_READER,
    UserRoles.COMPANY_COLLABORATOR
  ];

  minLengthTerm = 3;
  searchMoviesCtrl = new FormControl();
  filteredUsers: any;
  isLoading = false;
  errorMsg!: string;
  selectedMovie: any = '';

  form: UntypedFormGroup = this.fb.group({
    name: [null, Validators.required],
    secondName: [null, Validators.required],
    email: [null, [Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'), Validators.required]],
    role: [null, Validators.required]
  });

  onSelected(value: MatAutocompleteSelectedEvent): void {
    this.form.controls.email.setValue(value.option.value.email);
    this.form.controls.name.setValue(value.option.value.name);
    this.form.controls.secondName.setValue(value.option.value.secondName);
  }

  clearSelection(): void {
    this.form.controls.email.setValue('');
    this.filteredUsers = [];
  }

  async ngOnInit(): Promise<void> {
    if (await this.permissionService.checkRole(['add-manager-company']).pipe(take(1)).toPromise()) {
      this.types.push(UserRoles.COMPANY_MANAGER);
      this.managerService
        .get()
        .pipe(
          untilDestroyed(this)
        )
        .subscribe((users) => {
            this.usersManager = users;
          },
          (err) => this.dialogs.error(err)
        );
    }

    this.form.controls.email.valueChanges
      .pipe(
        filter(res => res !== null && res.length >= this.minLengthTerm),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.errorMsg = '';
          this.filteredUsers = [];
          this.isLoading = true;
        }),
        switchMap(value => this.baseUrl.getBaseUrl('users/search-by-email').pipe(flatMap((url) => this.http.get<any[]>(url + '?search=' + value)))
          .pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
        )
      )
      .subscribe((data: any[]) => {
        this.filteredUsers = data;
      });
  }


  save(): void {
    const data = this.selectedManagerId ?
      { role: this.form.get('role').value, userId: this.selectedManagerId } :
      {
        name: this.form.get('name').value,
        secondName: this.form.get('secondName').value,
        email: this.form.get('email').value,
        role: this.form.get('role').value
      };
    this.dialogRef.close(data);
  }
}
