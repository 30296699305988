import { Injectable } from '@angular/core';
import { IFileEntity, IPublicFile } from '@ipnote/interface';
import { getEncodedUrl } from '../../common-functions';

@Injectable({
  providedIn: 'root',
})
export class FilePreviewService {
  openFile(file: IFileEntity | IPublicFile) {
    const fileType = file['mimetype']?.split('/').pop() ?? file?.type?.split('/').pop() ?? null;
    const previewFileType = ['avi', 'mp4', 'pdf', 'jpeg', 'jpg', 'gif', 'png', 'plain'];
    if (previewFileType.includes(fileType)) {
      if (file.url.includes('http')) {
        window.open(getEncodedUrl(file.url), '_blank', 'location=no,menubar=no,toolbar=no');
        return true;
      } else {
        window.open(
          `${location.protocol}//${file['bucketName']}/${file.url}`,
          '_blank',
          'location=no,menubar=no,toolbar=no',
        );
        return true;
      }
    }
    return false;
  }
}
