export class AppConfiguration {
  baseUrl: string;
  filesServerBaseUrl: string;
  contentBaseUrl: string;
  authBaseUrl: string;
  sentry: string;
  environment: string;
  hostUrl: string;
  invoiceTemplate: string;
  chatsBaseUrl: string;
  termsAndServiceUrl: string;
  appUrl: string

  constructor(data: any) {
    Object.assign(this, data);
  }
}
