<div class="wrapper">
  <div class="title">
    <h2 mat-dialog-title>Are you certain you would like to reject the task "{{data.task.title}}"?</h2>
  </div>
  <button class="button-close" [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
  <div class="content">
    <div class="content-title">
      Kindly indicate the reason for rejecting this task:
    </div>
    <form [formGroup]="form">
      <mat-radio-group color="primary" class="radio-group" formControlName="option">
        <mat-radio-button [value]="enums.reasonOptionEnum.DIFFICULT_COMMUNICATION_WITH_THE_CUSTOMER">Difficult
          communication with the customer
        </mat-radio-button>
        <mat-radio-button [value]="enums.reasonOptionEnum.NOT_WITHIN_THE_SCOPE_OF_MY_SERVICES">Not within the scope of
          my services
        </mat-radio-button>
        <mat-radio-button [value]="enums.reasonOptionEnum.TOO_SHORT_NOTICE_OR_EXPIRED_DEADLINE">Too short notice or
          expired deadline
        </mat-radio-button>
        <mat-radio-button [value]="enums.reasonOptionEnum.TOO_DELAYED_IN_TIME">Too delayed in time</mat-radio-button>
        <mat-radio-button [value]="enums.reasonOptionEnum.LATE_REPLIES">Late replies</mat-radio-button>
        <div class="otherReason">
          <mat-radio-button [value]="enums.reasonOptionEnum.OTHER" class="button">Other</mat-radio-button>
          <input formControlName="text" class="other-reason-input" type="text">
        </div>
      </mat-radio-group>
    </form>
  </div>
  <mat-dialog-actions>
    <button mat-button (click)="cancel()">
      CANCEL
    </button>
    <button mat-raised-button color="primary"
            [disabled]="form.get('option').value === null || (form.get('option').value === 'other' && form.get('text').value.length <= 0)"
            (click)="reject()">
      REJECT
    </button>
  </mat-dialog-actions>
</div>
