import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { StateUser } from '../../../store/reducers/user';
import { selectStateUser } from '../../../store/selectors/user.selectors';
import { AppState } from '#appState';
import { Store } from '@ngrx/store';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';

@UntilDestroy()
@Component({
  selector: 'app-phone-field-control',
  templateUrl: './phone-field-control.component.html',
  styleUrls: ['./phone-field-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PhoneFieldControlComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: PhoneFieldControlComponent,
    },
  ],
})
export class PhoneFieldControlComponent implements ControlValueAccessor, Validator {
  @Input() required = false;
  @Input() countryCode;
  touched = false;

  disabled = false;

  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  userState$: Observable<StateUser> = this.store.select(selectStateUser);
  userState: StateUser;
  phone = new FormControl();
  onChange = (quantity) => {};

  onTouched = () => {};

  constructor(private store: Store<AppState>, private cdr: ChangeDetectorRef) {
    this.userState$.pipe(untilDestroyed(this)).subscribe((userState) => {
      this.userState = userState;
    });
    this.phone.valueChanges.subscribe((value) => {
      this.markAsTouched();
      if (!this.disabled) {
        this.onChange(value);
      }
    });
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    const value = this.phone.value;
    if (!value && this.required) {
      this.phone.addValidators(Validators.required);
      return {
        required: true,
      };
    } else return this.phone.errors;
  }

  writeValue(value: string) {
    this.phone.setValue(value);
    this.cdr.detectChanges();
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
    disabled ? this.phone.disable() : this.phone.enable();
  }
  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
