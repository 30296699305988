import { Country, Protocols, TaskStages, Territory, TransactionStage, TransactionType } from '@ipnote/enum';
import { ITaskEntity } from '@ipnote/interface';

export interface IFrontTaskStages {
  label: string;
  color: string;
  icon: string;
}

export function trademarkCountryFilter(country: string) {
  return (
    Country[country.toUpperCase()] ||
    [Protocols.MADRID_PROTOCOL, Territory.AFRICA, Territory.ASIA, Territory.BENELUX, Territory.EUIPO].includes(
      country as Protocols | Territory,
    )
  );
}

export function patentCountryFilter(country: string): boolean {
  return ![
    Protocols.WIPO_PROOF,
    Protocols.LISBON,
    Protocols.HAGUE_PROTOCOL,
    Protocols.MADRID_PROTOCOL,
    Territory.WORLD,
    Territory.AFRICA,
    Territory.ASIA,
    Territory.BENELUX,
    Territory.EUIPO,
  ].includes(country as Protocols | Territory);
}

export function getEncodedUrl(url: string) {
  let encodedUrl = '';
  const arrUrl = url.split('/');
  const encodedPart = encodeURIComponent(arrUrl.pop()).replace(/\+/g, '%2B');
  arrUrl.push(encodedPart);
  encodedUrl = arrUrl.join('/');
  return encodedUrl;
}

export function taskStage(task: ITaskEntity): IFrontTaskStages {
  const greenColor = '#77BB38';
  const warnColor = '#E7850D';
  if ([TaskStages.DRAFT, TaskStages.CHOICE_EXECUTOR].includes(task.stage)) {
    return { label: 'New task', icon: 'watch_later', color: greenColor };
  }
  if ([TaskStages.CONFIRM].includes(task.stage)) {
    return { label: 'Selection', icon: 'watch_later', color: greenColor };
  }
  if ([TaskStages.RUNNING, TaskStages.IN_PROGRESS].includes(task.stage)) {
    if (getRefillTransaction()?.stage !== TransactionStage.COMPLETED) {
      return { label: 'Payment', icon: 'settings', color: warnColor };
    }
    return { label: 'In work', icon: 'settings', color: greenColor };
  }
  if ([TaskStages.REOPEN, TaskStages.CHECK].includes(task.stage)) {
    return { label: 'Acceptance', icon: 'check_circle', color: greenColor };
  }
  if ([TaskStages.DONE].includes(task.stage)) {
    return { label: 'Completed', icon: 'done', color: greenColor };
  }
  return { label: 'In work', icon: 'watch_later', color: greenColor };

  function getRefillTransaction() {
    return task?.transactions?.find((transaction) => transaction?.type === TransactionType.REFILL);
  }
}
export function taskStageNumber(task: ITaskEntity) {
  switch (task?.stage.toString()) {
    case TaskStages.DRAFT:
    case TaskStages.CHOICE_EXECUTOR:
      return 1;
    case TaskStages.CONFIRM:
      return 2;
    case TaskStages.RUNNING:
      return 3;
    case TaskStages.IN_PROGRESS:
      return 4;
    case TaskStages.REOPEN:
    case TaskStages.CHECK:
    case TaskStages.DONE:
      return 5;
  }
}
