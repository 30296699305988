import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent implements OnInit {
  @Input()
  formError: { [key: string]: any } | undefined;

  @Input()
  showError = true;

  @Input()
  errorTextRequired = null;

  @Input()
  errorTextMinLengtht = null;

  @Input()
  errorTextPattern = null;

  constructor() {
  }

  ngOnInit(): void {
  }
}
