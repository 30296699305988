import { ConfirmConditionsButtonComponent } from './components/confirm-conditions-button/confirm-conditions-button.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { AuthService } from './services/auth/auth.service';
import { RavenErrorHandler } from './services/error-handler/raven-error-handler';
import { ErrorInterceptorService } from './services/error-interceptor/error-interceptor.service';
import { DialogService } from './services/dialogs/dialog.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { UserPipe } from './pipes/user/user.pipe';
import { Md5Pipe } from './pipes/md5/md5.pipe';
import { GravatarPipe } from './pipes/gravatar/gravatar.pipe';
import { DiffsComponent } from './components/diffs/diffs.component';
import { UserComponent } from './components/user/user.component';
import { FileButtonComponent } from './components/file-button/file-button.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { UploadComponent } from './components/upload/upload.component';
import { IconModule } from '@visurel/iconify-angular';
import { FlexModule } from '@angular/flex-layout';
import { CountryPipe } from './pipes/country/country.pipe';
import { CountriesChipsComponent } from './components/countries-chips/countries-chips.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountryFlagPipe } from './pipes/country-flag/country-flag.pipe';
import { DateInputComponent } from './components/date-input/date-input.component';
import { RenameDialogComponent } from './components/rename-dialog/rename-dialog.component';
import { TranslocoModule } from '@ngneat/transloco';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FilterSidenavComponent } from './components/filter-sidenav/filter-sidenav.component';
import { LinksComponent } from './components/links/links.component';
import { LinksChipsComponent } from './components/links/links-chips/links-chips.component';
import { FilterFieldsPipe } from './pipes/filter-fields/filter-fields.pipe';
import { SettingsSidenavComponent } from './components/settings-sidenav/settings-sidenav.component';
import { SettingsFieldsPipe } from './pipes/settings-fields/settings-fields.pipe';
import { TransliteService } from './services/translite/translite.service';
import { RouterModule } from '@angular/router';
import { EntityTasksComponent } from './components/entity-tasks/entity-tasks.component';
import { PayerChipsComponent } from './components/payer-chips/payer-chips.component';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MultiSelectDropdownComponent } from './components/multi-select-dropdown/multi-select-dropdown.component';
import { PortalModule } from '@angular/cdk/portal';
import { RelationsPipe } from './pipes/relations/relations.pipe';
import { RelationPipe } from './pipes/relation/relation.pipe';
import { EditColumnsIconComponent } from '../ui/edit-columns-icon/edit-columns-icon.component';
import { TakePipe } from './pipes/take/take.pipe';
import { ChipsBarComponent } from './components/chips-bar/chips-bar.component';
import { EntityChipsComponent } from './components/entity-chips/entity-chips.component';
import { EntityChipsContainerComponent } from './components/entity-chips-container/entity-chips-container.component';
import { ScrollbarModule } from '@vex/components/scrollbar/scrollbar.module';
import { WithoutPipe } from './pipes/without/without.pipe';
import { LimitToPipe } from './pipes/limit-to/limitTo.pipe';
import { FileIconComponent } from '../ui/file-icon/file-icon.component';
import { ChoiceServiceButtonComponent } from './components/choice-service-button/choice-service-button.component';
import { ChoiceExecutorButtonComponent } from './components/choice-executor-button/choice-executor-button.component';
import { TakeAJobComponent } from './components/take-a-job/take-a-job.component';
import { TakeAJobDialogComponent } from './components/take-a-job-dialog/take-a-job-dialog.component';
import { TaskFlowDialogComponent } from './components/task-flow-dialog/task-flow-dialog.component';
import { EditEmailDialogComponent } from './components/edit-email-dialog/edit-email-dialog.component';
import { EditPasswordDialogComponent } from './components/edit-password-dialog/edit-password-dialog.component';
import { InviteUserDialogComponent } from './components/invite-user-dialog/invite-user-dialog.component';
import { UpdatePermissionDialogComponent } from './components/update-permission-dialog/update-permission-dialog.component';
import { CompanyPipe } from './pipes/company/company.pipe';
import { AvatarPhotoComponent } from './components/avatar-photo/avatar-photo.component';
import { SubscriptionErrComponent } from './components/subscription-err/subscription-err.component';
import { InviteCompanyDialogComponent } from './components/invite-company-dialog/invite-company-dialog.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ErrorService } from './services/error-service/error-service';
import { TransactionHistoryComponent } from './components/transaction-history/transaction-history.component';
import { DropdownDialogComponent } from './components/dropdown-dialog/dropdown-dialog.component';
import { CompanySubscriptionsComponent } from './components/company-subscriptions/company-subscriptions.component';
import { SharedModule } from '../../@new_app/app/common/modules/shared-module';
import { TruncatePipe } from './pipes/truncate/truncate';
import { ChoiceCompaniesButtonComponent } from './components/choice-companies-button/choice-companies-button.component';
import { ChoiceCompanyDialogComponent } from './components/choice-company-dialog/choice-company-dialog.component';
import { FilesComponent, FilesOrderComponent, FilesSearchComponent, FileTagsComponent } from './components/files';
import { ChoiceBalanceComponent } from './components/choice-balance/choice-balance.component';
import { DeleteMessageDialogComponent } from './components/delete-message-dialog/delete-message-dialog.component';
import { FormErrorComponent } from './components/form-error/form-error.component';
import { PermissionService } from './guards/permission.service';
import { PermissionDirective } from './guards/permission.directive';
import { GuideDialogComponent } from './components/guide-dialog/guide-dialog.component';
// import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { OnboardingVideoComponent } from './components/onboarding-video/onboarding-video.component';
import { ConfirmOfferDialogComponent } from './components/confirm-offer-dialog/confirm-offer-dialog.component';
import { PriceDetailComponent } from './components/price-detail/price-detail.component';
import { FreePlanComponent } from './components/subscription-price-dialogs/free-plan/free-plan.component';

import { FileSizePipe } from './pipes/file-size.pipe';
import { CallToActionComponent } from './components/call-to-action/call-to-action.component';
import { OnboardingPreviewComponent } from './components/onboarding-preview/onboarding-preview.component';
import { TutorialSidenavComponent } from './components/tutorial-sidenav/tutorial-sidenav.component';
import { DeleteTaskReasonDialogComponent } from './components/delete-task-reason-dialog/delete-task-reason-dialog.component';
import { DeclineOfferDialogComponent } from './components/decline-offer-dialog/decline-offer-dialog.component';
import { ProviderOnboardingPanelComponent } from './components/onboarding/provider-onboarding-panel/provider-onboarding-panel.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ShareLinkDialogComponent } from './components/onboarding/share-link-dialog/share-link-dialog.component';
import { RequestEditPublicCardComponent } from './components/request-edit-public-card/request-edit-public-card.component';
import { UploadImgComponent } from './components/company-logo/upload-img.component';
import { SpinnerViewComponent } from './components/spinner-view/spinner-view.component';
import { CommonInputDialogComponent } from './components/common-input-dialog/common-input-dialog.component';
import { CancelSubscriptionComponent } from './components/dialogs/cancel-subscription/cancel-subscription.component';
import { CollaboratorsListComponent } from './components/dialogs/calloborat-list/collaborators-list/collaborators-list.component';
import { ChangesNeededDialogComponent } from './components/changes-needed-dialog/changes-needed-dialog.component';
import { ModalUploadToFolderComponent, UploadToFolderComponent } from './components/upload-to-folder';
import { IsFolderPipe } from './components/files/files.pipe';
import { OpgSitePreviewComponent } from './components/opg-site-preview/opg-site-preview.component';
import { CustomerPricesDialogComponent } from './components/subscription-price-dialogs/customer-prices-dialog/customer-prices-dialog.component';
import { MillionPipe } from './pipes/million/million.pipe';
import { ChatAiComponent } from './components/dialogs/chat-ai/chat-ai.component';
import { SafePipeModule } from 'safe-pipe';
import { SocketIoService } from './services/socket.io/socket.io.service';
import { ShowdownModule } from 'ngx-showdown';
import { EditMessageDialogComponent } from './components/edit-message-dialog/edit-message-dialog.component';
import { NeedHelpComponent } from './components/need-help/need-help.component';
import { ChoiceUserButtonComponent } from './components/choice-user-button/choice-user-button.component';
import { YoutubePlayerModule } from '../page-modules/desk/modules/youtube-player/youtube-player.module';
import { CountryFlagComponent } from './components/country-flag/country-flag.component';
import { MtxTooltipModule } from '@ng-matero/extensions/tooltip';
import { TaskActionComponent } from './components/task-action/task-action.component';
import { UploadFilesPreviewComponent } from './components/dialogs/upload-files-preview/upload-files-preview.component';
import { HeaderComponent } from './components/header/header.component';
import { NgxMaskModule } from 'ngx-mask';
import { SendEmailDialogComponent } from './components/dialogs/send-email-dialog/send-email-dialog.component';
import { TrustpilotComponent } from './components/trustpilot/trustpilot.component';
import { ProviderRatingComponent } from './components/provider-rating/provider-rating.component';
import { ProviderReviewsComponent } from './components/provider-reviews/provider-reviews.component';
import { ProviderCompletedTasksComponent } from './components/provider-completed-tasks/provider-completed-tasks.component';
import { UserButtonComponent } from './components/user-button/user-button.component';
import { CustomerCompletedTasksComponent } from './components/customer-completed-tasks/customer-completed-tasks.component';
import { SearchIpRightControlComponent } from './components/search-ip-right-control/search-ip-right-control.component';
import { PricingTableComponent } from './components/pricing-table/pricing-table.component';
import { AvatarPhotosComponent } from './components/avatar-photos/avatar-photos.component';
import { RegistrationHelperComponent } from './components/registration-helper/registration-helper.component';
import { PhoneFieldControlComponent } from './components/phone-field-control/phone-field-control.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { CentsToCurrencyPipe } from './pipes/currency/cents-to-currency.pipe';
import { NgxFilesizeModule } from 'ngx-filesize';
import { AttachmentsLoaderComponent } from './components/attachments-loader/attachments-loader.component';
import { DragDropDirective } from '../page-modules/desk/directives/drag-drop.directive';
import { ModalUploadFileFromChatComponent } from './components/upload-to-folder/modal-upload-file-from-chat/modal-upload-file-from-chat.component';
import { StopPreventDirective } from '../page-modules/desk/directives/stop-prevent.directive';

@NgModule({
  declarations: [
    SearchIpRightControlComponent,
    UserButtonComponent,
    HeaderComponent,
    EditMessageDialogComponent,
    ChatAiComponent,
    TruncatePipe,
    AlertDialogComponent,
    AvatarPhotoComponent,
    UserPipe,
    FileSizePipe,
    CompanyPipe,
    Md5Pipe,
    GravatarPipe,
    DiffsComponent,
    UserComponent,
    FileButtonComponent,
    PaginatorComponent,
    UploadComponent,
    CountryPipe,
    CountriesChipsComponent,
    PayerChipsComponent,
    CountryFlagPipe,
    DateInputComponent,
    RenameDialogComponent,
    FilterSidenavComponent,
    LinksComponent,
    LinksChipsComponent,
    FilterFieldsPipe,
    SettingsSidenavComponent,
    SettingsFieldsPipe,
    EntityTasksComponent,
    UploadImageComponent,
    MultiSelectDropdownComponent,
    RelationsPipe,
    RelationPipe,
    EditColumnsIconComponent,
    TakePipe,
    ChipsBarComponent,
    EntityChipsComponent,
    EntityChipsContainerComponent,
    WithoutPipe,
    FileIconComponent,
    ChoiceServiceButtonComponent,
    ChoiceExecutorButtonComponent,
    ConfirmConditionsButtonComponent,
    TakeAJobComponent,
    TakeAJobDialogComponent,
    TaskFlowDialogComponent,
    EditEmailDialogComponent,
    EditPasswordDialogComponent,
    InviteUserDialogComponent,
    UpdatePermissionDialogComponent,
    LimitToPipe,
    SubscriptionErrComponent,
    InviteCompanyDialogComponent,
    TransactionHistoryComponent,
    DropdownDialogComponent,
    CompanySubscriptionsComponent,
    ChoiceCompaniesButtonComponent,
    ChoiceCompanyDialogComponent,
    FilesComponent,
    FilesOrderComponent,
    FileTagsComponent,
    FilesSearchComponent,
    ChoiceBalanceComponent,
    DeleteMessageDialogComponent,
    FormErrorComponent,
    GuideDialogComponent,
    OnboardingVideoComponent,
    ConfirmOfferDialogComponent,
    PriceDetailComponent,
    FreePlanComponent,
    CallToActionComponent,
    OnboardingPreviewComponent,
    TutorialSidenavComponent,
    DeleteTaskReasonDialogComponent,
    DeclineOfferDialogComponent,
    ProviderOnboardingPanelComponent,
    ShareLinkDialogComponent,
    RequestEditPublicCardComponent,
    UploadImgComponent,
    SpinnerViewComponent,
    CommonInputDialogComponent,
    CancelSubscriptionComponent,
    CollaboratorsListComponent,
    ChangesNeededDialogComponent,
    UploadToFolderComponent,
    ModalUploadToFolderComponent,
    IsFolderPipe,
    OpgSitePreviewComponent,
    CustomerPricesDialogComponent,
    MillionPipe,
    NeedHelpComponent,
    NeedHelpComponent,
    ChoiceUserButtonComponent,
    CountryFlagComponent,
    TaskActionComponent,
    UploadFilesPreviewComponent,
    SendEmailDialogComponent,
    TrustpilotComponent,
    ProviderRatingComponent,
    ProviderReviewsComponent,
    ProviderCompletedTasksComponent,
    CustomerCompletedTasksComponent,
    AvatarPhotosComponent,
    RegistrationHelperComponent,
    PhoneFieldControlComponent,
    PricingTableComponent,
    CentsToCurrencyPipe,
    ModalUploadFileFromChatComponent,
  ],
  providers: [
    DialogService,
    ErrorInterceptorService,
    RavenErrorHandler,
    AuthService,
    TransliteService,
    EditEmailDialogComponent,
    EditPasswordDialogComponent,
    InviteUserDialogComponent,
    UpdatePermissionDialogComponent,
    InviteCompanyDialogComponent,
    ErrorService,
    PermissionService,
    DecimalPipe,
    CurrencyPipe,
    SocketIoService,
  ],
  exports: [
    SearchIpRightControlComponent,
    HeaderComponent,
    TruncatePipe,
    IsFolderPipe,
    AvatarPhotoComponent,
    WithoutPipe,
    TakePipe,
    RelationsPipe,
    RelationPipe,
    UserPipe,
    FileSizePipe,
    CompanyPipe,
    GravatarPipe,
    DiffsComponent,
    UserComponent,
    FileButtonComponent,
    PaginatorComponent,
    UploadComponent,
    FilesComponent,
    Md5Pipe,
    CountryPipe,
    CountriesChipsComponent,
    PayerChipsComponent,
    DateInputComponent,
    FilterSidenavComponent,
    SettingsSidenavComponent,
    LinksComponent,
    LinksChipsComponent,
    FilterFieldsPipe,
    SettingsFieldsPipe,
    EntityTasksComponent,
    UploadImageComponent,
    MultiSelectDropdownComponent,
    EditColumnsIconComponent,
    ChipsBarComponent,
    EntityChipsComponent,
    EntityChipsContainerComponent,
    FileIconComponent,
    ChoiceServiceButtonComponent,
    ChoiceExecutorButtonComponent,
    ConfirmConditionsButtonComponent,
    TakeAJobDialogComponent,
    TakeAJobComponent,
    TaskFlowDialogComponent,
    LimitToPipe,
    TransactionHistoryComponent,
    DropdownDialogComponent,
    CompanySubscriptionsComponent,
    ChoiceCompaniesButtonComponent,
    ChoiceCompanyDialogComponent,
    CountryFlagPipe,
    ChoiceBalanceComponent,
    DeleteMessageDialogComponent,
    FormErrorComponent,
    OnboardingVideoComponent,
    PriceDetailComponent,
    CallToActionComponent,
    OnboardingPreviewComponent,
    TutorialSidenavComponent,
    ProviderOnboardingPanelComponent,
    UploadImgComponent,
    OpgSitePreviewComponent,
    MillionPipe,
    NeedHelpComponent,
    ChoiceUserButtonComponent,
    CountryFlagComponent,
    TaskActionComponent,
    HeaderComponent,
    TrustpilotComponent,
    ProviderRatingComponent,
    ProviderReviewsComponent,
    ProviderCompletedTasksComponent,
    UserButtonComponent,
    CustomerCompletedTasksComponent,
    AvatarPhotosComponent,
    RegistrationHelperComponent,
    ChatAiComponent,
    PhoneFieldControlComponent,
    PricingTableComponent,
    CentsToCurrencyPipe,
  ],
  imports: [
    MatRadioModule,
    MatTableModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    RouterModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    IconModule,
    FlexModule,
    MatAutocompleteModule,
    MatChipsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatInputModule,
    TranslocoModule,
    DragDropModule,
    MatSidenavModule,
    MatSelectModule,
    MatCheckboxModule,
    PortalModule,
    ScrollbarModule,
    FormsModule,
    SharedModule,
    MatExpansionModule,
    SafePipeModule,
    // NgxYoutubePlayerModule
    ShowdownModule.forRoot({ flavor: 'github', openLinksInNewWindow: true, underline: true }),
    YoutubePlayerModule,
    MtxTooltipModule,
    NgxMaskModule,
    NgxIntlTelInputModule,
    NgxFilesizeModule,
    AttachmentsLoaderComponent,
    DragDropDirective,
    PermissionDirective,
    StopPreventDirective,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppCommonModule {}
