import { Component, Input } from '@angular/core';
import { ICompanyEntity } from '@ipnote/interface';
import icStar from '@iconify/icons-bi/star-fill';
@Component({
  selector: 'app-provider-rating',
  templateUrl: './provider-rating.component.html',
  styleUrls: ['./provider-rating.component.scss'],
})
export class ProviderRatingComponent {
  icStar = icStar;
  @Input() provider: ICompanyEntity
}
