import { ActionReducerMap } from '@ngrx/store';
import { aiWidgetWindowReducer, AiWidgetWindowState } from './reducers/ai-widget/ai-widget-window.reducer';
import { AiWidgetChatReducer, AiWidgetChatState } from './reducers/ai-widget/ai-widget-chat.reducer';

export interface StateAiWidget {
  aiWindow: AiWidgetWindowState;
  aiChat: AiWidgetChatState;
}

export const reducers: ActionReducerMap<StateAiWidget> = {
  aiWindow: aiWidgetWindowReducer,
  aiChat: AiWidgetChatReducer,
};
