<div class="w-full h-full" fxLayout="column" fxLayoutAlign="center center">
  <div [@fadeInUp] class="card overflow-hidden p-2 md:max-w-[700px] md:h-auto w-full h-full">
    <div class="border rounded w-full pt-5 pb-10 px-6 block h-full md:h-auto">
      <div class="title">
        <h1>{{ 'new-company' | transloco }}</h1>
      </div>
      <app-company-creator></app-company-creator>
    </div>
  </div>
</div>
