import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';

@Component({
  selector: 'app-edit-email-dialog',
  templateUrl: './edit-email-dialog.component.html',
  styleUrls: ['./edit-email-dialog.component.scss'],
})
export class EditEmailDialogComponent implements OnInit {
  form: UntypedFormGroup = this.fb.group({
    email: [null, Validators.required],
    password: [null, Validators.required],
  });
  inputType = 'password';
  visible = false;
  emailMask = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

  icVisibility = icVisibility;
  icVisibilityOff = icVisibilityOff;

  constructor(
    public dialogRef: MatDialogRef<EditEmailDialogComponent>,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {}

  save() {
    this.dialogRef.close({ password: this.form.get('password').value, email: this.form.get('email').value });
  }

  toggleVisibility() {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }
}
