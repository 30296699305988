import { environment } from 'environments/environment';
import { io } from 'socket.io-client';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { selectStateUser } from '../../../store/selectors/user.selectors';
import { concatMap, filter, map, retry, take, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Socket, SocketOptions } from 'socket.io-client/build/esm/socket';
import { of, throwError } from 'rxjs';
import { selectStateSelectedCompany } from '#selectors';
import { ManagerOptions } from 'socket.io-client/build/esm/manager';
import { NAME_KEY_THROTTLER } from '@ipnote/const';

@Injectable({
  providedIn: 'root',
})
export class SocketIoService {
  socket: Socket;
  wsOptions: Partial<ManagerOptions & SocketOptions> = {
    autoConnect: true,
    transports: ['websocket'],
  };

  constructor(private store: Store<AppState>) {}

  setupSocketConnection() {
    this.store
      .select(selectStateUser)
      .pipe(
        concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(selectStateSelectedCompany)))),
        filter(([userState, company]) => !(!userState.isAuthenticated && !userState.keyThrottler)),
        take(1),
        map(([userState, company]) => {
          return userState.isAuthenticated
            ? io(environment.baseUrl, {
                auth: {
                  Authorization: `Bearer ${userState.token.accessToken}`,
                },
                query: {
                  companyId: company?.id.toString(),
                },
                ...this.wsOptions,
              })
            : io(environment.baseUrl, {
                query: {
                  [NAME_KEY_THROTTLER]: userState.keyThrottler,
                },
                ...this.wsOptions,
              });
        }),
      )
      .subscribe({
        next: (val) => (this.socket = val),
      });
  }
}
