import { createAction, props } from '@ngrx/store';
import { MarkifySearchResultObject } from '@ipnote/interface';

export const loadTrademarkSearchResult = createAction(
  '[Trademark Search] Load Search Result',
  props<{
    id: number;
    result: MarkifySearchResultObject[];
  }>(),
);

export const updateTrademarkSearchResult = createAction(
  '[Trademark Search] Update Search Result',
  props<{ updatedResult: MarkifySearchResultObject }>(),
);

export const clearTrademarkSearchResult = createAction('[Trademark Search] Clear Search Result');
