import { IUserMeRes } from '@ipnote/interface';
import { Action, createReducer, on } from '@ngrx/store';
import { UserProfileLoad, UserProfileLoadSuccess, UserProfileUpdateEmailSuccess } from '../../actions/user.actions';
import { IStateServerResponse } from '../../interfaces/state-server-response.interface';

export interface StateUserProfile {
  userProfile: IUserMeRes;
  stateActions: {
    profileLoad: IStateServerResponse;
    updateEmail: IStateServerResponse;
    updatePassword: IStateServerResponse;
  };
}

export const initialStateUserProfile: StateUserProfile = {
  userProfile: null,
  stateActions: {
    profileLoad: {
      waitingServerResponse: false,
      error: null
    },
    updateEmail: {
      waitingServerResponse: false,
      error: null
    },
    updatePassword: {
      waitingServerResponse: false,
      error: null
    }
  }
};

const userProfile = createReducer(
  initialStateUserProfile,
  on(UserProfileLoad, (state) => ({
    userProfile: null,
    stateActions: {
      ...state.stateActions,
      profileLoad: {
        waitingServerResponse: true,
        error: null
      }
    }
  })),
  on(UserProfileLoadSuccess, (state, { userProfile }) => ({
    userProfile,
    stateActions: {
      ...state.stateActions,
      profileLoad: initialStateUserProfile.stateActions.profileLoad
    }
  })),

  on(UserProfileUpdateEmailSuccess, (state, { payload }) => ({
    ...state,
    userProfile: {
      ...state.userProfile,
      email: payload.email
    }
  }))
);

export function userProfileReducerDefault(state: StateUserProfile | undefined, action: Action) {
  return userProfile(state, action);
}
