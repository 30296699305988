import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SetTitleService {
  constructor(
    private translocoService: TranslocoService,
    public titleService: Title
  ) {
  }

  public setTitle(keyTranslateTitle): void {
    this.titleService.setTitle(this.translocoService.translate(keyTranslateTitle));
  }
}
