import { Component } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppCommonModule } from '../../app-common/app-common.module';
import { NgForOf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import {
  CreateTaskHeaderService,
  ICreateTaskHeaderStep,
} from './create-task-header-time-line/create-task-header-service';
import { UserSetBackUrl } from '../../store/actions/user.actions';
import { Router, RouterLinkWithHref } from '@angular/router';
import { AppState } from '#appState';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { selectStateUser } from '../../store/selectors/user.selectors';
import { StateUser } from '../../store/reducers/user';
import { NAME_AI_PARAMS } from '@ipnote/const';
import { CreateTaskHeaderTimeLineComponent } from './create-task-header-time-line/create-task-header-time-line.component';
import { AnalyticsService } from '../../app-common/services/analytics/analytics.service';
import { AnalyticsEventEnum } from '@ipnote/enum';
import { DragDropDirective } from '../desk/directives/drag-drop.directive';

@UntilDestroy()
@Component({
  selector: 'app-ai-create-task',
  templateUrl: './ai-create-task.component.html',
  styleUrls: ['./ai-create-task.component.scss'],
  standalone: true,
  imports: [
    AppCommonModule,
    NgForOf,
    MatIconModule,
    RouterLinkWithHref,
    CreateTaskHeaderTimeLineComponent,
    DragDropDirective,
  ],
  providers: [
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
  ],
})
export class AiCreateTaskComponent {
  stateUser: StateUser;

  constructor(
    private store: Store<AppState>,
    private createTaskHeaderService: CreateTaskHeaderService,
    private router: Router,
    private analyticsService: AnalyticsService,
  ) {
    this.store
      .select(selectStateUser)
      .pipe(untilDestroyed(this))
      .subscribe((stateUser) => {
        this.stateUser = stateUser;

        if (this.stateUser.aiActions) {
          this.router.navigate([], { queryParams: { [NAME_AI_PARAMS]: this.stateUser.aiActions } }).then();
        }
      });

    this.createTaskHeaderService.setCreateTaskStep(ICreateTaskHeaderStep.TASK_CREATION);
  }

  async goToDesk() {
    this.store.dispatch(UserSetBackUrl({ url: null }));
    await this.analyticsService.sendEvent(AnalyticsEventEnum.SKIP_AI_ASSISTANT, {});

    if (this.stateUser?.user) {
      this.router
        .navigate(['/'], {
          queryParams: { category: 'incoming' },
        })
        .then();
    } else {
      this.router.navigate(['/auth', 'sign-up'], {}).then();
    }
  }
}
