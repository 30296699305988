import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DecimalPipe } from '@angular/common';
import { ITaskPricePart } from '@ipnote/interface';

@Injectable({
  providedIn: 'root'
})
export class PriceDetailService {
  //price: number;
  //currency: string;
  //priceInUsd: number;

  constructor(private translocoService: TranslocoService, private decimalPipe: DecimalPipe) {
  }

  getPriceDetail(prices: ITaskPricePart[], type: string) {
    if (!prices?.length) return null;
    const price = prices.find((item) => item.type === type)?.price;
    const currency = prices.find((item) => item.type === type)?.currency;
    let priceInUsd;
    if (!price || !currency) return null;
    if (currency.toLowerCase() !== 'usd') {
      priceInUsd = prices.find((item) => item.type === type)?.priceInUSD;
      return (
        this.decimalPipe.transform(price, '1.0-2', 'en-US') +
        ' ' +
        (currency) +
        ' / ' +
        this.decimalPipe.transform(priceInUsd, '1.0-2', 'en-US') +
        ' ' +
        this.translocoService.translate('usd')
      );
    }

    return (
      this.decimalPipe.transform(price, '1.0-2', 'en-US') +
      ' ' +
      (this.translocoService.translate(currency.toLowerCase()))
    );
  }
}
