import { createReducer, on } from '@ngrx/store';
import * as ChatsAction from '../actions/chats.actions';

export interface Chats {
  count: number | null;
}

export const initialState: Chats = {
  count: null,
};

export const chatsReducer = createReducer(
  initialState,
  on(ChatsAction.setChatsCount, (state, { count }) => ({
    ...state,
    count,
  })),
);
