import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from '../../../../app-common/services/base-url/base-url.service';
import {
  RequestQueryBuilderService
} from '../../../../app-common/services/request-query-builder/request-query-builder.service';
import { IServiceEntity } from '@ipnote/interface';
import { Observable } from 'rxjs';
import { Paged } from '../../../../dataset/Paged';
import { flatMap } from 'rxjs/operators';
import { RequestQueryBuilder } from '@rewiko/crud-request';
import { SpinnerViewService } from '../../../../app-common/services/spinner/spinner-view.service';

@Injectable({
  providedIn: 'root'
})
export class ServicesService extends ApiService<IServiceEntity> {
  constructor(http: HttpClient, baseUrl: BaseUrlService, queryBuilder: RequestQueryBuilderService, spinnerService: SpinnerViewService) {
    super(http, baseUrl, queryBuilder, 'services', 'services', ['name'], spinnerService);
  }

  getServices(
    pageIndex: number,
    pageSize: number,
    sort: string[],
    search: object
  ): Observable<Paged<IServiceEntity>> {
    const queryString = new RequestQueryBuilder();
    queryString.setLimit(pageSize);
    queryString.setPage(pageIndex);
    queryString.search(search);

    if (sort) {
      sort.forEach(param => {
        queryString.sortBy({ field: param, order: 'ASC' });
      });
    }
    return this.baseUrl.getBaseUrl('services').pipe(
      flatMap((url) => {
          return this.http.get<Paged<IServiceEntity>>(url + '?' + queryString.query());
        }
      ));
  }

  override delete(id: number) {
    return this.baseUrl.getBaseUrl(`company-services/areas/${id}`).pipe(flatMap((url) => this.http.delete<any>(url)));
  }

  getPublicServices(
    pageIndex: number,
    pageSize: number,
    sort: string[],
    search: object) {
    const queryString = new RequestQueryBuilder();
    queryString.setLimit(pageSize);
    queryString.setPage(pageIndex);
    queryString.search(search);

    if (sort) {
      sort.forEach(param => {
        queryString.sortBy({ field: param, order: 'ASC' });
      });
    }
    return this.baseUrl.getBaseUrl('public/services').pipe(
      flatMap((url) => {
          return this.http.get<Paged<IServiceEntity>>(url + '?' + queryString.query());
        }
      ));
  }
}
