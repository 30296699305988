export enum ReminderType {
  FILING = 'filing',
  RESPONSE_TO_OFFICE_ACTION = 'response_to_office_action',
  MAINTENANCE_RENEWAL_FEE = 'maintenance_renewal_fee',
  REQUEST_EXAMINATION = 'request_examination',
  GRANTING_FEE = 'granting_fee',
  OPPOSITION = 'opposition',
  PCT_NATIONAL_PHASE = 'pct_national_phase',
  CUSTOM = 'custom',
}
