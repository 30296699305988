import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserSetBackUrl, UserSignOut } from '../../../store/actions/user.actions';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { ErrorService } from '../error-service/error-service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private store: Store<AppState>,
    private errorService: ErrorService,
    private router: Router,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        if (httpErrorResponse.status === 401) {
          this.store.dispatch(UserSignOut());
        }
        if (httpErrorResponse.status === 419) {
          // error 2fa
          if (!this.router.url.includes('/set-up-two-factor')) {
            this.store.dispatch(UserSetBackUrl({ url: this.router.url }));
          }
          this.router
            .navigate(['/auth/set-up-two-factor'], {
              queryParams: { type: httpErrorResponse?.error?.type },
            })
            .then();
        }
        return throwError(httpErrorResponse);
      }),
    );
  }
}
