import { Action, createReducer, on } from '@ngrx/store';
import { RouterNavigationStart, RouterSetCurrentUrl, RouterSetPrevUrl } from '../actions/router.actions';

export interface StateRouter {
  urlPrev: string;
  urlCurrent: string;
}

export const initialState: StateRouter = {
  urlPrev: null,
  urlCurrent: null
};

const routerReducer = createReducer(
  initialState,
  on(RouterSetPrevUrl, (state, payload) => ({
    ...state,
    urlPrev: payload.urlPrev
  })),
  on(RouterSetCurrentUrl, (state, payload) => ({
    ...state,
    urlCurrent: payload.urlCurrent
  })),
  on(RouterNavigationStart, (state) => ({
    ...state,
    urlPrev: state.urlCurrent
  }))
);

export function reducer(state: StateRouter | undefined, action: Action) {
  return routerReducer(state, action);
}
