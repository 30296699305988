<div class="wrapper">
  <ngx-intl-tel-input class="block mt-[3px] w-full"
                      [cssClass]="'custom'"
                      [preferredCountries]="preferredCountries"
                      [enableAutoCountrySelect]="true"
                      [enablePlaceholder]="true"
                      [searchCountryFlag]="true"
                      [searchCountryField]="[
          SearchCountryField.Iso2,
          SearchCountryField.Name
        ]"
                      [selectFirstCountry]="false"
                      [selectedCountryISO]="CountryISO.UnitedStates"
                      [maxLength]="15"
                      [phoneValidation]="true"
                      [separateDialCode]="true"
                      [numberFormat]="PhoneNumberFormat.National"
                      [formControl]="phone">
  </ngx-intl-tel-input>
</div>
