import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogService } from '../../services/dialogs/dialog.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { Observable } from 'rxjs';
import { selectStateSelectedCompany } from '#selectors';
import { AppState } from '#appState';
import { Store } from '@ngrx/store';
import { ICompanyEntity } from '@ipnote/interface';
import { AnalyticsEventEnum } from '@ipnote/enum';

@Component({
  selector: 'app-call-to-action',
  templateUrl: './call-to-action.component.html',
  styleUrls: ['./call-to-action.component.scss'],
})
export class CallToActionComponent implements OnInit {
  @Input() cameFrom: 'suggest-offer' | 'view-offer';
  @Input() text: string;
  @Input() upgradeButtonText = 'Upgrade';
  @Output() upgradeEmit = new EventEmitter<boolean>();
  @Output() closeWindowEmit = new EventEmitter<MouseEvent>();
  public selectedCompany$: Observable<ICompanyEntity> = this.store.select(selectStateSelectedCompany);
  selectedCompany: ICompanyEntity;

  constructor(
    private store: Store<AppState>,
    private dialogs: DialogService,
    private analyticsService: AnalyticsService,
  ) {
    this.selectedCompany$.subscribe((company) => {
      this.selectedCompany = company;
    });
  }

  ngOnInit(): void {}

  freePlanDialog($event: MouseEvent): void {
    this.upgradeEmit.emit(true);
    this.dialogs.subscriptionPricesDialog().subscribe(() => this.closeWindowEmit.emit($event));
    this.cameFrom === 'suggest-offer'
      ? this.analyticsService.sendEvent(AnalyticsEventEnum.UPSELL_REQUESTED_SUGGEST_OFFER, {})
      : this.analyticsService.sendEvent(AnalyticsEventEnum.UPSELL_REQUESTED_VIEW_OFFER, {});
  }
}
