import { AfterViewInit, Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { delay, filter, map, mergeMap } from 'rxjs/operators';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { PublicPageSteps } from '@ipnote/enum';

@Component({
  selector: 'app-public-page',
  templateUrl: './public-page.component.html',
  styleUrls: ['./public-page.component.scss'],
})
export class PublicPageComponent implements OnInit, AfterViewInit {
  loading$: Observable<boolean> = of(false);
  @HostBinding('style.overflow') overflow;
  @ViewChild('sidenav') sidenav: MatSidenav;
  public publicPageSteps = PublicPageSteps;
  step: PublicPageSteps = this.publicPageSteps.CREATE_TASK;

  email;

  constructor(private router: Router) {}

  ngAfterViewInit(): void {
    this.sidenav._closedStream.subscribe(() => {
      this.overflow = 'unset';
    });
  }

  ngOnInit(): void {
    this.loading$ = this.router.events.pipe(
      filter(
        (e) =>
          (e instanceof NavigationStart && e.url.includes('default')) ||
          e instanceof NavigationEnd ||
          e instanceof NavigationCancel ||
          e instanceof NavigationError,
      ),
      mergeMap((e) => {
        if (e instanceof NavigationEnd) {
          return of(e).pipe(delay(800));
        }
        return of(e);
      }),
      map((e) => {
        return e instanceof NavigationStart;
      }),
    );
  }

  async openSideNav(step) {
    this.overflow = 'hidden';
    this.step = step;
    await this.sidenav.toggle();
  }

  async closeSideNav() {
    await this.sidenav.close();
    this.overflow = 'unset';
  }
}
