export enum ServiceName {
  FTO_RESEARCH = 'fto_research',
  PATENT_LANDSCAPE = 'patent_landscape',
  STATE_ART_SEARCH = 'state_art_search',
  CONCLUSION_PRESENCE_ABSENCE_VIOLATION_RIGHTS = 'conclusion_presence_absence_violation_rights',
  PATENT_PORTFOLIO_ANALYSIS = 'patent_portfolio_analysis',
  RANDOM_RESEARCH = 'random_research',
  FILING_DOCKETING_APPLICATION = 'filing_docketing_application',
  LICENSE_AGREEMENT = 'license_agreement',
  RANDOM_AGREEMENT_WITH_OIP = 'random_agreement_with_oip',
  CLAIM_SETTLEMENT_IP_RIGHTS_VIOLATION_ISSUES = 'claim_settlement_ip_rights_violation_issues',
  CLAIM_SETTLEMENT_RIGHTS_VIOLATION_ISSUES = 'claim_settlement_rights_violation_issues',
  CLAIM_RESPONSE_PETITION_DRAFTING_IP = 'claim_response_petition_drafting_ip',
  CLAIM_SETTLEMENT_CONTRACT_ISSUES = 'claim_settlement_contract_issues',
  DISPUTES_SERVICE = 'disputes_service',
  PLEDGE_RIGHT_AGREEMENT = 'pledge_right_agreement',
  FRANCHISE_AGREEMENT = 'franchise_agreement',
  TRANSFER_RIGHTS_RD_AGREEMENT = 'transfer_rights_rd_agreement',
  TRANSFER_RIGHTS_AGREEMENT = 'transfer_rights_agreement',
  PRE_COURT_REPRESENTATION_PATENT_OFFICE = 'pre_court_representation_patent_office',
  AGREEMENT_REGISTRATION = 'agreement_registration',
  OBJECTION_RESPONSE_PATENT_OFFICE_PREPARE = 'objection_response_patent_office_prepare',
  RANDOM_APPEAL_PATENT_OFFICE = 'random_appeal_patent_office',
  APPLICATION_RECALL = 'application_recall',
  PRIORITY_DOCUMENT_COPY = 'priority_document_copy',
  OFFICIAL_FEE_ESTIMATE_PAY = 'official_fee_estimate_pay',
  RESTORATION_APPLICATION_DOC = 'restoration_application_doc',
  CHANGE_APPLICATION_DOC_NOT_ABT_HOLDER = 'change_application_doc_not_abt_holder',
  RIGHT_DOCUMENT_RENEWAL = 'right_document_renewal',
  VALIDATION = 'validation',
  GRANTING = 'granting',
  FILING_RESPONSE_OA = 'filing_response_OA',
  FILING_RESPONSE_FORMAL_OA = 'filing_response_formal_oa',
  FILING_EXAMINATION_REQUEST = 'filing_examination_request',
  LISBON_APPLICATION_PREPARE = 'lisbon_application_prepare',
  HAGUE_APPLICATION_PREPARE = 'hague_application_prepare',
  MADRID_APPLICATION_PREPARE = 'madrid_application_prepare',
  PCT_APPLICATION_PREPARE = 'pct_application_prepare',
  NATIONAL_PHASE_APPLICATION_PREPARE = 'national_phase_application_prepare',
  PROVISIONAL_APPLICATION = 'provisional_application',
  NAMRANDOM_ANALYTICSE = 'random_analytics',
  INVENTORY_RD_OIP_IA = 'inventory_rd_oip_ia',
  PROTECTABILITY_ANALYSIS = 'protectability_analysis',
  PROTECTABILITY_ANALYSIS_COUNTRY = 'protectability_analysis_country',
  PROGRAM_REGISTRATION_RUSSIAN_REGISTR = 'program_registration_russian_registr',
  FORMS_DOCUMENTS_FOR_REG_TAX_AUTHORITY = 'forms_documents_for_reg_tax_authority',
  DRAFTING_INVESTMENT_AGREEMENT = 'drafting_investment_agreement',
  DRAFTING_SHAREHOLDERS_AGREEMENT = 'drafting_shareholders_agreement',
  LEGAL_SUPPORT_INVESTOR_TRANSACTION = 'legal_support_investor_transaction',
  AUTHOR_ORDER_AGREEMENT = 'author_order_agreement',
  RD_AGREEMENT = 'rd_agreement',
  AGREEMENT_FOR_PROGRAM = 'agreement_for_program',
  BUSINESS_APPRAISAL = 'business_appraisal',
  LICENSE_ROYALTY_APPRAISAL = 'license_royalty_appraisal',
  OIP_APPRAISAL = 'oip_appraisal',
  ONE_INSTANCE_COURT_REPRESENTATION = 'one_instance_court_representation',
  RD_RESULTS_DETECTION = 'rd_results_detection',
  OTHER_SERVICE = 'other_service',
  LEGALISATION = 'legalisation',
  LOGO_DESIGN = 'logo_design',
  NOTARIAL_CERTIFICATION = 'notarial_certification',
  DOCUMENTS_TRANSLATION_FOREIGN_LANGUAGES = 'documents_translation_foreign_languages',
  RANDOM_SERVICE_GOVERNMENT_GRANT = 'random_service_government_grant',
  APPLICATION_FOR_SK_RESIDENCY = 'application_for_sk_residency',
  APPLICATION_FOR_GOVERNMENT_GRANT = 'application_for_government_grant',
  DOCUMENTS_FOR_GOVERNMENT_COMPENSATION = 'documents_for_government_compensation',
  HELP_REPORT_GOVERNMENT_GRANT = 'help_report_government_grant',
  OPTION_POLICY = 'option_policy',
  PERSONAL_DATA_PROTECTION_POLICY = 'personal_data_protection_policy',
  INTELLECTUAL_ACTIVITY_REGULATIONS = 'intellectual_activity_regulations',
  NON_DISCLOSURE_FOR_KNOW_HOW = 'non_disclosure_for_know_how',
  NON_DISCLOSURE_REQUIREMENTS = 'non_disclosure_requirements',
  RANDOM_AGREEMENT_CONTRACTOR_CUSTOMER = 'random_agreement_contractor_customer',
  DRAFTING_OPTION_AGREEMENT_EMPLOYEE = 'drafting_option_agreement_employee',
  DRAFTING_AUTHOR_AGREEMENT = 'drafting_author_agreement',
  DRAFTING_EMPLOYMENT_AGREEMENT = 'drafting_employment_agreement',
  LEGAL_SUPPORT_SHARE_PURCHASE_AGREEMENT = 'legal_support_share_purchase_agreement',
}
