<mat-sidenav-container class="sidenav-container" hasBackdrop="true">
  <mat-sidenav #sidenav mode="over" opened="false" position="end" class="sidenav md:w-2/5 p-8" fixedInViewport="true">
    <app-task-for-marketplace [hidden]="step!==publicPageSteps.CREATE_TASK" (stepsHandler)="step=$event"
                              (sideNavHandler)="closeSideNav()">
    </app-task-for-marketplace>
    <app-personal-field *ngIf="step===publicPageSteps.PERSONAL_FIELD"
                        (email)="email=$event"
                        [companyType]="'customer'"
                        (stepsHandler)="step=$event"
                        [fromMarketPlace]="true"
                        [sideNavMode]="true">
    </app-personal-field>
    <app-sign-in *ngIf="step===publicPageSteps.SIGN_IN"
                 [sideNavMode]="true"
                 (stepsHandler)="step=$event">
    </app-sign-in>
    <app-confirm-email *ngIf="step===publicPageSteps.CONFIRM_YOUR_EMAIL"
                       [email]="email"
                       >
    </app-confirm-email>
    <app-public-additional-filters *ngIf="step===publicPageSteps.ADDITIONAL_FILTER"></app-public-additional-filters>
  </mat-sidenav>
  <mat-sidenav-content class="bg-white">
    <div *ngIf="loading$ | async" class="loading-ipnote flex items-center justify-center h-screen">
      <img class="w-50 animate-pulse" style="height: 60px" src="../assets/img/new-ipnote-logo.png" />
    </div>
    <app-header></app-header>
    <main class="main-content">
      <router-outlet></router-outlet>
    </main>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
