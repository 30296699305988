export enum AnalyticsEventEnum {
  SIGN_UP = 'Signed Up',
  SIGN_IN = 'Signed In',
  SIGN_OUT = 'Signed Out',
  CONFIRM_EMAIL = 'Confirm email',
  PUBLIC_PROFILE_COMPLETED = 'Public profile completed',
  PUBLIC_PROFILE_APPROVED = 'Public profile approved',
  PROVIDER_APPROVED = 'Provider approved',
  SERVICE_COMPLETED = 'Service completed',
  NEW_TASK_STARTED = 'New task started',
  NEW_TASK_CREATED = 'New task created',
  NEW_TASK_CREATED_AI = 'New task created AI chat',
  NEW_TASK_PROVIDER_OPENED = 'New task provider opened',
  NEW_TASK_PROVIDER_REQUESTED = 'New task provider requested',
  TASK_OFFER_STARTED = 'Task offer started',
  TASK_OFFER_CONFIRMED = 'Task offer confirmed',
  TASK_OFFER_COMPLETED = 'Task offer completed',
  TASK_OFFER_DECLINE = 'Task offer decline',
  TASK_INITIATED = 'Task initiated',
  TASK_DELIVERED = 'Task delivered',
  TASK_DELETED = 'Task deleted',
  TASK_COMPLETED = 'Task completed',
  UPSELL_REQUESTED_HEADER = 'Upsell requested (header)',
  UPSELL_REQUESTED_MIN_OFFER = 'Upsell requested (min-offer)',
  UPSELL_REQUESTED_SUGGEST_OFFER = 'Upsell requested (suggest-offer)',
  UPSELL_REQUESTED_VIEW_OFFER = 'Upsell requested (view-offer)',
  UPSELL_CHECKOUT_STARTED = 'Upsell checkout started',
  TASK_REJECTION_STARTED = 'Task rejection started',
  PAY_INVOICE = 'Pay invoice',
  ONBOARDING_APPROVED = 'onboarding approved',
  ONBOARDING_COMPLETED = 'Onboarding completed',
  /** NEW IPNOTE-5445 */
  FIRST_MESSAGE_TASK = 'Start dialogue for task creation', // старт диалога по созданию таска( отправка первого сообщения от юзера после автоматического “Register Your Trademark in the USA” )
  START_REGISTRATION = 'Start chat for registration', // запуск чата по регистрации (это когда Ai просит написать full name)
  FIRST_REGISTRATION_MESSAGE = 'First user message since registration start', // Первое сообщение от юзера с начала регистрации
  USER_REGISTERED = 'User filled all registration fields', // юзер заполнил все поля регистрации,создание юзера (отправил название страны)
  NUMBER_CONFIRMED = 'User confirmed phone number', // юзер подтвердил телефон: ввел верный otp код из смс
  SKIP_AI_ASSISTANT = 'User clicked on skip this step', // Юзер кликнул на skip this step
  EMAIL_CONFIRMED = 'User confirmed email through form flow', // юзер пошел по другому флоу с регистрацией через форму и в сообщении на email подтвердил почту, указанную при регистрации. Кликнул на кнопку Confirm
  CHAT_OPENED = 'AI chat opened', // открытие ИИ чата
}
