import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { map, Observable } from 'rxjs';
import { selectStateIsAuth } from '../../store/selectors/user.selectors';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    public router: Router,
    private store: Store<AppState>
  ) {
  }

  canActivate(): Observable<boolean> | boolean {
    return this.store.select(selectStateIsAuth).pipe(
      map(isAuthenticated => {
        if (!isAuthenticated) {
          this.router.navigate(['/auth/log-in']).then();
        } else if (typeof isAuthenticated === undefined) {
          return false;
        }
        return isAuthenticated;
      })
    );
  }

}
