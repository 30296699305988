import { ITaskEntity } from '../interface/task.entity.interface';

export enum DashboardTaskCategory {
  SELECTION = 'Selection',
  PAYMENT = 'Payment',
  IN_WORK = 'In work',
  ACCEPTANCE = 'Acceptance',
}

export interface TasksTypeData {
  title: DashboardTaskCategory;
  data: ITaskEntity[] | [];
}
