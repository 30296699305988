import { ActionReducerMap } from '@ngrx/store';
import {
  onboardingProviderPanelReducer,
  OnboardingProviderPanelState
} from './reducers/onboarding-provider-panel.reduser';


export interface StateOnboardingProviderPanel {
  onboardingProviderPanel: OnboardingProviderPanelState;
}

export const reducers: ActionReducerMap<StateOnboardingProviderPanel> = {
  onboardingProviderPanel: onboardingProviderPanelReducer
};
