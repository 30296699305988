import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { BehaviorSubject, forkJoin, interval, never } from 'rxjs';
import { filter, skip, switchMap, tap } from 'rxjs/operators';
import { UserGetInfo } from '../store/actions/user.actions';
import { CompanyListLoad } from '../store/actions/company.actions';
import { selectStateUser } from '../store/selectors/user.selectors';
import { concatLatestFrom } from '@ngrx/effects';
import { selectStateCompanyList } from '#selectors';

@Injectable({
  providedIn: 'root',
})
export class SchedulerService {
  constructor(private store: Store<AppState>) {
    this.scheduler$.subscribe();
  }

  private workScheduler$ = new BehaviorSubject(false);

  private scheduler$ = this.workScheduler$.pipe(
    switchMap((work) => {
      if (work) {
        return forkJoin({
          user: this.getUserInfo$,
          companyList: this.getCompanyList$,
          // getUnreadMessage: this.getUnreadMessage$
        });
      } else {
        return never();
      }
    }),
  );

  private getUserInfo$ = interval(30000).pipe(
    skip(1),
    tap((val) => {
      this.store.dispatch(UserGetInfo());
    }),
  );

  private getCompanyList$ = interval(40000).pipe(
    skip(1),
    concatLatestFrom(() => [this.store.select(selectStateCompanyList)]),
    filter(([i, company]) => !company.apiData.waitingServerResponse),
    tap((val) => {
      //this.store.dispatch(CompanyListLoad());
    }),
  );

  private getUnreadMessage$ = interval(4000).pipe(
    tap((val) => {
      console.log('Get unread message', val);
    }),
  );

  startScheduler(): void {
    this.store.select(selectStateUser).subscribe((res) => {
      this.workScheduler$.next(true);
      if (!res.isAuthenticated) {
        this.workScheduler$.next(false);
      } else {
        this.workScheduler$.next(true);
      }
    });
  }
}
