import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ELanguage } from '@ipnote/enum';
import { fadeInUp400ms } from '@vex/animations/fade-in-up.animation';
import { StateConfirmEmail } from 'app/store/reducers/user';
import { selectConfirmEmailState } from 'app/store/selectors/user.selectors';
import { AuthService } from 'app/app-common/services/auth/auth.service';
import { SetTitleService } from 'app/app-common/services/set-title/set-title.service';
import { DialogService } from 'app/app-common/services/dialogs/dialog.service';
import { AppState } from '#appState';
import { UserConfirmEmail } from 'app/store/actions/user.actions';

@UntilDestroy()
@Component({
  selector: 'app-confirm-success',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss'],
  animations: [fadeInUp400ms]
})
export class EmailConfirmationComponent implements OnInit {
  public confirmEmailStore$: Observable<StateConfirmEmail> = this.store.select(selectConfirmEmailState);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private auth: AuthService,
    private dialogs: DialogService,
    private cd: ChangeDetectorRef,
    public titleService: SetTitleService,
    private translocoService: TranslocoService,
    private store: Store<AppState>
  ) {
    this.translocoService.setActiveLang(ELanguage.EN);
    this.titleService.setTitle('ipnote_title');
  }

  ngOnInit(): void {
    const token = this.route.snapshot.params.hash;

    this.store.dispatch(UserConfirmEmail({ data: { token } }));
  }

  getLink(): Observable<string[]> {
    return this.route.queryParams.pipe(
      map(params => {
        return params?.mp ? ['/public-page/company-public-info'] : ['/auth/log-in'];
      }));
  }
}
