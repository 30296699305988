import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StateUser, StateUserProfile } from '../reducers/user';
import { AppState } from '#appState';

export const selectStateUser = createFeatureSelector<StateUser>('user');

export const selectStateUserInfo = createSelector(selectStateUser, (user) => {
  return user.user;
});

export const selectStateIsAuth = createSelector(selectStateUser, (user) => {
  return user.isAuthenticated;
});

/***
 * SignIn
 */
export const selectSignInState = (state: AppState) => state.signIn;

/***
 * SignUp
 */
export const selectSignUpState = (state: AppState) => state.signUp;

/***
 * Confirm email
 */
export const selectConfirmEmailState = (state: AppState) => state.confirmEmail;

/***
 * Forgot password
 */
export const selectForgotPasswordRequestState = (state: AppState) => state.forgotPassword.request;

export const selectForgotPasswordConfirmState = (state: AppState) => state.forgotPassword.confirm;

/***
 * User profile
 */
export const selectStateUserProfile = createFeatureSelector<StateUserProfile>('userProfile');

// export const selectStatusActionsMain = createSelector(
//   selectStateUserProfile,
//   ( userProfile) => {
//     return userProfile.statusActions.main;
// });
//
// export const selectStatusActionsPassport = createSelector(
//   selectStateUserProfile,
//   ( userProfile) => {
//     return userProfile.statusActions.passport;
// });
//
// export const selectStatusActionsRole = createSelector(
//   selectStateUserProfile,
//   ( userProfile) => {
//     return userProfile.statusActions.roleSettings;
// });
//
// export const selectStateUserRoleSettings = createSelector(
//   selectStateUserProfile,
//   ( userProfile) => {
//     return userProfile.data?.rolesSettings;
//   });
//
// export const selectUserIsAdmin = createSelector(
//   selectStateUserRoleSettings,
//   ( stateUserRoles) => {
//     const findRoleAdmin = stateUserRoles?.length > 0 ? stateUserRoles.filter((roleSettings) => {
//       return roleSettings.role.isAdmin;
//     }) : [];
//     return findRoleAdmin.length > 0 ? true : false;
//   });
