/** @TODO: перенесено в файл: src/ipnote-utils/@models/file/interface/file.entity.interface.ts */
// export interface IFile {
//   _id?: string;
//   createdAt?: Date;
//   updatedAt?: Date;
//   bucketName: string;
//   name: string;
//   size: number;
//   descriptions: string;
//   type: string;
//   url: string;
//   tags: [FileTagsFolderTypes, FileTagsTypes];
//   owner?: number | string | any;
// }

export interface IFileSortSelect {
  field: string;
  order: 'ASC' | 'DESC';
  nameTranslocoKey: string;
}

export const fileOrders: IFileSortSelect[] = [
  { field: 'createdAt', order: 'ASC', nameTranslocoKey: 'sort_date_created' },
  { field: 'name', order: 'DESC', nameTranslocoKey: 'sort_name' },
];

export interface IPublicFile {
  url: string;
  name: string;
  size: number;
  type: string;
}
