<div class="flex h-full justify-center items-center">
  <div class=" flex flex-col basis-[475px] gap-[4rem]">
    <div class="flex">
      <div class="mr-5 shrink-0 flex w-[48px] h-[48px] bg-white rounded-[7px] justify-center items-center">
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="icon/ai">
            <path id="Vector"
                  d="M9.5 13C10.3396 12.7047 11.0727 12.167 11.6067 11.455C12.1407 10.743 12.4515 9.88867 12.5 9"
                  stroke="#145FAC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_2" d="M6.50391 5.125C6.52368 5.60873 6.66024 6.0805 6.90191 6.5" stroke="#145FAC"
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_3" d="M3.97656 10.896C4.1595 10.747 4.35527 10.6145 4.56156 10.5" stroke="#145FAC"
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_4" d="M6.5002 17.9999C5.81103 18.0002 5.13347 17.8225 4.5332 17.4839" stroke="#145FAC"
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_5" d="M12.5 13H16.5" stroke="#F0A93B" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path id="Vector_6"
                  d="M12.5 18H18.5C19.0304 18 19.5391 18.2107 19.9142 18.5858C20.2893 18.9609 20.5 19.4696 20.5 20V21"
                  stroke="#F0A93B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_7" d="M12.5 8H20.5" stroke="#F0A93B" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path id="Vector_8"
                  d="M16.5 8V5C16.5 4.46957 16.7107 3.96086 17.0858 3.58579C17.4609 3.21071 17.9696 3 18.5 3"
                  stroke="#F0A93B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_9"
                  d="M16.5 13.5C16.7761 13.5 17 13.2761 17 13C17 12.7239 16.7761 12.5 16.5 12.5C16.2239 12.5 16 12.7239 16 13C16 13.2761 16.2239 13.5 16.5 13.5Z"
                  stroke="#F0A93B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_10"
                  d="M18.5 3.5C18.7761 3.5 19 3.27614 19 3C19 2.72386 18.7761 2.5 18.5 2.5C18.2239 2.5 18 2.72386 18 3C18 3.27614 18.2239 3.5 18.5 3.5Z"
                  stroke="#F0A93B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_11"
                  d="M20.5 21.5C20.7761 21.5 21 21.2761 21 21C21 20.7239 20.7761 20.5 20.5 20.5C20.2239 20.5 20 20.7239 20 21C20 21.2761 20.2239 21.5 20.5 21.5Z"
                  stroke="#F0A93B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_12"
                  d="M20.5 8.5C20.7761 8.5 21 8.27614 21 8C21 7.72386 20.7761 7.5 20.5 7.5C20.2239 7.5 20 7.72386 20 8C20 8.27614 20.2239 8.5 20.5 8.5Z"
                  stroke="#F0A93B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_13"
                  d="M12.5008 5.00005C12.502 4.60008 12.4232 4.20391 12.269 3.83485C12.1148 3.46579 11.8884 3.13128 11.6031 2.851C11.3177 2.57072 10.9792 2.35032 10.6075 2.20278C10.2357 2.05524 9.83819 1.98353 9.4383 1.99186C9.03842 2.0002 8.64424 2.08841 8.27894 2.25132C7.91365 2.41422 7.58462 2.64853 7.31121 2.94045C7.03779 3.23238 6.82552 3.57604 6.68686 3.9512C6.5482 4.32637 6.48596 4.72548 6.50381 5.12505C5.91601 5.27619 5.37031 5.5591 4.90804 5.95236C4.44577 6.34562 4.07905 6.83892 3.83565 7.3949C3.59225 7.95087 3.47857 8.55494 3.5032 9.16136C3.52782 9.76778 3.69013 10.3606 3.97781 10.8951C3.47199 11.306 3.07423 11.8343 2.81915 12.434C2.56406 13.0337 2.45936 13.6866 2.51417 14.336C2.56898 14.9854 2.78162 15.6116 3.13361 16.1601C3.4856 16.7085 3.96627 17.1627 4.53381 17.4831C4.46372 18.0253 4.50555 18.5761 4.65669 19.1016C4.80784 19.627 5.0651 20.1159 5.41259 20.538C5.76008 20.9601 6.19042 21.3065 6.67703 21.5558C7.16364 21.8051 7.69618 21.952 8.24178 21.9874C8.78738 22.0228 9.33445 21.946 9.84919 21.7617C10.3639 21.5774 10.8354 21.2895 11.2346 20.9158C11.6337 20.5421 11.952 20.0906 12.1698 19.5891C12.3876 19.0876 12.5002 18.5468 12.5008 18V5.00005Z"
                  stroke="#145FAC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
        </svg>
      </div>

      <div>
        <div class="font-bold text-lg">AI Assistant</div>
        <div class="text-sm">Free global trademark and patent searches, easy task creating, instant price calculations
          and more
        </div>
      </div>
    </div>
    <div class="flex">
      <div class="mr-5 shrink-0 flex w-[48px] h-[48px] bg-white rounded-[7px] justify-center items-center">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="48" height="48" rx="7" fill="white"/>
          <path d="M19.4996 15H20.4996C18.5496 20.84 18.5496 27.16 20.4996 33H19.4996" stroke="#145FAC" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M26.5 15C28.45 20.84 28.45 27.16 26.5 33" stroke="#145FAC" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"/>
          <path d="M14.5 28V27C20.34 28.95 26.66 28.95 32.5 27V28" stroke="#145FAC" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M14.5 21.0001C20.34 19.0501 26.66 19.0501 32.5 21.0001" stroke="#145FAC" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"/>
          <path
            d="M23.5 34C29.0228 34 33.5 29.5228 33.5 24C33.5 18.4772 29.0228 14 23.5 14C17.9772 14 13.5 18.4772 13.5 24C13.5 29.5228 17.9772 34 23.5 34Z"
            stroke="#145FAC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path
            d="M23.5 24.5C23.7761 24.5 24 24.2761 24 24C24 23.7239 23.7761 23.5 23.5 23.5C23.2239 23.5 23 23.7239 23 24C23 24.2761 23.2239 24.5 23.5 24.5Z"
            fill="#F0A93B" stroke="#F0A93B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div>
        <div class="font-bold text-lg">Local IP Attorneys</div>
        <div class="text-sm">More than 800 verified IP law firms from 180+ countries, ranking and reviews</div>
      </div>
    </div>
    <div class="flex">
      <div class="mr-5 shrink-0 flex w-[48px] h-[48px] bg-white rounded-[7px] justify-center items-center">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="48" height="48" rx="7" fill="white"/>
          <path
            d="M31.5 25C31.5 30 28 32.5 23.84 33.95C23.6222 34.0238 23.3855 34.0202 23.17 33.94C19 32.5 15.5 30 15.5 25V18C15.5 17.7347 15.6054 17.4804 15.7929 17.2929C15.9804 17.1053 16.2348 17 16.5 17C18.5 17 21 15.8 22.74 14.28C22.9519 14.099 23.2214 13.9995 23.5 13.9995C23.7786 13.9995 24.0481 14.099 24.26 14.28C26.01 15.81 28.5 17 30.5 17C30.7652 17 31.0196 17.1053 31.2071 17.2929C31.3946 17.4804 31.5 17.7347 31.5 18V25Z"
            stroke="#145FAC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M20.5 24L22.5 26L26.5 22" stroke="#F0A93B" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
      </div>
      <div>
        <div class="font-bold text-lg">Guaranteed Security</div>
        <div class="text-sm">Flat fees, secure and fast online payments with guaranteed results</div>
      </div>
    </div>
    <div class="flex">
      <div class="mr-5 shrink-0 flex w-[48px] h-[48px] bg-white rounded-[7px] justify-center items-center">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="48" height="48" rx="7" fill="white"/>
          <path
            d="M27.5 34H29.5C30.0304 34 30.5391 33.7893 30.9142 33.4142C31.2893 33.0391 31.5 32.5304 31.5 32V19L26.5 14H17.5C16.9696 14 16.4609 14.2107 16.0858 14.5858C15.7107 14.9609 15.5 15.4696 15.5 16V19"
            stroke="#145FAC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M25.5 14V18C25.5 18.5304 25.7107 19.0391 26.0858 19.4142C26.4609 19.7893 26.9696 20 27.5 20H31.5"
                stroke="#145FAC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path
            d="M19.5 34C22.8137 34 25.5 31.3137 25.5 28C25.5 24.6863 22.8137 22 19.5 22C16.1863 22 13.5 24.6863 13.5 28C13.5 31.3137 16.1863 34 19.5 34Z"
            stroke="#F0A93B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M21 29.5L19.5 28.25V26" stroke="#F0A93B" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>

      </div>
      <div>
        <div class="font-bold text-lg">Fully Online</div>
        <div class="text-sm">24/7 registration and case data access with auto-sync to the patent offices</div>
      </div>
    </div>
  </div>
</div>
