export enum UserRoles {
  ADMIN = 'admin',
  USER = 'user',
  MANAGER = 'manager',
  COMPANY_READER = 'company-reader',
  COMPANY_COLLABORATOR = 'company-collaborator',
  COMPANY_OWNER = 'company-owner',
  COMPANY_MANAGER = 'company-manager',
  IP_OWNER = 'ip-owner',
  IP_OWNER_READER = 'ip-owner-reader',
  ACCESS_AGENT = 'access-agent',
  ACCESS_AGENT_READER = 'access-agent-reader',
}
