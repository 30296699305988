<button type="button" class="absolute -top-4 -right-4" (click)="close()">
  <mat-icon>close</mat-icon>
</button>
<div class="flex items-center ">
  <div [ngStyle]="{'color': period === enums.period.MONTH ? 'rgb(20, 95, 172)' : '#8997b1'}" class="text-base pr-8">Monthly payment</div>
    <mat-icon *ngIf="period === enums.period.MONTH"
              style="color: #8997b1"
              (click)="period = enums.period.YEARS" class="cursor-pointer w-12 h-12 text-[3rem]">toggle_off
    </mat-icon>
    <mat-icon *ngIf="period === enums.period.YEARS"
              (click)="period = enums.period.MONTH" class="cursor-pointer text-primary-500 w-12 h-12 text-[3rem]">toggle_on
    </mat-icon>
  <div [ngStyle]="{'color': period !== enums.period.MONTH ? 'rgb(20, 95, 172)' : '#8997b1'}" class="ml-8 text-base text-primary-500">Annual payment (2 months for free)</div>
</div>

<div class="prices-table">
  <div class="prices-table__cell prices-table__cell text-2xl font-medium justify-center">Pricing</div>
  <div class="prices-table__cell header">
    <div>Free</div>
    <div class="header__cost">$0</div>
  </div>
  <div class="prices-table__cell header">
    <div>Start</div>
    <div class="header__cost">$149{{period === enums.period.YEARS ? 0 : ''}}/{{period.slice(0, -1)}}</div>
  </div>
  <div class="prices-table__cell header">
    <div>Business</div>
    <div class="header__cost">$349{{period === enums.period.YEARS ? 0 : ''}}/{{period.slice(0, -1)}}</div>
  </div>
  <div class="prices-table__cell header">
    <div>Enterprise</div>
    <div class="header__cost">on request</div>
  </div>


  <div class="prices-table__cell body">
    <div>Active IP rights</div>
    <div>Support</div>
    <div>Commission</div>
    <div>AI patent search</div>
    <div>AI trademark search</div>
  </div>
  <div class="prices-table__cell body items-center">
    <div>10</div>
    <div>first month</div>
    <div>10%</div>
    <div>0</div>
    <div>0</div>
<!--    <div-->
<!--      *ngIf="(subscriptionStore$ | async)?.subscription?.subscriptionType.title === enums.userSubscriptionTypeTitle.FREE else freeBlock">-->
<!--      Your current plan-->
<!--    </div>-->
    <ng-template #freeBlock>
      <button [disabled]="true" mat-raised-button color="primary"
              (click)="changeSubscription(enums.userSubscriptionTypeTitle.FREE)">UPGRADE
      </button>
    </ng-template>
  </div>
  <div class="prices-table__cell body items-center">
    <div>50</div>
    <div>permanent</div>
    <div>8%</div>
    <div>0</div>
    <div>2</div>
<!--    <div-->
<!--      *ngIf="(subscriptionStore$ | async).subscription?.subscriptionType.title === enums.userSubscriptionTypeTitle.START else startBlock">-->
<!--      Your current plan-->
<!--    </div>-->
    <ng-template #startBlock>
      <button [disabled]="loading" mat-raised-button color="primary"
              (click)="changeSubscription(enums.userSubscriptionTypeTitle.START)">UPGRADE
      </button>
    </ng-template>
  </div>
  <div class="prices-table__cell body items-center">
    <div>150</div>
    <div>personal</div>
    <div>6%</div>
    <div>1</div>
    <div>3</div>
<!--    <div-->
<!--      *ngIf="(subscriptionStore$ | async).subscription?.subscriptionType.title === enums.userSubscriptionTypeTitle.BUSINESS else businessBlock">-->
<!--      Your current plan-->
<!--    </div>-->
    <ng-template #businessBlock>
      <button [disabled]="loading" mat-raised-button color="primary"
              (click)="changeSubscription(enums.userSubscriptionTypeTitle.BUSINESS)">UPGRADE
      </button>
    </ng-template>
  </div>
  <div class="prices-table__cell body items-center">
    <div>&#8734;</div>
    <div>premium</div>
    <div>individual</div>
    <div>individual</div>
    <div>individual</div>
<!--    <div-->
<!--      *ngIf="(subscriptionStore$ | async).subscription.subscriptionType.title === enums.userSubscriptionTypeTitle.INDIVIDUAL else individualBlock">-->
<!--      Your current plan-->
<!--    </div>-->
    <ng-template #individualBlock>
      <button [disabled]="loading" mat-raised-button color="primary" onclick="location.href='mailto:hi@ipnote.pro'">
        contact us
      </button>
    </ng-template>
  </div>
</div>
