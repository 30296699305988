import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'countryFlag'
})
export class CountryFlagPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: string): SafeResourceUrl {
    const code = value?.toLocaleLowerCase();
    if (['ta', 'xb', 'xa', 'xk', 'dg', 'ea', 'ic', 'ac', 'eur', 'hague_protocol', 'madrid_protocol'].includes(code)) {
      return null;
    } else if (
      [
        'africa',
        'asia',
        'eurasia',
        'europe',
        'euipo',
        'benelux',
        'arabic',
        'hague',
        'lisbon',
        'madrid',
        'pct',
        'wipo_proof',
        'world',
        'bvi'
      ].includes(code)
    ) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(`/assets/flags/${code}.png`);
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(`/assets/flags/${code}.svg`);
  }
}
