import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StateRouter } from '../reducers/router.reducers';


export const featureSelector = createFeatureSelector<StateRouter>('router'); // this key must match the name in the router.state.ts
export const routerSelector = createSelector(
  featureSelector,
  (router) => {
    return router;
  });

