import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorResponseCreate } from '@ipnote/shared';
import { IMarkifyDBsRecencyEntity } from '@ipnote/interface';

export const markifyDBsRecencyActions = createActionGroup({
  source: 'Markify DBs Recency',
  events: {
    'Get markify DBs recency': emptyProps(),
    'Get markify DBs recency success': props<{ dbsRecency: IMarkifyDBsRecencyEntity[] }>(),
    'Get markify DBs recency failure': props<{ error: ErrorResponseCreate }>(),
  },
});
