import { NgModule } from '@angular/core';
import { OnlyNumberDirective } from '../../../../app/page-modules/desk/directives/only-number.directive';
import { StopPreventDirective } from '../../../../app/page-modules/desk/directives/stop-prevent.directive';

@NgModule({
  imports: [StopPreventDirective],
  declarations: [OnlyNumberDirective],
  exports: [OnlyNumberDirective],
})
export class SharedModule {}
