import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ipnote-first-page-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './first-page-header.component.html',
  styleUrls: ['./first-page-header.component.scss'],
})
export class FirstPageHeaderComponent {
  constructor() {}
}
