import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { StripeService } from '../../../../page-modules/desk/services/stripe/stripe.service';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { DialogService } from '../../../services/dialogs/dialog.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EnumsService } from '../../../../page-modules/desk/services/enums/enums.service';
import { Period } from '@ipnote/enum';

@UntilDestroy()
@Component({
  selector: 'app-customer-prices-dialog',
  templateUrl: './customer-prices-dialog.component.html',
  styleUrls: ['./customer-prices-dialog.component.scss'],
})
export class CustomerPricesDialogComponent implements OnInit {
  loading;
  period: Period = this.enums.period.MONTH;

  constructor(
    public dialogRef: MatDialogRef<CustomerPricesDialogComponent>,
    private stripeService: StripeService,
    private analyticsService: AnalyticsService,
    private store: Store<AppState>,
    private dialogs: DialogService,
    public enums: EnumsService,
  ) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close(false);
  }

  changeSubscription(subType: any) {
    this.loading = true;
    this.stripeService
      .changeStripeSubscription({
        title: subType,
        period: this.period,
      })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (value) => {
          window.open(value.invoicePaymentUrl);
        },
        error: (err) => {
          this.dialogs.error(err);
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        },
      });
    return null;
  }
}
