import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotifyItem } from '../../dataset/NotifyItem';
import icNotifications from '@iconify/icons-ic/baseline-notifications-active';
import { DateFormatService } from '../../../../@vex/services/date-format.service';

@Component({
  selector: 'app-notifications-dialog',
  templateUrl: './notifications-dialog.component.html',
  styleUrls: ['./notifications-dialog.component.scss']
})
export class NotificationsDialogComponent implements OnInit {
  icNotifications = icNotifications;
  unreadNotifications: NotifyItem[] | [];
  unreadCount: number;

  iconsHash = {
    finance: 'account_balance_wallet',
    task: 'done_outline',
    collaborators: 'face',
    reminder: 'watch_later'
  };

  constructor(
    public dateFormatService: DateFormatService,
    public dialogRef: MatDialogRef<NotificationsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title?: string; items?: NotifyItem[] }
  ) {
    this.unreadNotifications = this.data.items.filter((item) => !item.isRead);
    this.unreadCount = this.unreadNotifications.length || 0;
  }

  makeTaskLink(str: string, preffix: string) {
    return preffix ? str.replace(preffix, '') : '';
  }

  goToTask(id: string) {
    const preffix = id ? id.slice(0, 4) : '';

    this.dialogRef.close({
      id: this.makeTaskLink(id, preffix),
      preffix
    });
  }

  close() {
    this.dialogRef.close({ items: this.unreadNotifications });
  }

  ngOnInit() {
  }

  icon(input: string): string {
    return this.iconsHash[input] || 'watch_later';
  }
}
