<div class="modal-close-icon" (click)="close();">
  <mat-icon size="10px">close</mat-icon>
</div>
<div id="prices">
  <stripe-pricing-table
    *ngIf="clientSecret"
    [attr.pricing-table-id]="pricingTableId"
    [attr.publishable-key]="stripeKey"
    [attr.customer-session-client-secret]="clientSecret">
  </stripe-pricing-table>
</div>
