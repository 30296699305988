export enum DealsColumn {
  NUMBER = 'number',
  COMPANY_NAME = 'company.name',
  COMPANY_TYPE = 'company.type',
  COMPANY_COUNTRY = 'company.country',
  TASK_NAME = 'title',
  TASK_COUNTRY = 'country',
  SERVICE = 'service.name',
  IP_TYPE = 'oipType',
  PROVIDER_NAME = 'executorCompany.name',
  PROVIDER_COUNTRY = 'executorCompany.country',
  CONFIRM_DATE = 'confirmDate',
  ACCEPT_DELIVERY_DATE = 'acceptDate',
  SERVICE_CHARGE = 'serviceCharge',
  GOVERNMENT_FEES = 'governmentFees',
  CUSTOMER_FEES = 'customerFees',
  PROVIDER_FEES = 'providerFees',
  TASK_PRICE = 'basePrice',
  PAY_OUT_SUM = 'payoutSum',
  REVENUE = 'revenue',
  TAKE_RATE = 'takeRate',
  PAYMENT_DATE = 'paymentDate',
  PAYOUT_DATE = 'payoutDate',
  TRANSFER = 'transactions.stripeAmountInCents',
  ACTION = 'btn_action',
}
