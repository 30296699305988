import * as user from './store/user.state';
import * as company from './store/company.state';
import * as aiWidget from './store/ai-widget.state';
import * as router from './store/reducers/router.reducers';
import * as tasksView from './store/reducers/tasks-tab.reducer';
import * as onboardingProviderPanel from './store/onboarding-privider-panel.state';
import * as chats from './store/reducers/chats.reducer';
import { updateHistoryKey, updateHistoryReducer } from './store/reducers/update-history.reducer';
import { markifyDBsRececyReducer, markifyDBsRecencyKey } from './store/reducers/markify-dbs-recency.reducer';
import { trademarkSearchReducer, TrademarkSearchState } from './store/reducers/trademark-search.reducer';

export interface AppState extends user.StateUser, company.StateCompany, aiWidget.StateAiWidget {
  router: router.StateRouter;
  trademarkSearch: TrademarkSearchState;
  tasksView: tasksView.TasksTab;
  chats: chats.Chats;
}

export const reducers = {
  ...onboardingProviderPanel.reducers,
  ...user.reducers,
  ...company.reducers,
  ...aiWidget.reducers,
  [updateHistoryKey]: updateHistoryReducer,
  [markifyDBsRecencyKey]: markifyDBsRececyReducer,
  router: router.reducer,
  tasksView: tasksView.tasksTabReducer,
  trademarkSearch: trademarkSearchReducer,
  chats: chats.chatsReducer,
};

export const selectRouterState = (state: AppState) => state.router;
