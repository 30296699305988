import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IValidationError } from '@ipnote/interface';
import { has } from 'lodash';

export function setFormError(form: UntypedFormGroup, errors: IValidationError[]): {
  form: UntypedFormGroup,
  otherServerErrors: string
} {
  const otherServerErrors = [];
  for (const error of errors) {
    if (has(form.controls, error.property)) {
      if (form.controls[error.property] instanceof UntypedFormControl) {
        const controls = form.controls[error.property] as UntypedFormControl;
        controls.setErrors({
          server: error.constraints
        });
      } else if (form.controls[error.property] instanceof UntypedFormGroup) {
        const childControls = form.controls[error.property] as UntypedFormGroup;
        for (const childError of error.children) {
          if (has(childControls.controls, childError.property)) {
            childControls.setErrors({
              server: childError.constraints
            });
          }
        }
      }
    } else {
      if (error.children?.length > 0) {
        for (const childError of error.children) {
          otherServerErrors.push(childError);
        }
      } else {
        otherServerErrors.push(error);
      }
    }
  }
  return {
    form,
    otherServerErrors: otherServerErrors.join('\n')
  };
}
