<div>
    <div class="mt-8">
      <h2 class="m-0">{{ "confirm_your_email" | transloco }}</h2>
    </div>
    <div class="mt-2">
      <h4 class="text-secondary m-0"> {{"confirm_email_message" | transloco}} {{email}}.</h4>
    </div>
    <div class="mt-6">
      <h4 class="text-secondary m-0" [innerHTML]='"confirm_email_sub_message" | transloco'></h4>
    </div>
    <div class="mt-6">
      <button [routerLink]="['/auth/log-in']" color="primary" mat-raised-button type="button"
              class="mb-10 w-full">{{ "got_it" | transloco }}</button>
    </div>
</div>
