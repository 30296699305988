import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EnumsService } from '../../../page-modules/desk/services/enums/enums.service';

@Component({
  selector: 'app-confirm-offer-dialog',
  templateUrl: './confirm-offer-dialog.component.html',
  styleUrls: ['./confirm-offer-dialog.component.scss']
})
export class ConfirmOfferDialogComponent implements OnInit {

  form: FormGroup;

  constructor(
    public enums: EnumsService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ConfirmOfferDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { name: string }
  ) {
    this.form = this.formBuilder.group({
      option: [],
      text: [{ value: '', disabled: true }]
    });
  }

  ngOnInit() {
    this.form.get('option').valueChanges.subscribe(reason => {
      if (reason === 'other') {
        this.form.get('text').enable();
      } else {
        this.form.get('text').disable();
      }
    });
  }

  decline() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close(this.form.value);
  }
}
