import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from '../base-url/base-url.service';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';

@Injectable()
export class AuthService {
  constructor(private http: HttpClient, private baseUrl: BaseUrlService) {}

  activate(token: string): Observable<object> {
    const params = { token };
    return this.baseUrl.getAuthBaseUrl('activate').pipe(flatMap((url) => this.http.post(url, params)));
  }
}
