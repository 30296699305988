import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class PaginatorIntlService extends MatPaginatorIntl {
  constructor(private transloco: TranslocoService) {
    super();
    this.transloco.langChanges$.pipe().subscribe((res) => {
      this.itemsPerPageLabel = this.transloco.translate('items-per-page');
      this.nextPageLabel = this.transloco.translate('next_page');
      this.previousPageLabel = this.transloco.translate('previous_page');
    });
    this.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return this.transloco.translate('0-of-length', { length });
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return this.transloco.translate('startIndex-endIndex-of-length', {
        startIndex: startIndex + 1,
        endIndex,
        length
      });
    };
  }
}
