<mat-dialog-content>
  <h2 mat-dialog-title>{{ 'edit-email' | transloco }}</h2>
  <form [formGroup]="form" (ngSubmit)="save()">
    <mat-form-field>
      <mat-label>{{ 'new-email-edit-form' | transloco }}</mat-label>
      <input matInput formControlName="email" required autocomplete="off">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'password' | transloco }}</mat-label>
      <input matInput formControlName="password" required [type]="inputType" autocomplete="off">
      <button (click)="toggleVisibility()" mat-icon-button matSuffix type="button"
              matTooltip="{{ 'toggle-visibility' | transloco }}">
        <mat-icon *ngIf="visible" [icIcon]="icVisibility"></mat-icon>
        <mat-icon *ngIf="!visible" [icIcon]="icVisibilityOff"></mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field style="display: none">
      <input matInput type="password">
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button type="button" color="primary" (click)="save()"
          [disabled]="form.invalid || form.disabled || form.pristine || !emailMask.test(form.get('email').value)">{{ 'save' | transloco }}</button>
  <button mat-button mat-dialog-close>{{ 'cancel' | transloco }}</button>
</mat-dialog-actions>

