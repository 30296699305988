import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AppConfiguration } from '../../../configuration/app.configuration';
import { getEncodedUrl } from '../../common-functions';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor(protected http: HttpClient, private config: AppConfiguration) {}

  public getDownloadFileS3(url: string, name: string): Observable<boolean> {
    let resp: Observable<HttpResponse<Blob>>;
    if (url.includes('http')) {
      resp = this.http.get<Blob>(`${getEncodedUrl(url)}`, {
        responseType: 'blob' as 'json',
        observe: 'response',
      });
    } else {
      resp = this.http.get<Blob>(`${this.config.filesServerBaseUrl}/${url}`, {
        responseType: 'blob' as 'json',
        observe: 'response',
      });
    }
    return resp.pipe(
      switchMap((res) => {
        // const fileName: string = this.getNameFile(res);
        this.downloadFile(res.body, name);
        return of(true);
      }),
      catchError((err) => {
        return of(false);
      }),
    );
  }

  public downloadFile(blob, fileName: string): void {
    const urlLink = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = urlLink;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(urlLink);
  }

  public getNameFile(res: HttpResponse<any>): string {
    // @TODO: Надо доработать бэк, чтобы возвращайл заголовок
    const contentDispositionHeader: string = res.headers.get('Content-Disposition');
    const result: string = contentDispositionHeader.split(';')[1].trim().split('=')[1];
    const name = result.replace(/"/g, '');
    const nameDecode = decodeURIComponent(name);
    return nameDecode;
  }
}
