// actions/ai-widget.actions.ts
import { AiMessage } from '@ipnote/entity';
import { createAction, props } from '@ngrx/store';
import { AiRequestWidgetType } from '@ipnote/type';
import { AiChatResponseWaitEnum } from '@ipnote/enum';

/***
 * All actions for the AI widget
 */
export const hideAiWidgetButton = createAction('[Chat AI] Hide AI Widget Button');
export const showAiWidgetButton = createAction('[Chat AI] Show AI Widget Button');
export const openChatWindow = createAction('[AI Widget] Open Chat Window');
export const closeChatWindow = createAction('[AI Widget] Close Chat Window');
export const toggleChatWindow = createAction('[AI Widget] Toggle Chat Window');
export const openNotifyWindow = createAction('[AI Widget] Open Notify Window');
export const closeNotifyWindow = createAction('[AI Widget] Close Notify Window');
export const toggleNotifyWindow = createAction('[AI Widget] Toggle Notify Window');
export const updateNotificationCount = createAction(
  '[AI Widget] Update Notification Count',
  props<{ count: number }>(),
);

/**
 * All actions for the AI chat
 */
export const initializeChat = createAction('[Chat AI] Initialize Chat');
export const sendCommand = createAction('[Chat AI] Send Message', props<AiRequestWidgetType>());
export const welcomeMessageReceived = createAction(
  '[Chat AI] Welcome Message Received',
  (message: Omit<AiMessage, 'id'>) => ({ message }),
);
export const newMessageReceived = createAction('[Chat AI] New Message Received', (message: Omit<AiMessage, 'id'>) => ({
  message,
}));
export const streamTokenReceived = createAction('[Chat AI] Stream Token Received', props<{ token: string }>());
export const endTokenReceived = createAction('[Chat AI] End Token Received');
export const waitingReceived = createAction(
  '[Chat AI] Waiting Received',
  props<{ operation: AiChatResponseWaitEnum }>(),
);
export const waitingReceivedBtn = createAction('[Chat AI] Waiting Received btn');
export const chatMessagesReceived = createAction(
  '[Chat AI] Chat Messages Received',
  props<{ messages: Omit<AiMessage, 'id'>[]; chatId: number }>(),
);
export const waitingPaymentReceived = createAction(
  '[Chat AI] Waiting Payment Received',
  props<{ serviceType: string }>(),
);
export const taskCreatedReceived = createAction('[Chat AI] Task Created Received', props<{ taskId: number }>());
export const eventReceived = createAction('[Chat AI] Event Received', props<{ eventName: string }>());
export const ratingAdded = createAction('[Chat AI] Rating Added', props<{ isGood: boolean }>());
export const reloadPageReceived = createAction('[Chat AI] Reload Page Received');
export const returnButtonReceived = createAction('[Chat AI] Return Button Received', props<{ buttons: string[] }>());
export const stopResponse = createAction('[Chat AI] Stop response');
export const clearReturnButtons = createAction('[Chat AI] Clear Return Buttons');
export const clearWaitingPayment = createAction('[Chat AI] Clear Waiting Payment');
