export enum TransactionType {
  TRANSFER = 'transfer', // сумма, которую хочет провайдер за задачу
  TRANSFER_IPNOTE = 'transfer_ipnote', // deprecated
  FEE = 'fee', // комиссия ipnote с кастомера
  INCOMING_FEE = 'incoming_fee', // комиссия ipnote с провайдера
  COMPENSATION = 'compensation', // госпошлина
  REFILL = 'refill', // оплата со стороны кастомера (transfer + compensation + fee)
  WITHDRAWAL = 'withdrawal', // выплата провайдеру на его счет в банке || payout (transfer + compensation - incoming fee)
  SUBSCRIPTION = 'subscription', // deprecated
  REFUND = 'refund', // deprecated
}

export enum TransactionTypes {
  INCOME = 'income',
  EXPENSE = 'expense',
}
