<mat-dialog-content>
  <h2 mat-dialog-title>{{ 'edit-password' | transloco }}</h2>
  <form [formGroup]="form" (ngSubmit)="save()">
    <input type="password" style="display: none">
    <mat-form-field>
      <mat-label>{{ 'old-password' | transloco }}</mat-label>
      <input matInput formControlName="oldPassword" [type]="inputTypeOldPassword" required autocomplete="off">
      <button (click)="toggleVisibility('OldPassword')" mat-icon-button matSuffix type="button"
              matTooltip="{{ 'toggle-visibility' | transloco }}">
        <mat-icon *ngIf="visibleOldPassword" [icIcon]="icVisibility"></mat-icon>
        <mat-icon *ngIf="!visibleOldPassword" [icIcon]="icVisibilityOff"></mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'new-password' | transloco }}</mat-label>
      <input matInput formControlName="newPassword" [type]="inputTypeNewPassword" required autocomplete="off">
      <button (click)="toggleVisibility('NewPassword')" mat-icon-button matSuffix type="button"
              matTooltip="{{ 'toggle-visibility' | transloco }}">
        <mat-icon *ngIf="visibleNewPassword" [icIcon]="icVisibility"></mat-icon>
        <mat-icon *ngIf="!visibleNewPassword" [icIcon]="icVisibilityOff"></mat-icon>
      </button>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button type="button" color="primary" (click)="save()"
          [disabled]="form.invalid || form.disabled || form.pristine">{{ 'save' | transloco }}</button>
  <button mat-button mat-dialog-close>{{ 'cancel' | transloco }}</button>
</mat-dialog-actions>

