<div class="wrapper">
  <div class="title">
    <h2 mat-dialog-title>The limit for IP rights has been exceeded</h2>
  </div>
  <button class="button-close" [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
  <mat-dialog-content class="break-words" [innerHTML]="data.error.error.message | transloco"></mat-dialog-content>
  <mat-dialog-actions class="pt-6">
    <a *ngIf="data.fromTask" href="mailto:support@ipnote.pro" mat-button class="modal-button">Contact support</a>
    <button *ngIf="data.fromTask" mat-button class="modal-button" (click)="saveAsDraft()">Save as draft</button>
    <button mat-button color="primary" class="modal-button" (click)="accept()">Upgrade</button>
  </mat-dialog-actions>
</div>
