export enum RIASubType {
  WAY = 'way',
  DEVICE = 'device',
  SUBSTANCE = 'substance',
  APPLICATION = 'application',
  LOGO = 'logo',
  NAMING = 'naming',
  DOMAIN_NAME = 'domain_name',
  PRODUCT = 'product',
  PACKAGING = 'packaging',
  INTERFACE = 'interface',
  ALGORITHM = 'algorithm',
  SOURCE_CODE = 'source_code',
  DESIGN_DOCUMENTATION = 'design_documentation',
  PROJECT_DOCUMENTATION = 'project_documentation',
  TECHNICAL_DOCUMENTATION = 'technical_documentation',
  IDEA = 'idea',
  PROJECT = 'project',
  METHOD = 'method',
  VIDEO = 'video',
  AUDIO = 'audio',
  PHOTO = 'photo',
  TEXT = 'text',
}
