import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from '../../../../app-common/services/base-url/base-url.service';
import { CollaboratorsCompany } from '../../../../dataset/Collaborator';
import { Observable } from 'rxjs';
import { finalize, flatMap, mergeMap } from 'rxjs/operators';
import { SpinnerViewService } from '../../../../app-common/services/spinner/spinner-view.service';

@Injectable({
  providedIn: 'root'
})
export class ManagerService {
  constructor(private http: HttpClient, private baseUrl: BaseUrlService, private spinnerService: SpinnerViewService) {
  }

  get(): Observable<any[]> {
    return this.baseUrl.getBaseUrl('users/managers').pipe(flatMap((url) => this.http.get<any[]>(url)));
  }

  invite(companyId: number, body: any): Observable<CollaboratorsCompany> {
    this.spinnerService.start()
    return this.baseUrl
      .getBaseUrl(`companies/${companyId}/manager`)
      .pipe(
        mergeMap((url) => this.http.post<CollaboratorsCompany>(url, body)),
        finalize(() => this.spinnerService.stop())
      );
  }

  expel(companyId: number, userId: any): Observable<CollaboratorsCompany> {
    return this.baseUrl
      .getBaseUrl(`companies/${companyId}/manager/${userId}`)
      .pipe(
        mergeMap((url) => this.http.delete<CollaboratorsCompany>(url)),
        finalize(() => this.spinnerService.stop())
      );
  }
}
