<div class="price-details" *ngIf="prices && prices.length">
  <div class="left">
    <span *ngIf='priceService.getPriceDetail(prices, "transfer")'
          class="price-name whitespace-nowrap">{{'transfer_price' | transloco}}</span>
    <span *ngIf='priceService.getPriceDetail(prices, "compensation")'
          class="price-name whitespace-nowrap">{{'compensation_price' | transloco}}</span>
    <span *ngIf='priceService.getPriceDetail(prices,"fee")'
          class="price-name whitespace-nowrap">{{'fee_ipnote' | transloco}}</span>
    <span *ngIf='priceService.getPriceDetail(prices,"incoming_fee")'
          class="price-name whitespace-nowrap">{{'incoming_fee_ipnote' | transloco}}</span>
  </div>
  <div class="right">
    <span *ngIf='priceService.getPriceDetail(prices, "transfer")'
          class="price-value whitespace-nowrap">{{priceService.getPriceDetail(prices, "transfer")}}</span>
    <span *ngIf='priceService.getPriceDetail(prices, "compensation")'
          class="price-value whitespace-nowrap">{{priceService.getPriceDetail(prices, "compensation")}}</span>
    <span *ngIf='priceService.getPriceDetail(prices,"fee")'
          class="price-value whitespace-nowrap">{{priceService.getPriceDetail(prices, "fee")}}</span>
    <span *ngIf='priceService.getPriceDetail(prices,"incoming_fee")'
          class="price-value whitespace-nowrap">{{priceService.getPriceDetail(prices, "incoming_fee")}}</span>
  </div>
</div>
