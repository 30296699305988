import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LobbyComponent } from './lobby.component';
import { SignUpComponent } from './modules/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { ConfirmEmailComponent } from './modules/confirm-email/confirm-email.component';
import { EmailConfirmationComponent } from './modules/email-confirmation/email-confirmation.component';
import { SignInComponent } from './modules/sign-in/sign-in.component';
import { ForgotPasswordConfirmationComponent } from './modules/forgot-password-confirmation/forgot-password-confirmation.component';
import { CreateFirstCompanyComponent } from './modules/create-first-company/create-first-company.component';
import { AuthGuard } from '../../app-common/guards/auth.guard';
import { GoogleResolver } from './resolvers/google.resolver';
import { LinkedInResolver } from './resolvers/linked-in.resolver';
import { SetUpTwoFactorComponent } from './modules/set-up-two-factor/set-up-two-factor.component';

const routes: Routes = [
  {
    path: '',
    component: LobbyComponent,
    children: [
      {
        path: 'log-in',
        component: SignInComponent,
      },
      {
        path: 'sign-up',
        component: SignUpComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'forgot-password-confirmation/:hash',
        component: ForgotPasswordConfirmationComponent,
      },
      {
        path: 'confirm-email',
        component: ConfirmEmailComponent,
      },
      {
        path: 'email-confirmation/:hash',
        component: EmailConfirmationComponent,
      },
      {
        path: 'create-first-company',
        canActivate: [AuthGuard],
        component: CreateFirstCompanyComponent,
      },
      {
        path: 'google/callback',
        resolve: {
          isResolved: GoogleResolver,
        },
        component: SignInComponent,
      },
      {
        path: 'linked-in/callback',
        resolve: {
          isResolved: LinkedInResolver,
        },
        component: SignInComponent,
      },
      {
        path: 'set-up-two-factor',
        canActivate: [AuthGuard],
        component: SetUpTwoFactorComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LobbyRoutingModule {}
