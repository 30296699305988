import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { sortBy } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CountriesSortService {
  constructor(private translocoService: TranslocoService) {
  }

  sort(list: string[]): string[] {
    let countries = list.map((key) => {
      return { key, value: this.translocoService.translate(key) };
    });
    countries = sortBy(countries, [
      (c) => {
        return c.value;
      }
    ]);
    return countries.map((country) => country.key);
  }
}
