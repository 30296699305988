import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ICompanyEntity, IUserSignInRes } from '@ipnote/interface';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { DateFormatService } from '../../../../../@vex/services/date-format.service';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ScrollbarDirective } from '../../../../../@vex/components/scrollbar/scrollbar.directive';
import {
  AiChatRequestCommandEnum,
  AiChatRequestRegistrationCommandEnum,
  AiChatResponseCommandEnum,
  AiChatResponseRegistrationCommandEnum,
  AiChatResponseWaitEnum,
  AnalyticsEventEnum,
} from '@ipnote/enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SocketIoService } from '../../../services/socket.io/socket.io.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NAME_AI_PARAMS, SOCKET_NAME_AI_CHAT } from '@ipnote/const';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, take } from 'rxjs';
import { selectStateSelectedCompany } from '#selectors';
import { AiRequestRegistrationType, AiRequestType, AiResponceRegistrationType, AiResponseType } from '@ipnote/type';
import { FileManagerService } from '../../../services/file-manager/file-manager.service';
import { findIndex } from 'lodash';
import { StateUser } from '../../../../store/reducers/user';
import { selectStateUser } from '../../../../store/selectors/user.selectors';
import { UserSignInSuccess } from '../../../../store/actions';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatRadioChange } from '@angular/material/radio';
import { UserProfileUpdate } from '../../../../store/actions/user.actions';
import { AiTaskCreatedComponent } from '../ai-task-created/ai-task-created.component';
import {
  CreateTaskHeaderService,
  ICreateTaskHeaderStep,
} from '../../../../page-modules/ai-create-task/create-task-header-time-line/create-task-header-service';
import { isURL } from 'class-validator';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AiChatMessageRating } from '@ipnote/enum';

@UntilDestroy()
@Component({
  selector: 'app-chat-ai',
  templateUrl: './chat-ai.component.html',
  styleUrls: ['./chat-ai.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('expandCollapseRatingComment', [
      state(
        'void',
        style({
          height: '0px',
          overflow: 'hidden',
        }),
      ),
      transition(':enter', [
        animate(
          '500ms ease-in-out',
          style({
            height: '*',
            overflow: 'hidden',
            opacity: 1,
          }),
        ),
      ]),
      //element being removed from DOM.
      transition(':leave', [
        animate(
          '500ms ease-in-out',
          style({
            height: '0px',
            overflow: 'hidden',
            opacity: 0,
          }),
        ),
      ]),
    ]),
    trigger('expandCollapseMessageForm', [
      state('visible', style({ opacity: '1' })),
      state('hide', style({ opacity: '0' })),
      transition('visible => hide', [animate('500ms 0.2s ease')]),
    ]),
    trigger('like', [
      state(
        AiChatMessageRating.UNLIKED,
        style({
          opacity: '1',
          transform: 'scale(1.3)',
          // marginTop: '10px'
        }),
      ),
      state(
        AiChatMessageRating.LIKED,
        style({
          opacity: '1',
          transform: 'scale(1.3)',
          // marginTop: '10px'
        }),
      ),
      transition(`* => ${AiChatMessageRating.UNLIKED}`, [animate('0.3s ease')]),
      transition(`* => ${AiChatMessageRating.LIKED}`, [animate('0.3s ease')]),
    ]),
  ],
})
export class ChatAiComponent implements OnInit, OnDestroy {
  public aiName = 'iPNOTE AI assistant';
  public aiLogo = '/assets/img/logo-gray.png';
  public userName = 'You';
  private socketName = SOCKET_NAME_AI_CHAT;
  private currentSocket = SOCKET_NAME_AI_CHAT;
  public user: IUserSignInRes;
  public isAuthenticated = false;
  public userState$: Observable<StateUser> = this.store.select(selectStateUser);
  private paramsAction = null;
  public messages: any[] = [];
  public commands = AiChatRequestCommandEnum;
  public showTheFormForChoosingWhatToOpen = false;
  public areRatingCommentVisible = false;
  public likeState: AiChatMessageRating = AiChatMessageRating.DEFAULT;
  public messageFormState: 'visible' | 'hide' = 'visible';
  public currentMessageId: number;

  selectedCompany: ICompanyEntity;
  selectedCompany$: Observable<ICompanyEntity> = this.store.select(selectStateSelectedCompany);
  waiting = true;
  waitingOperation = 'Request processing';
  waitingPayment = {
    status: false,
    type: null,
  };
  chatId = null;
  chatStatus = null;

  form = new UntypedFormGroup({
    message: new UntypedFormControl(),
  });
  formRatingComment = new FormGroup({
    messageId: new FormControl<number | null>(null, { nonNullable: true }),
    isGood: new FormControl<boolean | null>(null, { nonNullable: true }),
    comment: new FormControl<string | null>(null, { nonNullable: true }),
  });

  isNewChat = true;

  actionButtonsShow = false;
  actionButtons = [];
  radioButtonState: boolean;

  @ViewChild(ScrollbarDirective, { static: true }) scrollbar: ScrollbarDirective;

  /**
   * Resize on 'input' and 'ngModelChange' events
   *
   * @private
   */
  @ViewChild('messageInput') messageInput: ElementRef;

  @HostListener('input')
  @HostListener('ngModelChange')
  private _resizeMessageInput(): void {
    this._ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.messageInput.nativeElement.style.height = 'auto';
        this._changeDetectorRef.detectChanges();
        this.messageInput.nativeElement.style.height = `${this.messageInput.nativeElement.scrollHeight}px`;
        this._changeDetectorRef.detectChanges();
      });
    });
  }

  @HostListener('window:visibilitychange', ['$event'])
  visibilitychange() {
    if (!document.hidden && this.waitingPayment.status) {
      this.socketService.socket.emit(this.socketName, {
        command: AiChatRequestCommandEnum.CHECK_PAYOUT,
        serviceType: this.waitingPayment.type,
        chatId: this.chatId,
      });
      this.waitingPayment = {
        status: false,
        type: null,
      };
    }
  }

  constructor(
    public dialogRef: MatDialogRef<ChatAiComponent>,
    private store: Store<AppState>,
    public dateFormatService: DateFormatService,
    private socketService: SocketIoService,
    private analyticsService: AnalyticsService,
    private router: Router,
    private route: ActivatedRoute,
    private uploadService: FileManagerService,
    private _ngZone: NgZone,
    private _changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: { lastChat: any; openChat: any },
    public dialog: MatDialog,
    private createTaskHeaderService: CreateTaskHeaderService,
    private deviceService: DeviceDetectorService,
  ) {
    this.selectedCompany$.pipe(untilDestroyed(this)).subscribe((company) => (this.selectedCompany = company));
    this.initializeSocket().then();
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  async ngOnInit() {
    this.userState$.pipe(take(1)).subscribe((userState) => {
      if (userState.isAuthenticated) {
        this.user = userState.user;
      }
      this.radioButtonState = this.getRadioButtonState();
    });

    this.userState$.pipe().subscribe((userState) => {
      if (userState.isAuthenticated) {
        this.user = userState.user;
        this.isAuthenticated = true;
      } else {
        this.isAuthenticated = false;
      }
    });

    this.route.queryParams.subscribe((res) => {
      if (res[NAME_AI_PARAMS]) {
        this.paramsAction = res[NAME_AI_PARAMS];
      }
    });

    // this.isAuthenticated ? await this.startChatAi() : await this.startChatAiRegistration();
    await this.startChatAi();
  }

  ngOnDestroy() {
    !this.data ? this.socketService.socket.disconnect() : null;
  }

  private async initializeSocket() {
    this.socketService.setupSocketConnection();
    await this.ensureSocketInitialized();
  }

  private async ensureSocketInitialized() {
    return new Promise<void>((resolve) => {
      const checkSocket = () => {
        if (this.socketService.socket) {
          resolve();
        } else {
          setTimeout(checkSocket, 100);
        }
      };
      checkSocket();
    });
  }

  /***
   * Starting basic chat
   */
  private async startChatAi() {
    await this.analyticsService.sendEvent(AnalyticsEventEnum.CHAT_OPENED, {});
    // this.createTaskHeaderService.setCreateTaskStep(ICreateTaskHeaderStep.SIGN_UP);

    if (this.data?.openChat) {
      this.chatId = this.data?.openChat.id;
      this.chatStatus = this.data?.openChat.status;

      await this.sc<AiResponseType>(this.socketName);
      this.sendCommand(AiChatRequestCommandEnum.GET_CHAT);
    } else if (this.paramsAction) {
      await this.sc<AiResponseType>(this.socketName);
      await this.send(
        {
          command: AiChatRequestCommandEnum.START,
          newChat: this.isNewChat,
          message: this.paramsAction,
        },
        this.socketName,
      );
    } else if (this.data?.lastChat) {
      this.waiting = false;
      this.showTheFormForChoosingWhatToOpen = true;
      this.form.disable();
    } else {
      await this.sc<AiResponseType>(this.socketName);
      this.sendCommand(AiChatRequestCommandEnum.INIT);
    }
  }

  async choseWhatToOpen(lastChat: boolean) {
    if (lastChat) {
      this.data.openChat = this.data.lastChat;
      this.data.lastChat = null;
    } else {
      this.data.openChat = null;
      this.data.lastChat = null;
    }
    this.showTheFormForChoosingWhatToOpen = false;
    await this.startChatAi();
  }

  /**
   * Listens for a socket event with the given name and performs different actions based on the received response's command.
   * @param {string} name - The name of the socket event to listen for.
   * @returns {Promise<void>}
   */
  private async sc<T extends AiResponceRegistrationType | AiResponseType>(name: string): Promise<void> {
    await this.ensureSocketInitialized();
    if (!this.socketService.socket) {
      return;
    }

    this.socketService.socket.on(name, async (res: T) => {
      this.waiting = true;
      this.form.disable();

      const { command } = res;

      switch (command) {
        case AiChatResponseCommandEnum.WELCOME:
        case AiChatResponseRegistrationCommandEnum.WELCOME:
          this.addMessage(res.message);
          break;

        case AiChatResponseCommandEnum.NEW_MESSAGE:
        case AiChatResponseRegistrationCommandEnum.NEW_MESSAGE:
          this.addMessage(res.message);
          if (res['chatId']) {
            this.chatId = res['chatId'];
          }
          break;

        case AiChatResponseCommandEnum.STREAM_TOKEN:
        case AiChatResponseRegistrationCommandEnum.STREAM_TOKEN:
          this.appendTokenToMessage(res.token, undefined);
          break;

        case AiChatResponseCommandEnum.END_TOKEN:
        case AiChatResponseRegistrationCommandEnum.END_TOKEN:
          this.setPrintsToFalse(undefined);
          break;

        case AiChatResponseCommandEnum.WAITING:
        case AiChatResponseRegistrationCommandEnum.WAITING:
          this.setWaiting(res.operation);
          break;

        case AiChatResponseCommandEnum.CHAT_MESSAGES:
          this.updateMessages(res.messages, res.chatId);
          break;

        case AiChatResponseCommandEnum.WAITING_PAYMENT:
          this.setWaitingPayment(res.serviceType);
          break;

        case AiChatResponseRegistrationCommandEnum.AUTH_DATA:
          this.authUser(res.data);
          break;

        case AiChatResponseCommandEnum.CREATED_TASK:
          this.openTaskCreatedDialog(res.taskId);
          break;

        case AiChatResponseCommandEnum.EVENT:
        case AiChatResponseRegistrationCommandEnum.EVENT:
          this.sendAnalyticsEvent(res.eventName);
          break;

        case AiChatResponseCommandEnum.RATING_ADDED:
          this.updateRatingAndFormState(res.isGood);
          break;

        case AiChatResponseCommandEnum.WAITING_REGISTRATION:
          await this.analyticsService.sendEvent(AnalyticsEventEnum.FIRST_REGISTRATION_MESSAGE, {});
          break;

        case AiChatResponseCommandEnum.RELOAD_PAGE:
        case AiChatResponseRegistrationCommandEnum.RELOAD_PAGE:
          window.location.reload();
          break;
        case AiChatResponseCommandEnum.RETURN_BTN:
          this.actionButtons = res.buttons;
          this.actionButtonsShow = true;
          break;
      }

      this.scrollToBottom('auto');
      this.messageInput.nativeElement.focus();
    });
  }

  updateMessagesLinkType(): void {
    this.messages = this.messages.map((message) => {
      return {
        ...message,
        link: isURL(message.content),
      };
    });
  }

  /**
   * Adds a message to the messages array and enables the form.
   * @param {any} message - The message to add.
   * @returns {void}
   */
  private addMessage(message: any): void {
    this.messages = [...this.messages, message];
    this.updateMessagesLinkType();
    this.waiting = false;
    this.form.enable();
  }

  /**
   * Appends a token to the content of a message and sets the prints flag to true.
   * @param {string} token - The token to append.
   * @param {string} messageId - The ID of the message.
   * @returns {void}
   */
  private appendTokenToMessage(token: string, messageId: number): void {
    const indexMessage = this.getMessageIndex(messageId, true);
    this.messages[indexMessage].content += token;
    this.messages[indexMessage].prints = true;
    this.waitingOperation = AiChatResponseWaitEnum.WRITE;
  }

  /**
   * Sets the prints flag to false for a message.
   * @param {string} messageId - The ID of the message.
   * @returns {void}
   */
  private setPrintsToFalse(messageId: number | undefined): void {
    const indexMessage = this.getMessageIndex(messageId, true);
    this.messages[indexMessage].prints = false;
    this.waiting = false;
    this.form.enable();
  }

  /**
   * Sets the waiting flag and waitingOperation based on the received operation.
   * @param {string} operation - The operation to set.
   * @returns {void}
   */
  private setWaiting(operation: string): void {
    this.waiting = true;
    this.waitingOperation = operation;
  }

  /**
   * Updates the messages array and chatId.
   * @param {any[]} messages - The new messages array.
   * @param {string} chatId - The new chatId.
   * @returns {void}
   */
  private updateMessages(messages: any[], chatId: number): void {
    this.messages = messages;
    this.updateMessagesLinkType();
    this.chatId = chatId;
    this.waiting = false;
    this.form.enable();
  }

  /**
   * Sets the waitingPayment object.
   * @param {string} serviceType - The type of service.
   * @returns {void}
   */
  private setWaitingPayment(serviceType: string): void {
    this.waitingPayment = {
      status: true,
      type: serviceType,
    };
  }

  /**
   * Opens a dialog for a newly created task.
   * @param {string} taskId - The ID of the task.
   * @returns {void}
   */
  private openTaskCreatedDialog(taskId: number): void {
    this.createTaskHeaderService.setCreateTaskStep(ICreateTaskHeaderStep.PROVIDER_SELECTION);
    if (this.router.url.includes('ai-create-task')) {
      this.dialog.open(AiTaskCreatedComponent, {
        data: {
          taskId: taskId,
        },
        width: '520px',
      });
    }
  }

  /**
   * Sends an analytics event with the given event name.
   * @param {string} eventName - The name of the event.
   * @returns {void}
   */
  private sendAnalyticsEvent(eventName: string): void {
    this.analyticsService.sendEvent(eventName, {});
  }

  /**
   * Updates the rating and form state based on the received data.
   * @param {boolean} isGood - Indicates if the rating is good or not.
   * @param {string} messageId - The ID of the message.
   * @returns {void}
   */
  private updateRatingAndFormState(isGood: boolean): void {
    this.areRatingCommentVisible = false;
    this.messageFormState = 'visible';
    this.formRatingComment.reset();
    this.waiting = false;
    this.form.enable();

    const messageIndex = this.getMessageIndex(undefined, true);
    this.messages[messageIndex] = {
      ...this.messages[messageIndex],
      rating: {
        isGood: isGood,
      },
    };
  }

  /**
   * Gets the index of a message in the messages array based on its ID.
   * @param {string} messageId - The ID of the message.
   * @returns {number} - The index of the message in the messages array.
   */
  private getMessageIndex(messageId: number | undefined, isLast = false): number {
    if (isLast) {
      return this.messages.length - 1;
    } else {
      return findIndex(this.messages, { id: messageId });
    }
  }

  /***
   * Send
   * @param $event
   * @param action
   */
  async sendBtn($event?, action?: string): Promise<void> {
    const isEnterPressed = $event.key === 'Enter';
    const isShiftPressed = $event.shiftKey;
    const isSendButtonAction = action === 'sendButton';
    const isPressEnterToSend = this.radioButtonState;

    const shouldSendMessage = (isPressEnterToSend && isEnterPressed && !isShiftPressed) || isSendButtonAction;

    if (shouldSendMessage) {
      $event.preventDefault();
      await this.sendMessage();
    }
  }

  async sendMessage(): Promise<void> {
    this.waiting = true;
    this.waitingOperation = AiChatResponseWaitEnum.FORMING_A_RESPONSE;

    /** IPNOTE-5445 */
    if (this.paramsAction && this.messages.length === 2) {
      await this.analyticsService.sendEvent(AnalyticsEventEnum.FIRST_MESSAGE_TASK, {});
    }

    await this.send(
      {
        message: this.form.get('message').value,
        newChat: this.isNewChat,
        command: this.messages.length == 1 ? AiChatRequestCommandEnum.START : AiChatRequestCommandEnum.DISCUSSION,
        chatId: this.chatId,
      },
      this.currentSocket,
    );

    this.form.get('message').setValue('');
    this.scrollToBottom('smooth');
  }

  async send(data: AiRequestRegistrationType | AiRequestType, socket: string) {
    this.waiting = true;
    this.actionButtons = [];
    this.waitingOperation = 'Processing the request';
    this.socketService.socket.emit(socket, data);
  }

  private sendCommand(command: AiChatRequestCommandEnum) {
    this.socketService.socket.emit(this.socketName, {
      command,
      chatId: this.chatId,
    });

    if (command == AiChatRequestCommandEnum.COMPLETE) {
      this.dialogRef.close(true);
    }
  }

  async buttonClickedActions(button: string) {
    await this.send(
      {
        message: button,
        newChat: this.isNewChat,
        command: AiChatRequestCommandEnum.DISCUSSION,
        chatId: this.chatId,
      },
      this.currentSocket,
    );

    this.form.get('message').setValue('');
    this.actionButtonsShow = false;
    this.scrollToBottom('smooth');
  }

  /***
   *
   * @param $event
   */
  public async fileSelected($event: Event): Promise<void> {
    const target = ($event.target || $event.srcElement) as HTMLInputElement;
    const file = target.files.item(0);
    const { name, size, type } = file;
    const url = await this.uploadService.upload(file).toPromise();
    this.socketService.socket.emit(this.socketName, {
      content: '',
      attachments: [
        {
          url,
          name,
          size,
          type,
        },
      ],
      chatId: this.chatId,
      command: AiChatRequestCommandEnum.UPLOAD_FILE,
    });
    this.scrollToBottom('smooth');
  }

  uploadFiles(files: File[]) {
    files.map(async (file, index) => {
      const { name, size, type } = file;

      this.uploadService
        .upload(file)
        .pipe(untilDestroyed(this))
        .subscribe((url) => {
          this.socketService.socket.emit(this.socketName, {
            content: '',
            attachments: [
              {
                url,
                name,
                size,
                type,
              },
            ],
            chatId: this.chatId,
            command: AiChatRequestCommandEnum.UPLOAD_FILE,
          });
          this.scrollToBottom('smooth');
        });
    });
  }

  private scrollToBottom(behavior?: ScrollBehavior): void {
    setTimeout(() => {
      if (this.scrollbar) {
        this.scrollbar.scrollbarRef.getScrollElement().scrollTo({
          behavior: behavior ? behavior : 'auto',
          top: this.scrollbar.scrollbarRef.getContentElement().clientHeight,
        });
      }
    }, 1);
  }

  private authUser(data) {
    // this.analyticsService.sendEvent(AnalyticsEventEnum.SIGN_UP, { user: data.user, company: data.company });
    this.store.dispatch(UserSignInSuccess({ user: data, reboot: false }));
    this.waiting = true;
    this.waitingOperation = 'Authorize in the system';
    this.currentSocket = this.socketName;

    setTimeout(async () => {
      this.waiting = true;
      this.waitingOperation = 'Switching the dialogue';

      this.socketService.socket.disconnect();
      this.socketService.setupSocketConnection();

      await this.sc<AiResponseType>(this.socketName);

      await this.send(
        {
          command: AiChatRequestCommandEnum.CHECK_REGISTRATION,
          newChat: this.isNewChat,
          chatId: this.chatId,
        },
        this.socketName,
      );
    }, 7000);
  }

  close(): void {
    this.dialogRef.close(null);
  }

  /***
   * Rating
   * @param isGood
   */
  openRatingComment(messageId: number, isGood: boolean): void {
    this.currentMessageId = messageId;
    this.formRatingComment.setValue({
      messageId,
      isGood,
      comment: '',
    });

    this.messageFormState = 'hide';

    this.likeState = isGood ? AiChatMessageRating.LIKED : AiChatMessageRating.UNLIKED;
    this.areRatingCommentVisible = true;

    const messageIndex = this.messages.findIndex((m) => m.id == messageId);
    this.messages[messageIndex] = {
      ...this.messages[messageIndex],
      rating: {
        isGood,
      },
    };

    // this.scrollToBottom('auto');
  }

  async sendRatingComment(comment: boolean) {
    if (!comment) this.formRatingComment.controls.comment.reset();
    // @ts-ignore
    await this.send(
      // @ts-ignore
      {
        ...this.formRatingComment.value,
        chatId: this.chatId,
        command: AiChatRequestCommandEnum.ADD_RATING,
      },
      this.socketName,
    );
  }

  changeSendSettings($event: Partial<MatRadioChange>) {
    this.radioButtonState = $event.value;
    if (!this.isAuthenticated) {
      return;
    }
    const settingKey = this.isMobile ? 'mobilePressEnterToSend' : 'pressEnterToSend';
    this.updateUserProfile(settingKey, $event.value);
  }

  updateUserProfile(parameterName: string, value: boolean): void {
    this.store.dispatch(
      UserProfileUpdate({
        payload: {
          settings: {
            ...this.user.settings,
            chatAiSettings: {
              ...this.user.settings.chatAiSettings,
              [parameterName]: value,
            },
          },
        },
      }),
    );
  }

  getRadioButtonState(): boolean {
    return this.isMobile
      ? this.user?.settings?.chatAiSettings?.mobilePressEnterToSend ?? false
      : this.user?.settings?.chatAiSettings?.pressEnterToSend ?? true;
  }
}
