import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AiToolNameEnum } from '@ipnote/enum';
import { IAiCompleteToolEvent, ICompanyEntity } from '@ipnote/interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { AppState } from '#appState';
import { selectStateSelectedCompany } from '#selectors';
import { Router } from '@angular/router';
import { ChangeTaskService } from 'app/page-modules/desk/services/change-task/change-task.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class aiWidgetEventService {
  companySelect$: Observable<ICompanyEntity> = this.store.select(selectStateSelectedCompany);
  selectedCompany: ICompanyEntity;

  constructor(private store: Store<AppState>, private router: Router, private changeTaskService: ChangeTaskService) {
    this.companySelect$.pipe(untilDestroyed(this)).subscribe((company) => {
      this.selectedCompany = company;
    });
  }

  /** Переписать */
  processEvent(event: IAiCompleteToolEvent): void {
    switch (event.type) {
      case AiToolNameEnum.CREATE_TASK: {
        if (event.data.taskId) {
          const url = `/desk/${this.selectedCompany.id}/tasks/${event.data.taskId}/info/provider`;
          this.router.navigateByUrl(url);
        }
        break;
      }
      case AiToolNameEnum.TASK_CONFIRMATION: {
        if (event.data.taskId) {
          const url = `/desk/${this.selectedCompany.id}/tasks/${event.data.taskId}/info/provider`;
          this.router.navigateByUrl(url);
          this.changeTaskService.triggerUpdateTask();
        }
        break;
      }
      case AiToolNameEnum.PROVIDER_SELECTION: {
        if (event.data.taskId) {
          const url = `/desk/${this.selectedCompany.id}/tasks/${event.data.taskId}/service-requests`;
          this.router.navigateByUrl(url);
        }
        break;
      }
      case AiToolNameEnum.OFFER_SELECTION:
      case AiToolNameEnum.OFFER_REQUEST: {
        if (event.data.taskId) {
          const url = `/desk/${this.selectedCompany.id}/tasks/${event.data.taskId}/offers`;
          this.router.navigateByUrl(url);
        }
        break;
      }
    }
  }
}
