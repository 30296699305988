import { DealsColumn } from '../enums/deals-column';

export const COLUMNS = [
  DealsColumn.NUMBER,
  DealsColumn.COMPANY_NAME,
  DealsColumn.COMPANY_TYPE,
  DealsColumn.COMPANY_COUNTRY,
  DealsColumn.TASK_NAME,
  DealsColumn.TASK_COUNTRY,
  DealsColumn.SERVICE,
  DealsColumn.IP_TYPE,
  DealsColumn.PROVIDER_NAME,
  DealsColumn.PROVIDER_COUNTRY,
  DealsColumn.CONFIRM_DATE,
  DealsColumn.ACCEPT_DELIVERY_DATE,
  DealsColumn.SERVICE_CHARGE,
  DealsColumn.GOVERNMENT_FEES,
  DealsColumn.CUSTOMER_FEES,
  DealsColumn.PROVIDER_FEES,
  DealsColumn.TASK_PRICE,
  DealsColumn.PAY_OUT_SUM,
  DealsColumn.REVENUE,
  DealsColumn.TAKE_RATE,
  DealsColumn.PAYMENT_DATE,
  DealsColumn.PAYOUT_DATE,
  DealsColumn.TRANSFER,
  DealsColumn.ACTION,
];
