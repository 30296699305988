import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { selectStateUser } from '../../store/selectors/user.selectors';

@Injectable({
  providedIn: 'root',
})
export class AccessTokenInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService, private store: Store<AppState>) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(selectStateUser).pipe(
      take(1),
      map((userState) =>
        !!userState.isAuthenticated &&
        (req.url.includes(environment.baseUrl) ||
          req.url.includes(environment.chatsBaseUrl) ||
          req.url.includes(environment.authBaseUrl))
          ? req.clone({
              headers: req.headers.set('Authorization', `Bearer ${userState.token.accessToken}`),
            })
          : req,
      ),
      mergeMap((newReq) => next.handle(newReq)),
    );
  }
}
