import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseUrlService } from '../../../../app-common/services/base-url/base-url.service';
import { Observable, Subject } from 'rxjs';
import { finalize, flatMap, mergeMap } from 'rxjs/operators';
import {
  IAddTrustedCompanyDto,
  ICompanyEntity,
  ICompanyPublicInfoEntity,
  ICreateCompanyDto,
  IGetManyDefaultResponse,
  IOnboardingStepsEntity
} from '@ipnote/interface';
import { concatLatestFrom } from '@ngrx/effects';
import {
  RequestQueryBuilderService
} from '../../../../app-common/services/request-query-builder/request-query-builder.service';
import { SpinnerViewService } from '../../../../app-common/services/spinner/spinner-view.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  public companyChange: Subject<ICompanyEntity> = new Subject<ICompanyEntity>();

  constructor(private http: HttpClient,
              private baseUrl: BaseUrlService,
              private queryBuilder: RequestQueryBuilderService,
              private spinnerService: SpinnerViewService,
              private snackBar: MatSnackBar) {
  }

  getAll(
    pageIndex: number = 1,
    pageSize: number = 10,
    searchToken?: string,
    searchFields?: string[],
    sort?: string,
    filter?: any,
    additionalOptions?: object,
    relations?: string[]
  ): Observable<IGetManyDefaultResponse<ICompanyEntity>> {
    return this.baseUrl.getBaseUrl('companies').pipe(
      concatLatestFrom(() => [
        this.queryBuilder.buildRequest({
            pageIndex,
            pageSize,
            searchToken,
            searchFields,
            sort,
            filter,
            additionalOptions,
            relations
          }
        )
      ]),
      mergeMap(([ url, params ]) => this.http.get<IGetManyDefaultResponse<ICompanyEntity>>(url, { params: { ...params } }))
    );
  }


  deleteCompany(company: ICompanyEntity) {
    this.spinnerService.start();
    return this.baseUrl.getBaseUrl(`companies/${company.id}/completely`).pipe(
      mergeMap((url) => this.http.delete<ICompanyEntity>(url)),
      finalize(() => {
        this.spinnerService.stop();
        this.snackBar.open(`The company '${company.name}' was deleted`, 'OK', { duration: 3000 });
      })
    );
  }
  get(id: number | ICompanyEntity, relations?: string[]): Observable<ICompanyEntity> {
    return this.baseUrl.getBaseUrl(`companies/${id}`).pipe(
      concatLatestFrom(() => [
        this.queryBuilder.buildRequest({
            relations
          }
        )
      ]),
      mergeMap(([ url, params ]) => this.http.get<ICompanyEntity>(url, { params: { ...params } }))
    );
  }

  update(id: number, body: any): Observable<ICompanyEntity> {
    return this.baseUrl.getBaseUrl(`companies/${id}`).pipe(flatMap((url) => this.http.patch<ICompanyEntity>(url, body)));
  }

  create(body: ICreateCompanyDto): Observable<ICompanyEntity> {
    return this.baseUrl.getBaseUrl('companies').pipe(flatMap((url) => this.http.post<ICompanyEntity>(url, body)));
  }

  getExecutors(serviceId: number,
               companyId: number,
               oipType: string,
               country: string,
               taskId: number,
               sort?: string,
               filter?: any): Observable<IGetManyDefaultResponse<ICompanyEntity>> {
    return this.baseUrl.getBaseUrl('companies/providers').pipe(
      concatLatestFrom(() => [
        this.queryBuilder.buildRequest({
          additionalOptions: {
            serviceId,
            companyId,
            oipType,
            country,
            taskId,
          },
          sort,
          filter
        })
      ]),
      mergeMap(([ url, params ]) => {
          return this.http.get<IGetManyDefaultResponse<ICompanyEntity>>(url, { params })
        }
      )
    );
  }

  inviteTrusted(body: IAddTrustedCompanyDto, id: number): Observable<ICompanyEntity> {
    return this.baseUrl.getBaseUrl(`companies/${id}/trusted`).pipe(flatMap((url) => this.http.post<ICompanyEntity>(url, body)));
  }

  deleteTrusted(trustedId: number, companyId: number): Observable<ICompanyEntity> {
    return this.baseUrl.getBaseUrl(`companies/${companyId}/trusted/${trustedId}`).pipe(flatMap((url) => this.http.delete<ICompanyEntity>(url)));
  }

  getServiceProvider(company: number, sort: string, searchToken: string): Observable<ICompanyEntity[]> {
    let params = new HttpParams().set('companyId', company.toString());

    if (sort) {
      params = params.set('sort', sort);
    }
    if (searchToken) {
      params = params.set('searchToken', searchToken);
      params = params.set('searchFields', 'name');
    }
    return this.baseUrl.getBaseUrl('companies/service-providers').pipe(
      flatMap((url) => this.http.get<ICompanyEntity[]>(url, { params }))
    );
  }

  getBalance(companyId: number) {
    return this.baseUrl.getBaseUrl(`companies/${companyId}/balance`).pipe(
      flatMap((url) => this.http.get<any>(url))
    );
  }

  getPublicInfo(companyId): Observable<ICompanyPublicInfoEntity> {
    return this.baseUrl.getBaseUrl(`companies/${companyId}/company-public-info/inner-usage`).pipe(flatMap((url) => this.http.get<ICompanyPublicInfoEntity>(url)));
  }

  createPublicInfo(companyId: number, body): Observable<ICompanyPublicInfoEntity> {
    return this.baseUrl.getBaseUrl(`companies/${companyId}/company-public-info`).pipe(flatMap((url) => this.http.post<ICompanyPublicInfoEntity>(url, body)));
  }

  updatePublicInfo(companyId: number, body): Observable<ICompanyPublicInfoEntity> {
    return this.baseUrl.getBaseUrl(`companies/${companyId}/company-public-info`).pipe(flatMap((url) => this.http.patch<ICompanyPublicInfoEntity>(url, body)));
  }

  saveExternalData(id: number, body): Observable<ICompanyPublicInfoEntity> {
    return this.baseUrl.getBaseUrl(`company-public-info/${id}`).pipe(flatMap((url) => this.http.patch<ICompanyPublicInfoEntity>(url, body)));
  }

  allowPublication(companyId: number): Observable<ICompanyPublicInfoEntity> {
    return this.baseUrl.getBaseUrl(`companies/${companyId}/company-public-info/approve`).pipe(flatMap((url) => this.http.patch<ICompanyPublicInfoEntity>(url, {})));
  }

  requestToEdit(companyId: number, body: any): Observable<ICompanyPublicInfoEntity> {
    return this.baseUrl.getBaseUrl(`companies/${companyId}/company-public-info/request-to-edit`).pipe(mergeMap((url) => this.http.patch<ICompanyPublicInfoEntity>(url, body)));
  }

  approvedOnboardingSteps(companyId: number): Observable<IOnboardingStepsEntity> {
    return this.baseUrl.getBaseUrl(`companies/${companyId}/onboarding-steps/approve`).pipe(
      mergeMap((url) => this.http.patch<IOnboardingStepsEntity>(url, {}))
    );
  }

  isTrustedTrue(id: number): Observable<ICompanyEntity> {
    return this.baseUrl.getBaseUrl(`companies/${id}/set-as-trusted`).pipe(mergeMap((url) => this.http.post<ICompanyEntity>(url, {})));
  }

  isTrustedFalse(id: number): Observable<ICompanyEntity> {
    return this.baseUrl.getBaseUrl(`companies/${id}/unset-as-trusted`).pipe(mergeMap((url) => this.http.post<ICompanyEntity>(url, {})));
  }

  getProviderClients(): Observable<ICompanyEntity[]> {
    return this.baseUrl.getBaseUrl('companies/provider-clients').pipe(flatMap((url) => this.http.get<ICompanyEntity[]>(url)));
  }
}
