import { createFeature, createReducer, on } from '@ngrx/store';
import { IUpdateLogRecordEntity } from '@ipnote/interface';
import { RouterNavigationStart, updateHistoryActions } from '../actions';

export interface UpdateHistoryStateInterface {
  isLoading: boolean;
  error: unknown;
  data: IUpdateLogRecordEntity[];
}

const initialState: UpdateHistoryStateInterface = {
  isLoading: false,
  error: null,
  data: null,
};

const updateHistoryFeature = createFeature({
  name: 'updateHistory',
  reducer: createReducer(
    initialState,
    on(updateHistoryActions.getUpdateHistory, (state) => ({ ...state, isLoading: true })),
    on(updateHistoryActions.getUpdateHistorySuccess, (state, action) => ({
      ...state,
      isLoading: false,
      data: action.updateHistory,
    })),
    on(updateHistoryActions.getUpdateHistoryFailure, (state, action) => ({
      ...state,
      isLoading: false,
      error: action.error,
    })),
    on(RouterNavigationStart, () => initialState),
  ),
});

export const {
  name: updateHistoryKey,
  reducer: updateHistoryReducer,
  selectIsLoading: selectUpdateHistoryIsLoading,
  selectError: selectUpdateHistoryError,
  selectData: selectUpdateHistoryData,
} = updateHistoryFeature;
