import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { UserSignInLinkedInCallback } from 'app/store/actions/user.actions';

@Injectable({
  providedIn: 'root'
})
export class LinkedInResolver implements Resolve<boolean> {

  constructor(
    private store: Store<AppState>
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    this.store.dispatch(UserSignInLinkedInCallback({ params: route.queryParams }));
    return of(true);
  }
}
