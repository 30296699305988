import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { RouterNavigationStart, RouterSetCurrentUrl, RouterSetPrevUrl } from '../../../store/actions';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';

@Injectable({
  providedIn: 'root'
})
export class RouterEventsService {
  constructor(
    private store: Store<AppState>,
    private router: Router
  ) {
  }

  public startRouterSetStore(): void {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd || e instanceof NavigationStart)
    ).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.id === 1) {
          this.store.dispatch(RouterSetCurrentUrl({
            urlCurrent: event.url
          }));
          this.store.dispatch(RouterSetPrevUrl({
            urlPrev: event.url
          }));
        } else {
          this.store.dispatch(RouterSetCurrentUrl({
            urlCurrent: event.url
          }));
        }
      } else if (event instanceof NavigationStart) {
        if (event.id === 1) {
          this.store.dispatch(RouterSetCurrentUrl({
            urlCurrent: event.url
          }));
        } else {
          this.store.dispatch(RouterNavigationStart());
        }
      }
    });
  }
}
