import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogState } from '@angular/material/dialog';
import { SpinnerViewComponent } from '../../components/spinner-view/spinner-view.component';

@Injectable({
  providedIn: 'root'
})
export class SpinnerViewService {
  dialogRef;

  constructor(
    private router: Router,
    private dialog: MatDialog
  ) {
  }

  start(message?) {
    if (this.dialogRef?.getState() !== MatDialogState.OPEN) {
      this.dialogRef = this.dialog.open(SpinnerViewComponent, {
        disableClose: true,
        data: message === '' || message === undefined ? 'Loading...' : message
      });
    }

  }

  stop(): void {
    if (this.dialogRef?.getState() === MatDialogState.OPEN) {
      this.dialogRef.close();
    }
  }

}
