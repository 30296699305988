import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { selectStateSelectedCompany } from '#selectors';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SetCompanyIdInterceptor implements HttpInterceptor {

  constructor(
    private auth: AuthService,
    private store: Store<AppState>
  ) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return this.store.select(selectStateSelectedCompany).pipe(
      take(1),
      map(
        (selectedCompany) =>
          !!selectedCompany && !req.url.includes('amazonaws')
            ? req.clone({
              headers: req.headers.set(
                'companyId',
                selectedCompany.id.toString()
              )
            })
            : req
      ),
      mergeMap(newReq => next.handle(newReq))
    );
  }
}
