import { Injectable } from '@angular/core';
import posthog from 'posthog-js';
import { selectStateSelectedCompany, selectStateUserInfo } from '#selectors';
import { filter, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { environment } from '../../../../environments/environment';
import { IAnalyticsUser, ICompanyEntity, IUserSignInRes } from '@ipnote/interface';
import { AnalyticsEventEnum, CompanyTypeEnum } from '@ipnote/enum';
import { EnumsService } from '../../../page-modules/desk/services/enums/enums.service';
import { TranslocoService } from '@ngneat/transloco';
import {
  IPipedriveOrganization,
  IPipedrivePerson,
  PipedriveService,
} from '../../../page-modules/desk/services/pipedrive/pipedrive.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  // private defaultInfo: IAnalyticsDefault;
  private user: IAnalyticsUser;
  currentUser: IUserSignInRes;
  currentUser$: Observable<IUserSignInRes> = this.store.select(selectStateUserInfo);

  constructor(
    private store: Store<AppState>,
    private enums: EnumsService,
    private translocoService: TranslocoService,
    private pipedriveService: PipedriveService,
  ) {
    this.currentUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.currentUser = user;
    });

    /***
     * If the company is updated, update the user data
     */
    this.store
      .select(selectStateSelectedCompany)
      .pipe(
        withLatestFrom(this.store.select(selectStateUserInfo)),
        filter(([c, u]) => !!u),
      )
      .subscribe(([company, user]) => {
        this.user = new CreatePostHogUser(user, company);
        this.updateUser(this.user);
      });

    /***
     * When authorizing or changing a user
     */
    this.store
      .select(selectStateUserInfo)
      .pipe(
        withLatestFrom(this.store.select(selectStateSelectedCompany)),
        filter(([u, c]) => !!u),
      )
      .subscribe(([user, company]) => {
        this.user = new CreatePostHogUser(user, company);
        this.setUser(this.user);
      });

    if (environment.posthog.enable) {
      posthog.init(environment.posthog?.token, { api_host: environment.posthog.api });
    }
  }

  /***
   * Set user analytics
   */
  public setUser(user: IAnalyticsUser): void {
    if (environment.posthog.enable) {
      posthog.identify(user.userId, user);
    }
    if (environment.googleTag) {
      // @ts-ignore
      window.dataLayer.push({ email: user.email });
    }
    if (environment.profitwell) {
      // @ts-ignore
      window.profitwell('start', { user_email: user.email });
    }
  }

  /***
   * Update user analytics
   */
  public updateUser(user: IAnalyticsUser): void {
    if (environment.posthog.enable) {
      posthog.capture('Set some user properties', {
        $set: user,
      });
    }

    if (environment.googleTag) {
      // @ts-ignore
      window.dataLayer.push({ email: user.email });
    }
  }

  /***
   * Sending an event to analytics
   */
  public async sendEvent(eventName: AnalyticsEventEnum | string, property: any): Promise<void> {
    console.log('Send Event', eventName);
    console.log('GoogleTag', environment.googleTag);
    if (environment?.pipeDrive?.enable) {
      if (eventName === AnalyticsEventEnum.SIGN_UP) {
        let personResp;
        let organisationResp;
        const person = new PipeDrivePerson(property?.user as IUserSignInRes);
        // eslint-disable-next-line prefer-const
        personResp = await new Promise((resolve, reject) => {
          this.pipedriveService.createPerson(person).subscribe((pers) => resolve(pers));
        });
        if (property?.company) {
          const organisation = new PipeDriveOrganisation(
            property.company,
            this.translocoService.translate(property.company.country),
          );
          organisationResp = await new Promise((resolve, reject) => {
            this.pipedriveService.createOrganization(organisation).subscribe((org) => resolve(org));
          });
        }
        if (personResp?.data && organisationResp?.data) {
          this.pipedriveService
            .updatePerson(personResp?.data?.id, { org_id: organisationResp.data.id })
            .subscribe((resp) => console.log('pipeDrive users updated'));
        }
      }
    }

    if (environment.posthog.enable) {
      let postHogProperty = property;
      if (eventName === AnalyticsEventEnum.SIGN_UP) {
        postHogProperty = {
          email: property.email,
          firstName: property.name,
          lastName: property.secondNamem,
          companyName: property.company.name,
          isProvider: property.company.type === this.enums.companyTypeEnum.PROVIDER,
        };
      }
      posthog.capture(eventName, {
        ...this.user,
        ...postHogProperty,
      });
    }

    if (environment.googleTag) {
      console.log('Google  event', eventName.toLowerCase().replace(' ', '_'));
      const email = property?.email ? property?.email : this.user?.email;
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({
        event: 'analytics_events',
        event_name: eventName.toLowerCase().replace(' ', '_'),
        email,
      });
    }
  }
}

export class PipeDriveOrganisation implements IPipedriveOrganization {
  name: string;
  owner_id: number; // Ian
  label: number;
  visible_to = '3'; // visible to all user,
  '08ba7be4abbda10a04fe90d2ea8e547b99dec999': string;

  constructor(company: ICompanyEntity, country: string) {
    const providerLabelId = 80;
    const ipOwnerLabelId = 1;
    this.name = company.name;
    this.owner_id = 13617122;
    this.label = company?.type === CompanyTypeEnum.CUSTOMER ? ipOwnerLabelId : providerLabelId;
    this['08ba7be4abbda10a04fe90d2ea8e547b99dec999'] = country;
    return this;
  }
}

export class PipeDrivePerson implements IPipedrivePerson {
  email: [{ value: string }];
  phone: [{ value: string }];
  name: string;
  org_id: number;
  visible_to: string;

  constructor(person: IUserSignInRes) {
    this.name = `${person?.name} ${person?.secondName}`;
    //this.org_id = organizationData.data.id;
    this.email = [
      {
        value: person?.email,
      },
    ];
    this.phone = [
      {
        value: person?.phone?.dialCode + person?.phone?.number,
      },
    ];
    this.visible_to = '3';
    return this;
  }
}

export class CreatePostHogUser implements IAnalyticsUser {
  userId: string;
  email: string;
  firstName: string;
  secondName: string;
  companyName: string;
  companyCountry: string;
  isProvider: boolean;
  isStuff: boolean;
  companyType: string;
  companyId: number;
  isPaidPlan: boolean;
  userHashIntercom: string;

  constructor(user: IUserSignInRes, company: ICompanyEntity) {
    this.userId = user?.id?.toString();
    this.email = user?.email;
    this.firstName = user?.name;
    this.secondName = user?.secondName;
    this.companyName = company?.name;
    this.companyCountry = company?.country;
    this.isProvider = company?.isServiceProvider;
    this.isStuff = user?.isStaff;
    this.companyId = company?.id;
    this.companyType = company?.type;
    this.isPaidPlan = false;
    /** @TODO: подписки */
    this.userHashIntercom = user.userHashIntercom;
    return this;
  }
}
