import { Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { PublicPageComponent } from 'app/page-modules/public-page/public-page.component';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss'],
})
export class ConfirmEmailComponent {
  @Input() email: string;
  @Output() closeSideNav = new EventEmitter<boolean>();

  constructor(@Optional() private publicPageComponent: PublicPageComponent) {}

  close() {
    this.publicPageComponent?.closeSideNav().then();
    this.closeSideNav.emit(true);
  }
}
