import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EnumsService } from '../../../page-modules/desk/services/enums/enums.service';
import { UserRoles } from '@ipnote/enum';

@Component({
  selector: 'app-update-permission-dialog',
  templateUrl: './update-permission-dialog.component.html',
  styleUrls: ['./update-permission-dialog.component.scss']
})
export class UpdatePermissionDialogComponent implements OnInit {
  form: UntypedFormGroup = this.fb.group({
    role: [null, Validators.required]
  });
  types = [UserRoles.COMPANY_READER, UserRoles.COMPANY_COLLABORATOR];

  constructor(
    private dialogRef: MatDialogRef<UpdatePermissionDialogComponent>,
    private fb: UntypedFormBuilder,
    public enums: EnumsService,
    @Inject(MAT_DIALOG_DATA) public title: string
  ) {
  }

  ngOnInit() {
  }

  save(): void {
    this.dialogRef.close({ role: this.form.get('role').value });
  }
}
