import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IGetManyDefaultResponse, IUpdateLogRecordEntity } from '@ipnote/interface';
import { BaseUrlService } from 'app/app-common/services/base-url/base-url.service';
import { concatLatestFrom } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';
import { RequestQueryBuilderService } from 'app/app-common/services/request-query-builder/request-query-builder.service';

@Injectable({
  providedIn: 'root',
})
export class UpdateHistoryService {
  constructor(
    private http: HttpClient,
    private baseUrl: BaseUrlService,
    private queryBuilder: RequestQueryBuilderService,
  ) {}

  getUpdateHistory(url: string): Observable<IGetManyDefaultResponse<IUpdateLogRecordEntity>> {
    return this.baseUrl.getBaseUrl(url).pipe(
      concatLatestFrom(() => [this.queryBuilder.buildRequest({})]),
      mergeMap(([url, params = {}]) => {
        return this.http.get<IGetManyDefaultResponse<IUpdateLogRecordEntity>>(url, { params });
      }),
    );
  }
}
