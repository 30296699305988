<mat-dialog-content>
  <h2 mat-dialog-title>{{ title }}</h2>
  <form [formGroup]="form" (ngSubmit)="save()">
    <mat-form-field>
      <mat-label>{{ 'role' | transloco }}</mat-label>
      <mat-select formControlName="role" required>
        <mat-option *ngFor="let type of types" [value]="type">{{type + '_role' | transloco}}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button type="button" color="primary" (click)="save()">{{ 'save' | transloco }}</button>
</mat-dialog-actions>

