import { ErrorResponseCreate } from '@ipnote/shared';
import { Action, createReducer, on } from '@ngrx/store';
import { CompanyCreate, CompanyCreateError, CompanyCreateSuccess } from '../../actions/company.actions';

export interface StateCompanyCreate {
  waitingServerResponse: boolean;
  error: ErrorResponseCreate;
}

export const initialStateSignIn: StateCompanyCreate = {
  waitingServerResponse: false,
  error: null
};

const companyCreateReducer = createReducer(
  initialStateSignIn,
  on(CompanyCreate, (state) => ({
    waitingServerResponse: true,
    error: null
  })),
  on(CompanyCreateSuccess, (state) => ({
    ...initialStateSignIn
  })),
  on(CompanyCreateError, (state, { error }) => ({
    waitingServerResponse: false,
    error
  }))
);

export function companyCreateReducerDefault(state: StateCompanyCreate | undefined, action: Action) {
  return companyCreateReducer(state, action);
}
