import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from '../../../../app-common/services/base-url/base-url.service';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

export interface IPipedriveOrganization {
  name: string;
  owner_id: number; // Ian
  label: number;
  visible_to: string; // visible to all user,
  '08ba7be4abbda10a04fe90d2ea8e547b99dec999': string;
}
export interface IPipedrivePerson {
  name: string;
  org_id: number;
  email: [{ value: string }];
  phone: [{ value: string }];
  visible_to: string;
}

@Injectable({
  providedIn: 'root',
})
export class PipedriveService {
  constructor(private http: HttpClient, private baseUrl: BaseUrlService) {}
  createOrganization(body: IPipedriveOrganization): Observable<any> {
    return this.http.post<any>(
      `${environment.pipeDrive.apiUrl}/organizations?api_token=${environment.pipeDrive.apiToken}`,
      body,
    );
  }

  createPerson(body: IPipedrivePerson) {
    return this.http.post<any>(
      `${environment.pipeDrive.apiUrl}/persons?api_token=${environment.pipeDrive.apiToken}`,
      body,
    );
  }
  updatePerson(id: string, body: any) {
    return this.http.put<any>(
      `${environment.pipeDrive.apiUrl}/persons/${id}?api_token=${environment.pipeDrive.apiToken}`,
      body,
    );
  }

  getUserByEmail(email: string) {
    return this.http.get(
      `${environment.pipeDrive.apiUrl}/persons?term=${email}&exact_match=1&api_token=${environment.pipeDrive.apiToken}`,
    );
  }
}
