export enum CounterpartyType {
  LE = 'LE',
  IP = 'IP',
  PERSONAL = 'personal',
  COSP = 'COSP',
}

/***
 * @TODO: переименовать в верхний регистр
 */
export const typesForRussianCountries = [CounterpartyType.LE, CounterpartyType.IP, CounterpartyType.PERSONAL];

export const typesForNonRussianCountries = [CounterpartyType.PERSONAL, CounterpartyType.COSP];
