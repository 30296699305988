import { Counterparty } from '../counterparty/counterparty.entity';
import { Invention } from '../invention/invention.entity';
import { OIP } from '../oip/oip.entity';
import { Product } from '../product/product.entity';
import { Task } from '../task/task.entity';
import { TaskResult } from '../task/task-result.entity';
import { RIA } from '../ria/ria.entity';
import { Transaction } from '../transaction/transaction.entity';


export declare type SFiledValues = SPrimitivesVal | Array<SPrimitivesVal>;
export declare type SPrimitivesVal = string | number | boolean;
export declare type ModelsRepositoryFilesType =
  | OIP
  | Task
  | TaskResult
  | Product
  | Invention
  | RIA
  | Counterparty
  | Transaction;

/** *
 * OIP tags types
 */
export declare type OIPFilesTags = [FileTagsFolderTypes, FileTagsTypes];

export declare type FileTagsTypes = keyof TagsType | string;
export declare type FileTagsFolderTypes = keyof TagsFolderType | string;

export declare type TagsFolderType = {
  output?: SFiledValues;
  input?: SFiledValues;
};
export declare type TagsType = {
  application_documentation?: SFiledValues;
  formal_examination_request_response?: SFiledValues;
  substantive_request_response?: SFiledValues;
  issuance_fee?: SFiledValues;
  duty_payment?: SFiledValues;
  withdraw_request?: SFiledValues;
  withdraw_documents?: SFiledValues;
  restore_request?: SFiledValues;
  other?: SFiledValues;
  admission_notice?: SFiledValues;
  formal_examination_request?: SFiledValues;
  formal_examination_decision?: SFiledValues;
  substantive_request?: SFiledValues;
  registration_decision?: SFiledValues;
  extradition_refusal?: SFiledValues;
  security_document?: SFiledValues;
  withdraw_notification?: SFiledValues;
  withdraw_decision?: SFiledValues;
  restore_decision?: SFiledValues;
  withdraw_denied?: SFiledValues;
  fips_decision?: SFiledValues;
  fips_denied?: SFiledValues;
  po_opposition?: SFiledValues;
};

export enum FolderTypes {
  OUTPUT = 'output',
  INPUT = 'input',
}

export enum OutputFolderTypes {
  APPLICATION_DOCUMENTATION = 'application_documentation', // Заявочная документация - Application documents
  REQUEST_OF_EXAMINATION = 'request_of_examination', // Request of examination
  SUBSTANTIVE_REQUEST_RESPONSE = 'substantive_request_response', // Ответ на запрос по существу - Response to Office Action
  ISSUANCE_FEE = 'issuance_fee', // Оплата за регистрацию - Issue Fee
  DUTY_PAYMENT = 'duty_payment', // Оплата за поддержание в силе - Maintenance/Renewal fee
  RESPONSE_TO_OPPOSITION = 'response_to_opposition', // Response to Opposition
  OTHER = 'other',
}

export enum InputFolderTypes {
  ADMISSION_NOTICE = 'admission_notice', // Уведомление о поступлении - Filing Receipt
  SUBSTANTIVE_REQUEST = 'substantive_request', // запрос по существу - Office Action
  REGISTRATION_DECISION = 'registration_decision', // решение о регистрации - Notice of Allowance
  EXTRADITION_REFUSAL = 'extradition_refusal', // Отказ в выдаче - Refusal of Granting
  SECURITY_DOCUMENT = 'security_document', // Охранный документ - Patent/Certificate
  PO_OPPOSITION = 'po_opposition', // Возражение - Opposition
  OTHER = 'other',
}

/** *
 * TaskResult types
 */
export declare type TaskResultFilesTags = [TaskResultFileTagsFolderTypes, TaskResultFileTagsTypes];

export declare type TaskResultFileTagsFolderTypes = keyof TaskResultTagsFolderType;
export declare type TaskResultFileTagsTypes = keyof TaskResultTagsType;

export declare type TaskResultTagsFolderType = {
  other?: SFiledValues;
};
export declare type TaskResultTagsType = {
  result_files?: SFiledValues;
  result_act?: SFiledValues;
  rejected_files?: SFiledValues;
  accepted_files?: SFiledValues;
};

export enum TaskResultFolderTypes {
  OTHER = 'other',
}

export enum TaskResultTagsFilesTypes {
  RESULT = 'result_files',
  RESULT_ACT = 'result_act',
  REJECTED = 'rejected_files',
  ACCEPTED = 'accepted_files',
}
