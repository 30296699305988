import { AfterContentInit, Directive, ElementRef, EventEmitter, Input, NgZone, Output, QueryList } from '@angular/core';
import SimpleBar from 'simplebar';

@Directive({
  selector: '[vexScrollbar],vex-scrollbar',
  host: {
    class: 'vex-scrollbar',
  },
})
export class ScrollbarDirective implements AfterContentInit {
  @Input('vexScrollbar') options: Partial<string>;
  @Input() children: QueryList<any>;
  @Input() startElement: string;
  @Input() list: any[];
  @Input() offset: number;
  @Output() afterScroll = new EventEmitter();

  scrollbarRef: SimpleBar;

  constructor(private _element: ElementRef, private zone: NgZone) {}

  ngAfterContentInit() {
    this.zone.runOutsideAngular(() => {
      this.scrollbarRef = new SimpleBar(this._element.nativeElement, this.options as any);
      if (this.children && this.startElement) this.scrollTo();
    });
  }

  scrollTo() {
    const subscription = this.children.changes.subscribe((_) => {
      const index = this.list.findIndex((item) => item._id === this.startElement);
      this.scrollbarRef.getScrollElement().scroll({
        top: index * this.offset,
        left: 0,
        behavior: 'smooth',
      });
      subscription.unsubscribe();
      this.afterScroll.emit();
    });
  }
}
