import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EnumsService } from '../../../services/enums/enums.service';
import { ITaskEntity } from '@ipnote/interface';

@Component({
  selector: 'app-reject-task-dialog',
  templateUrl: './reject-task-dialog.component.html',
  styleUrls: ['./reject-task-dialog.component.scss'],
})
export class RejectTaskDialogComponent implements OnInit {
  form: FormGroup;

  constructor(
    public enums: EnumsService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<RejectTaskDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      task: ITaskEntity;
    },
  ) {
    this.form = this.formBuilder.group({
      option: [],
      text: [{ value: '', disabled: true }],
    });
  }

  ngOnInit() {
    this.form.get('option').valueChanges.subscribe((reason) => {
      if (reason === 'other') {
        this.form.get('text').enable();
      } else {
        this.form.get('text').disable();
      }
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  reject() {
    this.dialogRef.close(this.form.value);
  }
}
