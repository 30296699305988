import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { catchError, map, of, switchMap } from 'rxjs';
import { MarkifyDBsRecencyService } from 'app/page-modules/desk/modules/searches/trademark-search/markify-dbs-recency/markify-dbs-recency.service';
import { markifyDBsRecencyActions } from '../actions';

@Injectable()
export class MarkifyDbsRecencyEffects {
  getMarkifyDBsRecency$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(markifyDBsRecencyActions.getMarkifyDbsRecency),
      switchMap(() => {
        return this.markifyDBsRecencyService.getMarkifyDBsRecency().pipe(
          map((resp) => {
            return markifyDBsRecencyActions.getMarkifyDbsRecencySuccess({ dbsRecency: resp });
          }),
          catchError((error) => {
            return of(markifyDBsRecencyActions.getMarkifyDbsRecencyFailure({ error }));
          }),
        );
      }),
    );
  });

  constructor(private actions$: Actions, private markifyDBsRecencyService: MarkifyDBsRecencyService) {}
}
