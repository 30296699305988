<mat-error *ngIf="formError && showError">
  <ng-container *ngFor="let item of formError | keyvalue">
    <div [ngSwitch]="item.key">
      <div *ngSwitchCase="'required'">
        {{ errorTextRequired ? (errorTextRequired | transloco) : 'Required to fill' }}
      </div>
      <div *ngSwitchCase="'minlength'">
        {{ errorTextMinLengtht ? (errorTextMinLengtht | transloco) : 'Small length' }}
      </div>
      <div *ngSwitchCase="'pattern'">
        {{ errorTextPattern ? (errorTextPattern | transloco) : 'Incorrect value' }}
      </div>
      <div *ngSwitchCase="'server'">
        <ng-container *ngFor="let itemServer of item.value | keyvalue">
          {{itemServer?.value.toString() | transloco}}
        </ng-container>
      </div>
    </div>
  </ng-container>
</mat-error>
