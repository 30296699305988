import { ICompanyEntity } from '@ipnote/interface';
import { Action, createReducer, on } from '@ngrx/store';
import { CompanySelect, CompanySelectReset, CompanySetData } from '../../actions/company.actions';

export interface StateCompanySelect {
  companySelect: ICompanyEntity;
}

export const initialState: StateCompanySelect = {
  companySelect: null,
};

const companySelectReducer = createReducer(
  initialState,
  on(CompanySelect, (state, { company }) => ({
    ...state,
    companySelect: company,
  })),
  on(CompanySelectReset, (state) => ({
    ...state,
    companySelect: null,
  })),
  on(CompanySetData, (state, { company }) => ({
    ...state,
    companySelect: company,
  })),
);

export function companySelectReducerDefault(state: StateCompanySelect | undefined, action: Action) {
  return companySelectReducer(state, action);
}
