import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AppState } from '#appState';
import { Store } from '@ngrx/store';
import { ELanguage, PublicPageSteps } from '@ipnote/enum';
import googleIcon from '@iconify/icons-logos/google-icon';
import linkedInIcon from '@iconify/icons-logos/linkedin-icon';
import { fadeInUp400ms } from '@vex/animations/fade-in-up.animation';
import { selectSignInState } from 'app/store/selectors/user.selectors';
import { StateSignIn } from 'app/store/reducers/user';
import { AuthService } from 'app/app-common/services/auth/auth.service';
import { DialogService } from 'app/app-common/services/dialogs/dialog.service';
import { SetTitleService } from 'app/app-common/services/set-title/set-title.service';
import { PublicPageComponent } from 'app/page-modules/public-page/public-page.component';
import { setFormError } from 'app/app-common/utils/set-form-error';
import { UserService } from 'app/page-modules/desk/services/user/user.service';
import { UserSignIn, UserSignInError } from 'app/store/actions';
import { UserCheckBackUrl } from '../../../../store/actions/user.actions';

@UntilDestroy()
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  animations: [fadeInUp400ms],
})
export class SignInComponent implements OnInit {
  googleIcon = googleIcon;
  linkedInIcon = linkedInIcon;
  public publicPageSteps = PublicPageSteps;
  @Input() sideNavMode: boolean;
  @Output() stepsHandler = new EventEmitter<PublicPageSteps>();
  @Output() closeSideNav = new EventEmitter<boolean>();

  public signInStore$: Observable<StateSignIn> = this.store.select(selectSignInState);
  form: UntypedFormGroup = this.fb.group({
    email: ['', Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')],
    password: ['', Validators.required],
    save: [false, Validators.required],
  });

  inputType = 'password';
  visible = false;

  icVisibility = icVisibility;
  icVisibilityOff = icVisibilityOff;
  isWaitingResponse = false;

  language = '';

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private auth: AuthService,
    private dialogs: DialogService,
    private title: Title,
    @Inject(PLATFORM_ID) platformId: object,
    private translocoService: TranslocoService,
    public titleService: SetTitleService,
    private userService: UserService,
    private matDialog: MatDialog,
    private store: Store<AppState>,
    @Optional() private publicPageComponent: PublicPageComponent,
  ) {
    this.store.dispatch(UserCheckBackUrl());

    this.signInStore$
      .pipe(
        filter((state, i) => state.error !== null),
        untilDestroyed(this),
      )
      .subscribe((state) => {
        if (typeof state.error.error.message !== 'string') {
          const formatError = setFormError(this.form, state.error.error.message);
          this.form = formatError.form;
        } else {
          this.dialogs.signInError(state.error).subscribe((dialogResult) => {
            this.store.dispatch(UserSignInError({ error: null }));
          });
        }
      });
  }

  ngOnInit(): void {
    // this.language = window.navigator.language.substr(0, 2).toLowerCase();
    // if (this.language && ['ru', 'en'].includes(this.language)) this.translocoService.setActiveLang(this.language);
    this.translocoService.setActiveLang(ELanguage.EN);
    this.titleService.setTitle('ipnote_title');
  }

  send(): void {
    this.store.dispatch(UserSignIn(this.form.value));
    if (this.sideNavMode) {
      this.closeSideNav.emit(true);
      this.publicPageComponent?.closeSideNav();
    }
  }

  toggleVisibility(): void {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }
}
