import { Injectable } from '@angular/core';
import {
  ISendVerificationCodeRequest,
  ISendVerificationCodeResponse,
  IVerifyCodeRequest,
  IVerifyCodeResponse,
} from '@ipnote/interface';
import { HttpClient } from '@angular/common/http';
import { concatLatestFrom } from '@ngrx/effects';
import { finalize, mergeMap } from 'rxjs/operators';
import { BaseUrlService } from 'app/app-common/services/base-url/base-url.service';
import { RequestQueryBuilderService } from '../../../../app-common/services/request-query-builder/request-query-builder.service';
import { Observable } from 'rxjs';
import { SpinnerViewService } from '../../../../app-common/services/spinner/spinner-view.service';

@Injectable({
  providedIn: 'root',
})
export class TwoFactorVerificationService {
  constructor(
    private spinnerService: SpinnerViewService,
    private http: HttpClient,
    private baseUrl: BaseUrlService,
    public queryBuilder: RequestQueryBuilderService,
  ) {}

  sendCode(body: ISendVerificationCodeRequest): Observable<ISendVerificationCodeResponse> {
    this.spinnerService.start();
    return this.baseUrl.getBaseUrl('verification-codes/send-verification-code').pipe(
      concatLatestFrom(() => [this.queryBuilder.buildRequest({})]),
      finalize(() => this.spinnerService.stop()),
      mergeMap(([url, params]) => this.http.post<ISendVerificationCodeResponse>(url, body)),
    );
  }

  verifyCode(body: IVerifyCodeRequest) {
    this.spinnerService.start();
    return this.baseUrl.getBaseUrl('verification-codes/verify-code').pipe(
      concatLatestFrom(() => [this.queryBuilder.buildRequest({})]),
      finalize(() => this.spinnerService.stop()),
      mergeMap(([url, params]) => this.http.post<IVerifyCodeResponse>(url, body)),
    );
  }
}
