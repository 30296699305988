import { Component } from '@angular/core';
import { CommonModule, NgClass, NgForOf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { CreateTaskHeaderService, ICreateTaskHeaderStep } from './create-task-header-service';

@Component({
  selector: 'app-create-task-header-time-line',
  templateUrl: './create-task-header-time-line.component.html',
  styleUrls: ['./create-task-header-time-line.component.scss'],
  standalone: true,
  imports: [NgForOf, MatIconModule, NgClass, CommonModule],
})
export class CreateTaskHeaderTimeLineComponent {
  public readonly ICreateTaskHeaderStep = ICreateTaskHeaderStep;

  constructor(public createTaskHeaderService: CreateTaskHeaderService) {}
}
