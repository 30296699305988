import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-priority-document-dialog',
  templateUrl: './priority-document-dialog.html',
  styleUrls: ['./priority-document-dialog.scss']
})
export class PriorityDocumentDialogComponent implements OnInit {
  urlExp = 'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)';

  form: UntypedFormGroup = this.fb.group({
    request: [null, [Validators.pattern(this.urlExp)]]
  });

  constructor(
    public dialogRef: MatDialogRef<PriorityDocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title?: string;
      request?: string;
    },
    private fb: UntypedFormBuilder
  ) {
    this.form.get('request').patchValue(data.request);
  }

  ngOnInit() {
  }

  set() {
    this.dialogRef.close({
      request: this.form.get('request').value
    });
  }

  goToLink() {
    const request = this.form.get('request').value;
    return request ? window.open(request, '_blank') : false;
  }
}
