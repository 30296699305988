import { createReducer, on } from '@ngrx/store';
import {
  actionButtonClicked,
  collapseProviderOnboardingPanel,
  expandProviderOnboardingPanel,
} from '../actions/onboarding-provider-panel.actions';

export interface OnboardingProviderPanelState {
  actionButton: {
    actionButtonName: string;
    count: number;
  };
  panelIsOpen: boolean;
}

export const initialState: OnboardingProviderPanelState = {
  actionButton: {
    actionButtonName: null,
    count: 0,
  },
  panelIsOpen: null,
};

export const onboardingProviderPanelReducer = createReducer(
  initialState,
  on(actionButtonClicked, (state, { buttonAction }) => ({
    ...state,
    actionButton: { actionButtonName: buttonAction, count: state.actionButton.count + 1 },
  })),
  on(expandProviderOnboardingPanel, (state) => ({
    ...state,
    panelIsOpen: true,
  })),
  on(collapseProviderOnboardingPanel, (state) => ({
    ...state,
    panelIsOpen: false,
  })),
);
