import { Component } from '@angular/core';
import { AppState } from '#appState';
import { Store } from '@ngrx/store';
import { EnumsService } from '../../../page-modules/desk/services/enums/enums.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-registration-helper',
  templateUrl: './registration-helper.component.html',
  styleUrls: ['./registration-helper.component.scss'],
})
export class RegistrationHelperComponent {
  constructor(public store: Store<AppState>, public enums: EnumsService) {}
}
