import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment.googleTag) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const googleTag = require('./scripts/google-tag-manager'); // с импортом будет работать не правильно
  const script = window.document.createElement('script');
  script.type = 'text/javascript';
  script.textContent = googleTag;
}

Sentry.init({
  dsn: environment.sentry,
  environment: environment.environment,
  release: environment.version,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', environment.baseUrl],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((success) => console.log('Bootstrap success'))
    .catch((err) => console.error(err));
});
