<div @fadeInUp class="overflow-hidden w-full sign-in">
  <ipnote-first-page-header></ipnote-first-page-header>
  <div [formGroup]="form" fxLayout="column" class="">
    <div fxFlex="auto" fxLayout="column" class="py-0 my-0">
      <mat-form-field class="" fxFlex="grow" appearance="outline">
        <mat-label>{{ 'e-mail' | transloco }}</mat-label>
        <input formControlName="email" matInput required/>
        <!--            <app-form-error [formError]="form.controls['email']?.errors" [showError]="form.invalid && form.touched"></app-form-error>-->
      </mat-form-field>
      <mat-form-field fxFlex="grow" appearance="outline">
        <mat-label>{{ 'password' | transloco }}</mat-label>
        <input (keyup.enter)="send()" [type]="inputType" formControlName="password" matInput required/>
        <button (click)="toggleVisibility()" mat-icon-button matSuffix type="button"
                matTooltip="{{ 'toggle-visibility' | transloco }}">
          <mat-icon class="ic-visibility" *ngIf="visible" [icIcon]="icVisibility"></mat-icon>
          <mat-icon class="ic-visibility" *ngIf="!visible" [icIcon]="icVisibilityOff"></mat-icon>
        </button>
        <!--            <app-form-error [formError]="form.controls['password']?.errors" [showError]="form.invalid && form.touched"></app-form-error>-->
      </mat-form-field>
    </div>
    <div fxLayout="row" class="mb-4 sign-in__add-text" fxLayoutAlign="space-between center">
      <mat-checkbox color="primary" formControlName="save">{{ "remember-me" | transloco }}
      </mat-checkbox>
      <a [routerLink]="['/auth/forgot-password']">{{ "forgot-password" | transloco }}</a>
    </div>
    <button
      class="btn-icon__spinner sign-in__button"
      [ngClass]="{'white-text': !(form.invalid || form.disabled || (signInStore$ | async).waitingServerResponse)}"
      mat-raised-button
      type="button"
      [disabled]="form.invalid || form.disabled || (signInStore$ | async).waitingServerResponse"
      (click)="send()"
    >
      {{ "Sign in" | transloco }}
      <mat-spinner *ngIf="(signInStore$ | async).waitingServerResponse" [diameter]="25"></mat-spinner>
    </button>

    <app-idp-sign-in></app-idp-sign-in>
    <div fxFlex="auto" fxLayout="row" class="pt-8 sign-in__add-text" fxLayoutAlign="center">
      <span class="pr-1">{{ "dont-have-an-account" | transloco }}</span><br/>
      <a class="cursor-pointer" *ngIf="sideNavMode; else elseBlock"
         (click)="stepsHandler.emit(publicPageSteps.PERSONAL_FIELD)">{{ "click-here-to-create-one" | transloco }}</a>
      <ng-template #elseBlock>
        <a [routerLink]="['/auth/sign-up']">{{ "click-here-to-create-one" | transloco }}</a>
      </ng-template>
    </div>
  </div>
</div>

