import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-files-preview',
  templateUrl: './upload-files-preview.component.html',
  styleUrls: [ './upload-files-preview.component.scss' ],
})
export class UploadFilesPreviewComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: File[],
              private dialogRef: MatDialogRef<UploadFilesPreviewComponent>) {
  }
  close(): void {
    this.dialogRef.close(null);
  }
  send(): void {
    this.dialogRef.close(this.data);
  }

  fileSelected($event: Event) {
    for (let i = 0; i < $event.target['files'].length; i++) {
      const file = $event.target['files'][i];
      this.data.push(file);
    }
  }

  deleteFile(f: File) {
    this.data = this.data.filter(file => file.name !== f.name)
  }
}
