import { CountryCode } from '../../user/enums/country-code.enum';

export const SmsSupportedCountries = [
  // CountryCode.Abkhazia.toUpperCase(),
  CountryCode.Afghanistan.toUpperCase(),
  CountryCode.Albania.toUpperCase(),
  CountryCode.Algeria.toUpperCase(),
  CountryCode.Andorra.toUpperCase(),
  CountryCode.Angola.toUpperCase(),
  CountryCode.AntiguaAndBarbuda.toUpperCase(),
  CountryCode.Armenia.toUpperCase(),
  CountryCode.Austria.toUpperCase(),
  CountryCode.Azerbaijan.toUpperCase(),
  CountryCode.Bahrain.toUpperCase(),
  CountryCode.Bangladesh.toUpperCase(),
  CountryCode.Barbados.toUpperCase(),
  CountryCode.Benin.toUpperCase(),
  CountryCode.Belize.toUpperCase(),
  CountryCode.Bhutan.toUpperCase(),
  CountryCode.Bolivia.toUpperCase(),
  CountryCode.Botswana.toUpperCase(),
  CountryCode.Brazil.toUpperCase(),
  CountryCode.BritishVirginIslands.toUpperCase(),
  CountryCode.Brunei.toUpperCase(),
  CountryCode.Bulgaria.toUpperCase(),
  CountryCode.BurkinaFaso.toUpperCase(),
  CountryCode.Burundi.toUpperCase(),
  CountryCode.Cambodia.toUpperCase(),
  CountryCode.Cameroon.toUpperCase(),
  CountryCode.CaymanIslands.toUpperCase(),
  CountryCode.Chad.toUpperCase(),
  CountryCode.Croatia.toUpperCase(),
  CountryCode.Cyprus.toUpperCase(),
  CountryCode.Dominica.toUpperCase(),
  CountryCode.Denmark.toUpperCase(),
  CountryCode.CongoDRCJamhuriYaKidemokrasiaYaKongo.toUpperCase(),
  CountryCode.Egypt.toUpperCase(),
  CountryCode.ElSalvador.toUpperCase(),
  CountryCode.Estonia.toUpperCase(),
  CountryCode.Ethiopia.toUpperCase(),
  CountryCode.Finland.toUpperCase(),
  CountryCode.Fiji.toUpperCase(),
  CountryCode.France.toUpperCase(),
  CountryCode.Gabon.toUpperCase(),
  CountryCode.Georgia.toUpperCase(),
  CountryCode.Germany.toUpperCase(),
  CountryCode.Ghana.toUpperCase(),
  CountryCode.Greece.toUpperCase(),
  CountryCode.Grenada.toUpperCase(),
  CountryCode.Guatemala.toUpperCase(),
  CountryCode.Guyana.toUpperCase(),
  CountryCode.Haiti.toUpperCase(),
  CountryCode.Honduras.toUpperCase(),
  CountryCode.Indonesia.toUpperCase(),
  CountryCode.Iraq.toUpperCase(),
  CountryCode.Ireland.toUpperCase(),
  CountryCode.Israel.toUpperCase(),
  // CountryCode.IvoryCoast.toUpperCase(),
  CountryCode.Jamaica.toUpperCase(),
  CountryCode.Japan.toUpperCase(),
  CountryCode.Jordan.toUpperCase(),
  CountryCode.Kenya.toUpperCase(),
  CountryCode.Kyrgyzstan.toUpperCase(),
  CountryCode.Laos.toUpperCase(),
  CountryCode.Latvia.toUpperCase(),
  CountryCode.Lebanon.toUpperCase(),
  CountryCode.Lesotho.toUpperCase(),
  CountryCode.Libya.toUpperCase(),
  CountryCode.Lithuania.toUpperCase(),
  CountryCode.Luxembourg.toUpperCase(),
  CountryCode.Madagascar.toUpperCase(),
  CountryCode.Macau.toUpperCase(),
  CountryCode.Malawi.toUpperCase(),
  CountryCode.Maldives.toUpperCase(),
  CountryCode.Mali.toUpperCase(),
  CountryCode.Malta.toUpperCase(),
  CountryCode.Mauritania.toUpperCase(),
  CountryCode.Mexico.toUpperCase(),
  CountryCode.Moldova.toUpperCase(),
  CountryCode.Mongolia.toUpperCase(),
  CountryCode.Montenegro.toUpperCase(),
  CountryCode.Montserrat.toUpperCase(),
  CountryCode.Morocco.toUpperCase(),
  CountryCode.Mozambique.toUpperCase(),
  CountryCode.Myanmar.toUpperCase(),
  CountryCode.Nepal.toUpperCase(),
  CountryCode.Netherlands.toUpperCase(),
  CountryCode.NewCaledonia.toUpperCase(),
  CountryCode.Norway.toUpperCase(),
  CountryCode.Oman.toUpperCase(),
  CountryCode.Pakistan.toUpperCase(),
  CountryCode.PapuaNewGuinea.toUpperCase(),
  CountryCode.Panama.toUpperCase(),
  CountryCode.Paraguay.toUpperCase(),
  CountryCode.Portugal.toUpperCase(),
  CountryCode.Romania.toUpperCase(),
  CountryCode.Rwanda.toUpperCase(),
  CountryCode.SaintLucia.toUpperCase(),
  CountryCode.SaintKittsAndNevis.toUpperCase(),
  CountryCode.SaintVincentAndTheGrenadines.toUpperCase(),
  CountryCode.Samoa.toUpperCase(),
  CountryCode.Serbia.toUpperCase(),
  CountryCode.Senegal.toUpperCase(),
  CountryCode.Slovakia.toUpperCase(),
  CountryCode.Slovenia.toUpperCase(),
  CountryCode.Somalia.toUpperCase(),
  CountryCode.SouthSudan.toUpperCase(),
  CountryCode.Spain.toUpperCase(),
  CountryCode.SierraLeone.toUpperCase(),
  CountryCode.SriLanka.toUpperCase(),
  CountryCode.Sudan.toUpperCase(),
  CountryCode.Suriname.toUpperCase(),
  CountryCode.Sweden.toUpperCase(),
  CountryCode.Switzerland.toUpperCase(),
  CountryCode.Tajikistan.toUpperCase(),
  CountryCode.Tanzania.toUpperCase(),
  CountryCode.Togo.toUpperCase(),
  CountryCode.TrinidadAndTobago.toUpperCase(),
  CountryCode.Tunisia.toUpperCase(),
  CountryCode.Uganda.toUpperCase(),
  CountryCode.Ukraine.toUpperCase(),
  CountryCode.UnitedKingdom.toUpperCase(),
  CountryCode.Uzbekistan.toUpperCase(),
  CountryCode.Venezuela.toUpperCase(),
  CountryCode.Vietnam.toUpperCase(),
  CountryCode.Zambia.toUpperCase(),
  CountryCode.Zimbabwe.toUpperCase(),
  CountryCode.SouthAfrica.toUpperCase(),
  CountryCode.CongoRepublicCongoBrazzaville.toUpperCase(),
  CountryCode.Mauritius.toUpperCase(),
  CountryCode.Gambia.toUpperCase(),
  CountryCode.Liberia.toUpperCase(),
  CountryCode.Greenland.toUpperCase(),
];
