<div
  *ngIf="provider?.companyPublicInfo?.ratingCount !== 0; else newUser"
  [matTooltipPosition]="'above'"
  [matTooltip]="
    'The provider has ' +
    provider?.companyPublicInfo?.ratingCount +
    ' reviews, with an average of ' +
    (provider?.companyPublicInfo?.rating | number : '1.2-2')
  "
  class="font-medium flex flex-nowrap"
>
  <ic-icon class="mr-1" style="color: #f0a93b" [icIcon]="icStar" [inline]="true"></ic-icon>
  <span class="font-medium rating">{{ provider?.companyPublicInfo?.rating | number : '1.2-2' }}</span>
  <div class="ml-1 rating-count" *ngIf="provider?.companyPublicInfo?.ratingCount !== 0">
    ({{ provider?.companyPublicInfo?.ratingCount }})
  </div>
</div>
<ng-template #newUser>
  <span class="whitespace-nowrap font-medium" style="color: #8997b1">New user</span>
</ng-template>
