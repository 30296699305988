export enum TaskStages {
  NEW = 'new', // Old enum
  DRAFT = 'draft', // task draft
  CHOICE_SERVICE = 'choice_service', // Old enum
  CHOICE_EXECUTOR = 'choice_executor',
  CONFIRM = 'confirm', // service request was added / the provider made an offer
  RUNNING = 'running', // offer was accepted by client
  IN_PROGRESS = 'in_progress', // provider took the task to work
  CHECK = 'check', // provider did task
  REOPEN = 'reopen', // client returned the task for revision
  DONE = 'done', // client accepted the job
  CANCELED = 'canceled', // Old enum
}
