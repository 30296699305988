import { Component, OnInit } from '@angular/core';
import { AppState } from '#appState';
import { Store } from '@ngrx/store';
import { UserGetInfo } from '../../../../store/actions/user.actions';

@Component({
  selector: 'app-create-first-company',
  templateUrl: './create-first-company.component.html',
  styleUrls: ['./create-first-company.component.scss'],
})
export class CreateFirstCompanyComponent implements OnInit {
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.store.dispatch(UserGetInfo());
  }
}
