import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from '../../../../../../app-common/services/base-url/base-url.service';
import { Observable } from 'rxjs';
import { MarkifyDBsRecency } from '@ipnote/entity';
import { mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MarkifyDBsRecencyService {
  constructor(private http: HttpClient, private baseUrl: BaseUrlService) {}

  getMarkifyDBsRecency(): Observable<MarkifyDBsRecency[]> {
    return this.baseUrl.getBaseUrl('markify/databases-recency').pipe(
      mergeMap((url) => {
        return this.http.get<MarkifyDBsRecency[]>(url);
      }),
    );
  }
}
