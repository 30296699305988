<div class="flex h-full sm:flex-row flex-col">
  <div class="sm:w-1/2 h-full bg-white flex flex-col">
    <app-create-task-header-time-line class="pt-[20px] mx-4"></app-create-task-header-time-line>
    <div class="sm:px-8 px-4 grow pb-6 overflow-hidden"
         style="max-height: calc(100vh - 67px);"
    >
      <app-chat-ai></app-chat-ai>
    </div>
  </div>
  <div class="hidden sm:flex sm:w-1/2 bg-designSystem-light-gray flex-col p-4 ">
    <div class="basis-1/2 flex justify-center sm:mb-0 mb-8">
      <div class="basis-[450px] flex justify-center items-center flex-col">
        <h2 class="font-bold">What is next?</h2>
        <span class="mt-6 text-center">Our AI assistant will help you create the perfect brief and send it directly to suitable IP law firms. Shortly thereafter, you'll begin receiving offers, allowing you to select the best one based on your preferred criteria, such as price, experience, rating, and more.</span>
      </div>
    </div>
    <div class="basis-1/2 flex px-4 sm:overflow-hidden">
      <img class="rotate-[-3deg] grow object-contain" src="../../../assets/img/task-create-page.png" alt="">
    </div>
  </div>
</div>
