<div class="text-xs sm:text-[14px] sm:tracking-normal tracking-[-0.1px] flex gap-3 w-full">
  <ng-container *ngFor="let step of createTaskHeaderService.steps$ | async">
    <div [ngClass]="{
          'text-designSystem-iceberg': step.status === 'inactive',
          'text-designSystem-golden-bell': step.status === 'active',
          'text-designSystem-sushi': step.status === 'completed'
        }"
         class="basis-1/3 grow flex items-center">
      <mat-icon [ngClass]="{}"
                class="text-[18px] w-[18px] h-[18px] mr-1">{{ step.icon }}</mat-icon>
      <div [ngClass]="{
          'text-designSystem-dark-spell font-bold border-b-designSystem-golden-bell': step.status === 'active',
          'border-b-designSystem-iceberg': step.status === 'inactive',
          'border-b-designSystem-sushi': step.status === 'completed'
        }"
           class="pb-1 grow whitespace-nowrap border-b-[2px] mb-5">
        {{ step.label }}
      </div>
    </div>
  </ng-container>
</div>
