<button #ref
        (click)="showDropdown($event)"
        [ngClass]="[buttonStyle]">
  {{nameKey | transloco}}
</button>

<app-task-flow-dialog [reference]="ref" [position]="position">
  <div class="dropdown-panel" *ngIf="!showChoiceExecutor">
    <div class="confirm-title">{{ 'confirm-conditions-title' | transloco}}</div>
    <app-call-to-action (upgradeEmit)="dropdown.hide()"
                        [cameFrom]="'view-offer'"
                        (closeWindowEmit)="showDropdown($event)"
                        *ngIf="!(selectedCompany$ | async)?.additionalFields.isPrime && (selectedCompany$ | async)?.isServiceProvider"
                        [text]="'Upgrade your plan to Prime and reduce commission.'"
                        class="call-to-action">
    </app-call-to-action>
    <div vexScrollbar class="overflow-wrapper">
      <ng-container *ngFor="let sr of serviceRequests">

        <div class="service-request" *ngIf="sr">
          <div class="service-request-info">
            <div class="block-1">
              <div (click)="openPublicInfo(sr?.executorCompany, $event)" class="flex items-baseline"
                   [ngClass]="{linked: isPublicProfilePublished(sr)}"
                   [matTooltip]="(isPublicProfilePublished(sr) ? ('view_company_profile' | transloco) : null)">
                <svg class="block-1_link_svg" *ngIf="isPublicProfilePublished(sr)" width="14" height="12"
                     viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.3704 5.66773V10.875C10.3704 11.4963 9.87292 12 9.25926 12H1.11111C0.497454 12 0 11.4963 0 10.875V2.625C0 2.00367 0.497454 1.5 1.11111 1.5H8.98093C9.2284 1.5 9.35234 1.80295 9.17734 1.98012L8.62178 2.54262C8.56969 2.59536 8.49904 2.62499 8.42537 2.625H1.25C1.21316 2.625 1.17784 2.63982 1.15179 2.66619C1.12574 2.69256 1.11111 2.72833 1.11111 2.76562V10.7344C1.11111 10.7717 1.12574 10.8074 1.15179 10.8338C1.17784 10.8602 1.21316 10.875 1.25 10.875H9.12037C9.15721 10.875 9.19253 10.8602 9.21858 10.8338C9.24463 10.8074 9.25926 10.7717 9.25926 10.7344V6.23023C9.25926 6.15562 9.28852 6.08409 9.34063 6.03136L9.89618 5.46886C10.0712 5.29167 10.3704 5.41716 10.3704 5.66773ZM13.0556 0H9.90776C9.66104 0 9.53648 0.303047 9.71134 0.480117L10.8268 1.60983L4.52581 7.98956C4.41734 8.09939 4.41734 8.27747 4.52581 8.38732L5.04958 8.91764C5.15808 9.02747 5.33396 9.02747 5.44243 8.91764L11.7434 2.53793L12.8591 3.66743C13.0331 3.84352 13.3333 3.71981 13.3333 3.46856V0.28125C13.3333 0.12593 13.209 0 13.0556 0Z"
                    fill="#8997B1" />
                </svg>
                <span class="executor-name">{{sr?.executorCompany?.name}}</span>
              </div>
              <div class="" *ngIf="sr?.executorCompany?.country">
                <img [src]="sr?.executorCompany?.country|countryFlag"
                     matTooltipPosition="above"
                     [matTooltip]="sr?.executorCompany?.country | transloco"
                     class="country-flag">
              </div>
              <app-provider-rating [provider]="sr?.executorCompany"></app-provider-rating>

            </div>
            <div class="md:mt-0 mt-2 isTrusted"
                 [matTooltip]="'Provider has passed an additional iPNOTE verification' | transloco"
                 matTooltipPosition="above"
                 *ngIf="sr?.executorCompany?.additionalFields?.isPrime && sr?.executorCompany.isTrusted">
              <ic-icon class="account-is-trusted" [icIcon]="checkCircle"></ic-icon>
              <span class="whitespace-nowrap">Trusted provider</span>
            </div>
          </div>
          <div class="wrapper-task-info">
            <div class="executor-tasks-info" *ngIf="(sr?.executorCompany?.completedTaskCount === 0); else notNewUser">
              {{'new_user' | transloco}}
            </div>
            <ng-template #notNewUser>
              <div
                class="executor-tasks-info">{{sr?.executorCompany?.completedTaskCount}} {{(sr?.executorCompany?.completedTaskCount > 1 ? 'tasks_for' : 'task_for') | transloco }} {{sr?.executorCompany?.totalPriceForCompletedTasks | currency:'USD':true:'3.0-0'}}</div>
            </ng-template>
            <div class="isCertified" [matTooltip]="'provider_with_certified' | transloco" matTooltipPosition="above"
                 *ngIf="sr?.executorCompany?.isCertified && isPublicProfilePublished(sr)">
              <ic-icon class="account-is-certified" [icIcon]="icAccountStart"></ic-icon>
              <span>Locally certified</span>
            </div>
          </div>
          <div class="request-info" *ngIf="sr?.offer && (sr?.offer.price || sr?.offer.price === 0); else elseBlock">
            <div class="meta">
              <div class="service-request-meta">
                <div class="request-meta" [mtxTooltipClass]="'mtx-tooltip'" [mtxTooltip]="priceDetails">
                  <div class="meta-price" *ngIf="sr?.offer">
                    <mat-icon>local_offer</mat-icon>
                    {{ sr?.offer.basePrice| number:'':"en-US" }}
                    <span *ngIf="sr?.offer.currency">{{ sr?.offer.baseCurrency | lowercase | transloco }}</span>
                  </div>
                  <div class="meta-days" *ngIf="sr?.offer.days">
                    <mat-icon>watch_later</mat-icon>
                    {{sr.offer.days}} {{'days' | transloco}}
                  </div>
                </div>
                <ng-template #priceDetails>
                  <app-price-detail class="" [prices]="sr.offer.prices"
                                    *ngIf="isOfferExists"></app-price-detail>
                </ng-template>
              </div>

              <!-- <div class="service-request-meta-tag">
                 <mat-icon>account_balance_wallet</mat-icon>
                 <span>{{'low-cost' | transloco}}</span>
               </div>-->
            </div>
          </div>
          <ng-template #elseBlock>
            <div class="request-info">
              <div class="meta">
                <div class="service-request-meta">
                  <div class="request-meta">
                    <div class="meta-days">
                      <mat-icon>watch_later</mat-icon>
                      {{'wait-offer' | transloco}}
                    </div>
                  </div>
                </div>
                <!--<div class="service-request-meta-tag">
                  <mat-icon>account_balance_wallet</mat-icon>
                  <span>{{'low-cost' | transloco}}</span>
                </div>-->
              </div>
            </div>
          </ng-template>

          <div class="meta-actions">
            <button *ngIf="sr?.offer && (sr?.offer.price || sr?.offer.price === 0)" class="confirm-btn"
                    (click)="acceptServiceRequest(sr)">
              {{'accept-request' | transloco}}
            </button>
            <button class="conversation-btn" (click)="handleConversationClick(sr)">
              {{'conversation-request' | transloco}}
            </button>
            <button class="decline-btn" (click)="rejectServiceRequest(sr)">
              {{'decline-request' | transloco}}
            </button>
          </div>
        </div>
        <hr class="hr" />
      </ng-container>
    </div>
    <button *ngIf="executors?.length" class="other-provider"
            (click)="showChoiceExecutors()">{{'show_executors' | transloco}}</button>
  </div>

  <div class="dropdown-panel" *ngIf="showChoiceExecutor">

    <div class="confirm-title">{{ 'choice-author' | transloco}}</div>
    <mat-checkbox class="chose-all" (change)="selectAll()"
                  [checked]="allSelected">
      <span class="executor-name">{{"choose_all_service_providers" | transloco}}</span>
    </mat-checkbox>
    <div vexScrollbar class="overflow-wrapper">
      <div *ngIf="isLoading && !executors" class="flex items-center justify-center">
        <mat-spinner [diameter]="45" class="mx-auto"></mat-spinner>
      </div>
      <ng-container *ngFor="let executor of executors">
        <div class="service-request hover" (click)="checkboxSelect(executor)" *ngIf="executor">
          <div class="service-request-info">
            <div class="block-1">
              <mat-checkbox color="primary"
                            (change)="checkboxSelect(executor)"
                            (click)="$event.stopPropagation()"
                            [checked]="alreadyIn(executor)">
              </mat-checkbox>
              <div class="flex items-baseline" (click)="openPublicInfo(executor, $event)"
                   [ngClass]="{linked: executor?.companyPublicInfo.stage === companyPublicEnums.PUBLISHED}">
                <svg class="block-1_link_svg" *ngIf="executor?.companyPublicInfo.stage === companyPublicEnums.PUBLISHED"
                     width="14" height="12" viewBox="0 0 14 12" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.3704 5.66773V10.875C10.3704 11.4963 9.87292 12 9.25926 12H1.11111C0.497454 12 0 11.4963 0 10.875V2.625C0 2.00367 0.497454 1.5 1.11111 1.5H8.98093C9.2284 1.5 9.35234 1.80295 9.17734 1.98012L8.62178 2.54262C8.56969 2.59536 8.49904 2.62499 8.42537 2.625H1.25C1.21316 2.625 1.17784 2.63982 1.15179 2.66619C1.12574 2.69256 1.11111 2.72833 1.11111 2.76562V10.7344C1.11111 10.7717 1.12574 10.8074 1.15179 10.8338C1.17784 10.8602 1.21316 10.875 1.25 10.875H9.12037C9.15721 10.875 9.19253 10.8602 9.21858 10.8338C9.24463 10.8074 9.25926 10.7717 9.25926 10.7344V6.23023C9.25926 6.15562 9.28852 6.08409 9.34063 6.03136L9.89618 5.46886C10.0712 5.29167 10.3704 5.41716 10.3704 5.66773ZM13.0556 0H9.90776C9.66104 0 9.53648 0.303047 9.71134 0.480117L10.8268 1.60983L4.52581 7.98956C4.41734 8.09939 4.41734 8.27747 4.52581 8.38732L5.04958 8.91764C5.15808 9.02747 5.33396 9.02747 5.44243 8.91764L11.7434 2.53793L12.8591 3.66743C13.0331 3.84352 13.3333 3.71981 13.3333 3.46856V0.28125C13.3333 0.12593 13.209 0 13.0556 0Z"
                    fill="#8997B1" />
                </svg>
                <span
                  [matTooltip]="(executor?.companyPublicInfo.stage === companyPublicEnums.PUBLISHED ? ('view_company_profile' | transloco) : null)"
                  class="executor-name"
                >{{executor.name}}
            </span>
              </div>
              <div class="" *ngIf="executor?.country">
                <img [src]="executor?.country|countryFlag"
                     matTooltipPosition="above"
                     [matTooltip]="executor?.country | transloco"
                     class="country-flag">
              </div>
              <div *ngIf="executor.companyPublicInfo.rating !== 0">
                <ic-icon class="icStar" [icIcon]="icStar" [inline]="true"></ic-icon>
                <span class="executor-rating">{{executor.companyPublicInfo.rating | number: '1.0-1'}}</span>
              </div>
              <div class="executor-feedback" *ngIf="executor.companyPublicInfo.ratingCount !== 0">({{executor?.companyPublicInfo?.ratingCount}})</div>
            </div>
            <div class="md:mt-0 mt-2 isTrusted"
                 [matTooltip]="'Provider has passed an additional iPNOTE verification' | transloco"
                 matTooltipPosition="above"
                 *ngIf="executor?.additionalFields?.isPrime && executor?.isTrusted">
              <ic-icon class="account-is-trusted" [icIcon]="checkCircle"></ic-icon>
              <span class="whitespace-nowrap">Trusted provider</span>
            </div>
          </div>
          <div class="wrapper-task-info">
            <div class="executor-tasks-info" *ngIf="(executor.companyPublicInfo.completedTaskCount === 0); else notNewUser">
              {{'new_user' | transloco}}
            </div>
            <ng-template #notNewUser>
              <div
                class="executor-tasks-info">{{executor.companyPublicInfo.completedTaskCount}} {{'tasks_for' | transloco }} {{executor.companyPublicInfo.totalPriceForCompletedTasks | currency}}</div>
            </ng-template>
            <div class="isCertified" [matTooltip]="'provider_with_certified' | transloco" matTooltipPosition="above"
                 *ngIf="executor.isCertified && executor.companyPublicInfo.stage === companyPublicEnums.PUBLISHED">
              <ic-icon class="account-is-certified" [icIcon]="icAccountStart"></ic-icon>
              <span>Locally certified</span>
            </div>
          </div>
        </div>
        <hr class="hr" />
      </ng-container>
    </div>

    <div class="button-panel">
      <button mat-raised-button color="primary" (click)="handleSavePotential(selectedExecutors)"
              [disabled]="!selectedExecutors.length">
        {{'request-a-quote' | transloco}}
      </button>
      <button mat-button *ngIf="serviceRequests?.length > 0" (click)="showOffersPanel()"
              style="padding-left: 15px;">{{'show_service_requests' | transloco}}</button>
    </div>
  </div>
</app-task-flow-dialog>
