export enum PriceDetailsType {
  BASE = 'base',
  WITH_SECOND_CLASS = 'with_second_class',
  WITH_THIRD_CLASS = 'with_third_class',
  FROM_SECOND_TO_FIFTH_CLASSES = 'from_second_to_fifth_classes',
  FIRST_TWO_CLASSES = 'first_two_classes',
  FIRST_THREE_CLASSES = 'first_three_classes',
  FIRST_FIVE_CLASSES = 'first_five_classes',
  FROM_UP_TO = 'from_up_to',
  EMPTY = 'empty',
}
