import { ICompanyEntity } from '@ipnote/interface';
import { Action, createReducer, on } from '@ngrx/store';
import {
  CompanyAllReset,
  CompanyListLoad,
  CompanyListLoadError,
  CompanyListLoadSuccess
} from '../../actions/company.actions';
import { ErrorResponseCreate } from '@ipnote/shared';

export interface StateCompanyList {
  companies: ICompanyEntity[];
  apiData: {
    count: number;
    total: number;
    page: number;
    pageCount: number;
    waitingServerResponse: boolean,
    error: ErrorResponseCreate,
    userId: number
  };
}

export const initialState: StateCompanyList = {
  companies: [],
  apiData: {
    count: null,
    total: null,
    page: null,
    pageCount: null,
    waitingServerResponse: false,
    error: null,
    userId: null
  }
};

const companyListReducer = createReducer(
  initialState,
  on(CompanyAllReset, () => ({
    ...initialState
  })),
  on(CompanyListLoad, (state) => ({
    ...state,
    apiData: {
      ...initialState.apiData,
      waitingServerResponse: true
    }
  })),
  on(CompanyListLoadSuccess, (state, { result, userId }) => ({
    ...state,
    companies: result.data,
    apiData: {
      count: result.count,
      total: result.total,
      page: result.page,
      pageCount: result.pageCount,
      waitingServerResponse: false,
      error: null,
      userId
    }
  })),
  on(CompanyListLoadError, (state, { error }) => ({
    ...state,
    apiData: {
      ...initialState.apiData,
      error
    }
  }))
);

export function companyListReducerDefault(state: StateCompanyList | undefined, action: Action) {
  return companyListReducer(state, action);
}
