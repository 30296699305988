export enum ProductStages {
  GENERATE_IDEA = 'generate_idea',
  NIOKR = 'niokr',
  PILOT = 'pilot',
  PRE_PRODUCTION = 'pre_production',
  MASTER_PRODUCTION = 'master_production',
  BREEDING = 'breeding',
  GROWTH = 'growth',
  MATURITY = 'maturity',
  DECLINE = 'decline',
}

export enum ProductTypes {
  TECHNICAL_TOOL = 'technical_tool',
  TECHNOLOGY = 'technology',
  CONSTRUCTION = 'construction',
  MATERIAL = 'material',
  AUTOMATED_SYSTEM = 'automated_system',
  MANAGEMENT_METHODS = 'management_methods',
}
