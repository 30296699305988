import { ErrorHandler, Injectable } from '@angular/core';
// import {version} from '../../../../environments/version';
import { AppConfiguration } from '../../../configuration/app.configuration';

@Injectable()
export class RavenErrorHandler implements ErrorHandler {
  constructor(private config: AppConfiguration) {
  }

  handleError(error: any) {
    console.error(error.originalError || error);
    /*if (this.config.sentry) {
      const eventId = Sentry.captureException(error.originalError || error);
      Sentry.showReportDialog({ eventId });
    }*/
  }
}
