export enum OIPType {
  TRADEMARK = 'trademark', // Товарный знак
  INVENTION = 'invention', // Изобретение
  UTILITY_MODEL = 'utility_model', // Полезная модель
  INDUSTRIAL_MODEL = 'industrial_model', // Промышленный образец
  CREATION_PLACE = 'creation_place', // Наименование места происхождения товара
  GEO_INDICATION = 'geo_indication', // Географическое указание

  SOFTWARE = 'software', // ПрЭВМ
  DATABASE = 'database', // База данных
  TOPOLOGY = 'topology_integral_scheme', // Топология интегральной микросхемы

  KNOW_HOW = 'know_how', // Ноу-хау
  TRADE_NAME = 'trade_name', // Коммерческое обозначение
  DESIGN_TYPE = 'design_type', // Произведение
}
