import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { enUS, ru } from 'date-fns/locale';
import { IUserEntity } from '@ipnote/interface';

@Injectable({
  providedIn: 'root',
})
export class DateFormatService {
  // company$: BehaviorSubject<ICompanyEntity> = this.companyService.company;
  user$: BehaviorSubject<IUserEntity>;
  // user$: BehaviorSubject<IUserEntity> = this.userService.user;
  languages = {
    ru: { str: 'ru_RU', obj: ru },
    en: { str: 'en_US', obj: enUS },
  };

  constructor() {}

  getFormat(): string {
    // const company = this.company$.value;
    // if (company && company.country && company.country.toUpperCase() === 'US') return 'MM/dd/yy';
    return 'dd-MMM-yyyy';
  }

  getLocale(): string {
    const user = this.user$?.value;
    const userLang = user?.settings?.lang;
    return this.languages[userLang]?.str || 'en_US';
  }

  getFullLocale(): Locale {
    const user = this.user$?.value;
    const userLang = user?.settings?.lang;
    return this.languages[userLang]?.obj || enUS;
  }
}
