export enum FileModels {
  OIP_MODEL = 'OIP',
  FOLDER_FILES = 'FolderFiles',
  TASK_MODEL = 'Task',
  TASK_RESULT_MODEL = 'Task_result',
  MESSAGES_MODEL = 'Messages',
  PRODUCT_MODEL = 'Product',
  INVENTION_MODEL = 'Invention',
  RIA_MODEL = 'RIA',
  COUNTERPARTY_MODEL = 'Counterparty',
  TRANSACTION_MODEL = 'Transaction',
  REMINDER = 'Reminder',
  COMPANY_PUBLIC_INFO = 'CompanyPublicInfo',
}
