import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { filter, map, take } from 'rxjs/operators';
import { StateServerRes } from 'app/store/reducers/user';
import { selectForgotPasswordConfirmState } from 'app/store/selectors/user.selectors';
import { AuthService } from 'app/app-common/services/auth/auth.service';
import { DialogService } from 'app/app-common/services/dialogs/dialog.service';
import { setFormError } from 'app/app-common/utils/set-form-error';
import { UserForgotPasswordConfirm } from 'app/store/actions/user.actions';

@UntilDestroy()
@Component({
  selector: 'app-forgot-password-confirmation',
  templateUrl: './forgot-password-confirmation.component.html',
  styleUrls: ['./forgot-password-confirmation.component.scss']
})
export class ForgotPasswordConfirmationComponent implements OnInit {
  public forgotPasswordStore$: Observable<StateServerRes> = this.store.select(selectForgotPasswordConfirmState);

  confirmForm = new UntypedFormGroup({
    newPassword: new UntypedFormControl('', Validators.minLength(8)),
    token: new UntypedFormControl('', Validators.required)
  });

  inputType = 'password';
  visible = false;

  icVisibility = icVisibility;
  icVisibilityOff = icVisibilityOff;

  constructor(
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private router: Router,
    private auth: AuthService,
    private dialogs: DialogService,
    private store: Store<AppState>
  ) {
    this.forgotPasswordStore$.pipe(
      filter((state) => state.error !== null),
      take(1),
      map((state) => {
        if (typeof state.error.error.message !== 'string') {
          const formatError = setFormError(this.confirmForm, state.error.error.message);
          this.confirmForm = formatError.form;
        } else {
          this.dialogs.error(state.error);
        }
      })
    ).subscribe();
  }

  ngOnInit(): void {
    const hash = this.route.snapshot.params.hash;
    this.confirmForm.controls['token'].setValue(hash);
  }

  send(): void {
    this.store.dispatch(UserForgotPasswordConfirm({ data: this.confirmForm.value }));
  }

  toggleVisibility(): void {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }

}
