import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogService } from '../../services/dialogs/dialog.service';

@Component({
  selector: 'app-subscription-err',
  templateUrl: './subscription-err.component.html',
  styleUrls: ['./subscription-err.component.scss'],
})
export class SubscriptionErrComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SubscriptionErrComponent>,
    private route: ActivatedRoute,
    private router: Router,
    private dialogs: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: { error: HttpErrorResponse; fromTask: boolean; callback: () => void },
  ) {}

  ngOnInit() {}

  decline() {
    this.dialogRef.close(false);
  }

  accept() {
    this.dialogs.subscriptionPricesDialog().subscribe();
    this.dialogRef.close(true);
  }

  saveAsDraft() {
    this.data.callback();
    this.dialogRef.close(true);
  }
}
