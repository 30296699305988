<h2 mat-dialog-title *ngIf="data.title">
  {{data.title}}
  <button mat-raised-button
          *ngIf="unreadCount > 0"
          mat-dialog-close
          color="primary"
          type="button"
          (click)="close()">{{ 'read-all' | transloco }}</button>
</h2>
<mat-dialog-content>
  <mat-nav-list dense *ngIf="data.items && data.items.length">
    <mat-list-item *ngFor="let item of data.items" (click)="goToTask(item.id)">
      <mat-icon class="icon"
                matListIcon
                [class.active]="item.isRead">{{icon(item.meta?.type)}}</mat-icon>
      <h3 matLine><span class="notification-title">{{item.title}}</span></h3>
      <p matLine>{{item.message}}</p>
      <p matLine>{{ item.notifyAt | date: dateFormatService.getFormat() : null : dateFormatService.getLocale() }}</p>
      <mat-icon>arrow_forward</mat-icon>
      <mat-divider [inset]="true"></mat-divider>
    </mat-list-item>
  </mat-nav-list>
  <div class="empty" *ngIf="!data.items || !data.items.length">
    {{ 'notifications-empty' | transloco }}
  </div>
</mat-dialog-content>
