<mat-dialog-content>
  <h2 mat-dialog-title>{{ 'invite' | transloco }}</h2>
  <mat-form-field class="search-select">
    <mat-label>
      {{ 'add-executor' | transloco }}
    </mat-label>
    <mat-select [(ngModel)]="selectCompany">
      <mat-option>
        <ngx-mat-select-search [(ngModel)]="search" (ngModelChange)="getCompanies(search)" [clearSearchInput]=false
                               placeholderLabel="{{ 'search' | transloco}}"
                               noEntriesFoundLabel="{{ 'no_option' | transloco}}">
        </ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let company of companies" [value]="company">
        {{company.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button type="button" color="primary" (click)="save()" [disabled]="!selectCompany">
    {{ 'invite' | transloco }}
  </button>
</mat-dialog-actions>

