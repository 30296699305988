<div [ngClass]="{'bg-designSystem-iceberg': !fileUrl}"
     class="overflow-hidden h-full w-full flex items-center justify-center relative">
  <input [disabled]="disabled" type="file" onclick="this.value=null;" #input (change)="file($event)" style="display: none;"/>
  <img class="h-full w-full" *ngIf="fileUrl" [ngStyle]="{'object-fit': objFit}" [ngClass]="{'enabled': !disabled}" [src]="fileUrl"
       (click)="!disabled && input.click()" alt=""/>
  <div class="label" (click)="input.click()" *ngIf="!fileUrl">
    <ng-content ></ng-content>
  </div>
  <div *ngIf="!disabled" class="img-control hidden absolute items-center justify-center gap-2">
    <button *ngIf="fileUrl" mat-icon-button class="delete" (click)="remove()" type="button">
      <mat-icon>delete</mat-icon>
    </button>
    <button *ngIf="fileUrl" mat-icon-button class="upload" (click)="input.click()" type="button">
      <mat-icon>edit</mat-icon>
    </button>
  </div>

</div>


