import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PreSignedUploadService } from '../pre-signed-upload/pre-signed-upload.service';
import { Observable } from 'rxjs';
import { finalize, map, mergeMap } from 'rxjs/operators';
import { AppConfiguration } from '../../../configuration/app.configuration';
import { IFileEntity } from '@ipnote/interface';
import { SpinnerViewService } from '../spinner/spinner-view.service';
import { FileTagsTypes } from '@ipnote/type';
import { FileModels } from '@ipnote/enum';

@Injectable({
  providedIn: 'root',
})
export class FileManagerService {
  constructor(
    private http: HttpClient,
    private preSignService: PreSignedUploadService,
    private config: AppConfiguration,
    private spinnerService: SpinnerViewService,
  ) {}

  upload(file: File, path?: string): Observable<string> {
    this.spinnerService.start();
    const name = file.name;
    const type = file.type || 'text%2Fplain';
    return this.preSignService.sign(name, type, path).pipe(
      mergeMap((data) => {
        const fileData = new FormData();
        Object.keys(data.fields).forEach((key) => {
          fileData.append(key, data.fields[key]);
        });
        fileData.append('Content-Type', type);
        fileData.append('file', file);
        return this.http
          .post(data.url, fileData, {
            // withCredentials: true
          })
          .pipe(map(() => `https://${data.fields.bucket}/${data.fields.key}`));
      }),
      finalize(() => this.spinnerService.stop()),
    );
  }

  uploadLinkFilePublic(
    fileModel: FileModels,
    modelId: number,
    fileObject,
    tags?: FileTagsTypes[],
    comment?: string,
  ): Observable<IFileEntity> {
    this.spinnerService.start();

    const data = {
      fileObject: fileObject,
      tags: tags,
      name: fileObject.name,
      comment: comment,
    };

    return this.http
      .post<IFileEntity>(`${this.config.filesServerBaseUrl}/attach-to/${fileModel}/${modelId}`, data)
      .pipe(finalize(() => this.spinnerService.stop()));
  }

  uploadPrivate(
    fileModel: FileModels,
    modelId: number,
    file,
    tags?: FileTagsTypes[],
    comment?: string,
  ): Observable<IFileEntity> {
    this.spinnerService.start();
    const formData = new FormData();
    formData.append('file', file);
    if (tags) {
      formData.append('tags', JSON.stringify(tags));
    }
    formData.append('name', file.name);
    if (comment) {
      formData.append('comment', comment);
    }
    return this.http
      .post<IFileEntity>(`${this.config.filesServerBaseUrl}/${fileModel}/${modelId}`, formData, {
        headers: { 'Content-Transfer-Encoding': 'utf-8' },
      })
      .pipe(finalize(() => this.spinnerService.stop()));
    // @TODO: Чтобы сильно не менять существующую модель загрузки файлов, пока от прогресса отказался
    // return this.http
    //   .post<IFileS3>(`${environment.filesServerBaseUrl}/${fileModel}/${modelId}`, formData, {
    //     reportProgress: true,
    //     observe: 'events',
    //   })
    //   .pipe(
    //     map((event) => {
    //       switch (event.type) {
    //         case HttpEventType.UploadProgress:
    //           const progress = Math.round((100 * event.loaded) / event.total);
    //           // return { status: 'progress', message: progress };
    //           break;
    //         case HttpEventType.Response:
    //           return event.body;
    //       }
    //     })
    //   );
  }

  uploadGroupFile(
    files,
    tags: FileTagsTypes[],
    folderName: string,
    comment: string,
    modelId: number,
    model: FileModels,
  ): Observable<any> {
    this.spinnerService.start();
    const formData = new FormData();
    formData.append('tags', JSON.stringify(tags));
    formData.append('folderName', folderName);
    formData.append('comment', comment);
    formData.append('modelId', modelId.toString());
    formData.append('model', model);
    files.forEach((file) => {
      formData.append('files', file, file.name);
    });

    return this.http
      .post<IFileEntity>(`${this.config.baseUrl}/folder-files`, formData)
      .pipe(finalize(() => this.spinnerService.stop()));
  }

  deleteFile(id: number): Observable<any> {
    return this.http.delete(`${this.config.filesServerBaseUrl}/${id}`, { responseType: 'text' });
  }

  previewUrl(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        resolve(fileReader.result as string);
      };
      fileReader.onerror = (err) => {
        reject(err);
      };
      fileReader.readAsDataURL(file);
    });
  }

  uploadPublicInfoExternalFiles(companyPublicInfoId: number, file, tags?: FileTagsTypes[]): Observable<IFileEntity> {
    this.spinnerService.start();
    const formData = new FormData();
    formData.append('file', file);
    if (tags) {
      formData.append('tags', JSON.stringify(tags));
    }
    formData.append('name', file.name);

    return this.http
      .post<IFileEntity>(`${this.config.filesServerBaseUrl}/CompanyPublicInfo/${companyPublicInfoId}`, formData, {
        headers: { 'Content-Transfer-Encoding': 'utf-8' },
      })
      .pipe(finalize(() => this.spinnerService.stop()));
  }
}
