<div class="bg-pattern basis-[381px] h-full" fxLayout="column" fxLayoutAlign="center center">
  <div class="card overflow-hidden p-2">
    <div class="border rounded w-full pt-10 px-6 block">
      <div class="pb-0" fxLayout="column" fxLayoutAlign="center center">
        <div class="fill-current text-center">
          <img class="image" src="../../../../assets/img/new-ipnote-logo.png" />
        </div>
      </div>

      <div [formGroup]="confirmForm" class="py-6" fxLayout="column">
        <div fxFlex="auto" fxLayout="column" class="py-0 my-0">
          <mat-form-field class="mt-5" fxFlex="grow" appearance="outline">
            <mat-label>{{ 'new-password' | transloco }}</mat-label>
            <input [type]="inputType" formControlName="newPassword" matInput required />
            <button (click)="toggleVisibility()" mat-icon-button matSuffix type="button"
                    matTooltip="{{ 'toggle-visibility' | transloco }}">
              <mat-icon *ngIf="visible" [icIcon]="icVisibility"></mat-icon>
              <mat-icon *ngIf="!visible" [icIcon]="icVisibilityOff"></mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="-mt-8 mb-8"><span
          [ngClass]="confirmForm.controls['newPassword'].invalid && confirmForm.controls['newPassword'].touched? 'required red-text':'required'"
          class="more_character text-xs">{{'use_more_characters' | transloco}}</span>
        </div>
        <button (click)="send()" class="btn-icon__spinner mb-8 w-full" color="primary" mat-raised-button type="button"
                [disabled]="confirmForm.invalid || confirmForm.disabled || (forgotPasswordStore$ | async).waitingServerResponse"
        >
          {{ "confirm-new-password" | transloco }}
          <mat-spinner *ngIf="(forgotPasswordStore$ | async).waitingServerResponse" [diameter]="25"></mat-spinner>
        </button>
      </div>
    </div>
  </div>
</div>
