import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-spinner-view',
  templateUrl: './spinner-view.component.html',
  styleUrls: ['./spinner-view.component.scss']
})
export class SpinnerViewComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SpinnerViewComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
  }

}
