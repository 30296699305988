import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from '../../../../app-common/services/base-url/base-url.service';
import { RequestQueryBuilderService } from '../../../../app-common/services/request-query-builder/request-query-builder.service';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Stripe } from 'stripe';
import { concatLatestFrom } from '@ngrx/effects';

type Prog = {
  title;
};

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  constructor(
    private http: HttpClient,
    private baseUrl: BaseUrlService,
    private queryBuilder: RequestQueryBuilderService,
  ) {}

  changeStripeSubscription(params: { title: any; period: string }): Observable<any> {
    return this.baseUrl
      .getBaseUrl('stripe/set-subscription')
      .pipe(mergeMap((url) => this.http.post(url, null, { params })));
  }

  createStripeSubscription(): Observable<any> {
    return this.baseUrl.getBaseUrl('stripe/create-subscription').pipe(mergeMap((url) => this.http.post(url, null, {})));
  }

  getCouponInfo(id: string): Observable<any> {
    return this.baseUrl.getBaseUrl(`stripe/coupons/${id}`).pipe(mergeMap((url) => this.http.get(url)));
  }

  getSubscription(): Observable<Stripe.Subscription> {
    return this.baseUrl
      .getBaseUrl('stripe/subscription')
      .pipe(mergeMap((url) => this.http.get<Stripe.Subscription>(url)));
  }

  getCustomer(): Observable<Stripe.Customer> {
    return this.baseUrl.getBaseUrl('stripe/customer').pipe(mergeMap((url) => this.http.get<Stripe.Customer>(url)));
  }

  cancelSubscription(stripeSubscriptionId: string): Observable<Stripe.Subscription> {
    return this.baseUrl
      .getBaseUrl(`stripe/subscriptions/${stripeSubscriptionId}/cancel-at-period-end`)
      .pipe(mergeMap((url) => this.http.patch<Stripe.Subscription>(url, {})));
  }

  restoreSubscription(stripeSubscriptionId: string): Observable<Stripe.Subscription> {
    return this.baseUrl
      .getBaseUrl(`stripe/subscriptions/${stripeSubscriptionId}/restore`)
      .pipe(mergeMap((url) => this.http.patch<Stripe.Subscription>(url, {})));
  }

  getInvoices(pageSize: number): Observable<Stripe.ApiList<Stripe.Invoice>> {
    return this.baseUrl.getBaseUrl('stripe/invoices').pipe(
      concatLatestFrom(() => [this.queryBuilder.buildRequest({ pageSize })]),
      mergeMap(([url, params]) => this.http.get<Stripe.ApiList<Stripe.Invoice>>(url, { params: { ...params } })),
    );
  }

  patchCustomer(id: string, body: any) {
    return this.baseUrl
      .getBaseUrl(`stripe/customers/${id}`)
      .pipe(mergeMap((url) => this.http.patch<Stripe.Customer>(url, body)));
  }

  getStripeLink(id: number) {
    return this.baseUrl.getBaseUrl(`stripe/account-link/${id}`).pipe(mergeMap((url) => this.http.get(url)));
  }

  attachPaymentMethod(): Observable<{ url: string }> {
    return this.baseUrl
      .getBaseUrl('stripe/attach-payment-method')
      .pipe(mergeMap((url) => this.http.get<{ url: string }>(url)));
  }

  createCustomerSessions(): Observable<{ client_secret: string }> {
    return this.baseUrl
      .getBaseUrl('stripe/customer-sessions')
      .pipe(mergeMap((url) => this.http.get<{ client_secret: string }>(url)));
  }
}
