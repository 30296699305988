import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export enum ICreateTaskHeaderStep {
  TASK_CREATION = 'Task creation',
  SIGN_UP = 'Sign up',
  PROVIDER_SELECTION = 'Provider selection',
}
@Injectable({
  providedIn: 'root',
})
export class CreateTaskHeaderService {
  private _steps = new BehaviorSubject<
    Array<{ step: ICreateTaskHeaderStep; label: string; icon: string; status: 'inactive' | 'active' | 'completed' }>
  >([
    { step: ICreateTaskHeaderStep.TASK_CREATION, label: 'Task creation', icon: 'assignment', status: 'inactive' },
    { step: ICreateTaskHeaderStep.SIGN_UP, label: 'Sign up', icon: 'login', status: 'inactive' },
    {
      step: ICreateTaskHeaderStep.PROVIDER_SELECTION,
      label: 'Provider selection',
      icon: 'contact_emergency',
      status: 'inactive',
    },
  ]);

  public steps$ = this._steps.asObservable();

  public setCreateTaskStep(step: ICreateTaskHeaderStep) {
    const updatedSteps = this._steps.value.map((s) => ({ ...s, status: 'inactive' }));

    if (step === ICreateTaskHeaderStep.SIGN_UP) {
      updatedSteps.find((s) => s.step === ICreateTaskHeaderStep.TASK_CREATION).status = 'completed';
    } else if (step === ICreateTaskHeaderStep.PROVIDER_SELECTION) {
      updatedSteps.find((s) => s.step === ICreateTaskHeaderStep.SIGN_UP).status = 'completed';
      updatedSteps.find((s) => s.step === ICreateTaskHeaderStep.TASK_CREATION).status = 'completed';
    }

    const currentStepIndex = updatedSteps.findIndex((s) => s.step === step);
    updatedSteps[currentStepIndex].status = 'active';
    // @ts-ignore
    this._steps.next(updatedSteps);
  }
}
