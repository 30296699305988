import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AlertDialogComponent } from '../../components/alert-dialog/alert-dialog.component';
import { RenameDialogComponent } from '../../components/rename-dialog/rename-dialog.component';
import { BidChoiceDialogComponent } from '../../components/bid-choice-dialog/bid-choice-dialog.component';
import { PatentChoiceDialogComponent } from '../../components/bid-patent-dialog/patent-choice-dialog.component';
import { NotifyItem } from '../../dataset/NotifyItem';
// import { NotificationsDialogComponent } from '../../components/notifications-dialog/notifications-dialog.component';
import { PriorityDocumentDialogComponent } from '../../components/priority-document-dialog/priority-document-dialog';
import { EditEmailDialogComponent } from '../../components/edit-email-dialog/edit-email-dialog.component';
import { EditPasswordDialogComponent } from '../../components/edit-password-dialog/edit-password-dialog.component';
import { InviteUserDialogComponent } from '../../components/invite-user-dialog/invite-user-dialog.component';
import { UpdatePermissionDialogComponent } from '../../components/update-permission-dialog/update-permission-dialog.component';
import { SubscriptionErrComponent } from '../../components/subscription-err/subscription-err.component';
import { InviteCompanyDialogComponent } from '../../components/invite-company-dialog/invite-company-dialog.component';
import { NotificationsDialogComponent } from '../../components/notifications-dialog/notifications-dialog.component';
import { ChoiceBalanceComponent } from '../../components/choice-balance/choice-balance.component';
import { ChatMessage, ICompanyEntity, IServiceRequestEntity, ITaskEntity } from '@ipnote/interface';
import { DeleteMessageDialogComponent } from '../../components/delete-message-dialog/delete-message-dialog.component';
import { GuideDialogComponent } from '../../components/guide-dialog/guide-dialog.component';
import { ConfirmOfferDialogComponent } from '../../components/confirm-offer-dialog/confirm-offer-dialog.component';
import { TranslocoService } from '@ngneat/transloco';
import { DeleteTaskReasonDialogComponent } from '../../components/delete-task-reason-dialog/delete-task-reason-dialog.component';
import { DeclineOfferDialogComponent } from '../../components/decline-offer-dialog/decline-offer-dialog.component';
import { RejectTaskDialogComponent } from '../../../page-modules/desk/modules/tasks/reject-task-dialog/reject-task-dialog.component';
import { FreePlanComponent } from '../../components/subscription-price-dialogs/free-plan/free-plan.component';
import { CustomerPricesDialogComponent } from '../../components/subscription-price-dialogs/customer-prices-dialog/customer-prices-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { selectStateSelectedCompany } from '#selectors';
import { CompanyTypeEnum } from '@ipnote/enum';
import { ChatAiComponent } from '../../components/dialogs/chat-ai/chat-ai.component';
import { Platform } from '@angular/cdk/platform';
import { EditMessageDialogComponent } from '../../components/edit-message-dialog/edit-message-dialog.component';
import { PricingTableComponent } from '../../components/pricing-table/pricing-table.component';
import { PricingTableType } from '../../dataset/PricingTable.type';
// import { NoMoneyDialogComponent } from '../../../desk/modules/tasks/no-money-dialog/no-money-dialog.component';
// import { ChoiceBalanceComponent } from '../../../desk/modules/tasks/choice-balance/choice-balance.component';
// import { RejectTaskDialogComponent } from '../../../desk/modules/tasks/reject-task-dialog/reject-task-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  public selectedCompany$: Observable<ICompanyEntity> = this.store.select(selectStateSelectedCompany);
  selectedCompany: ICompanyEntity;

  constructor(
    public dialog: MatDialog,
    private store: Store<AppState>,
    private translocoService: TranslocoService,
    private platform: Platform,
  ) {
    this.selectedCompany$.subscribe((company) => {
      this.selectedCompany = company;
    });
  }

  alert(options: { title?: string; message: string; primaryButton?: string }): Promise<void> {
    const ref = this.dialog.open(AlertDialogComponent, {
      data: {
        title: options.title,
        message: options.message,
        primaryButton: options.primaryButton ?? this.translocoService.translate('got_it'),
      },
    });
    return ref.afterClosed().toPromise();
  }

  error(err: HttpErrorResponse): Observable<any> {
    const ref = this.dialog.open(AlertDialogComponent, {
      data: { title: 'Error', message: err?.error?.message, primaryButton: 'Try again' },
    });
    return ref.afterClosed();
  }

  signInError(err: HttpErrorResponse): Observable<any> {
    const ref = this.dialog.open(AlertDialogComponent, {
      data: { title: 'Incorrect data', message: err.error.message, primaryButton: 'ok, let’s try again' },
    });
    return ref.afterClosed();
  }

  confirm(options: {
    title: string;
    message: string;
    primaryButton: string;
    slaveButton: string;
  }): Observable<boolean> {
    return this.dialog
      .open(AlertDialogComponent, {
        data: options,
      })
      .afterClosed();
  }

  rename(data: { title?: string; label: string; value?: string }): Observable<string> {
    return this.dialog
      .open(RenameDialogComponent, {
        data,
        minWidth: '300px',
      })
      .afterClosed();
  }

  bidChoice(data: { title?: string; request?: string; requiredState?: boolean }) {
    return this.dialog
      .open(BidChoiceDialogComponent, {
        data,
        width: '600px',
      })
      .afterClosed();
  }

  priorityChoice(data: { title?: string; request?: string; requiredState?: boolean }) {
    return this.dialog
      .open(PriorityDocumentDialogComponent, {
        data,
        width: '600px',
      })
      .afterClosed();
  }

  patentChoice(data: { title?: string; release?: string; requiredState?: boolean }) {
    return this.dialog
      .open(PatentChoiceDialogComponent, {
        data,
        minWidth: '600px',
      })
      .afterClosed();
  }

  notifications(data: { title?: string; items?: NotifyItem[] }) {
    return this.dialog
      .open(NotificationsDialogComponent, {
        data,
        minWidth: '600px',
      })
      .afterClosed();
  }

  updateEmail(): Observable<{ password: string; email: string }> {
    return this.dialog
      .open(EditEmailDialogComponent, {
        minWidth: '350px',
        maxWidth: '350px',
      })
      .afterClosed();
  }

  updatePassword(): Observable<{ oldPassword: string; newPassword: string }> {
    return this.dialog
      .open(EditPasswordDialogComponent, {
        minWidth: '350px',
        maxWidth: '350px',
      })
      .afterClosed();
  }

  inviteUser(): Observable<{ name: string; email: string; role: string } | { role: 'manager'; userId: number }> {
    return this.dialog
      .open(InviteUserDialogComponent, {
        minWidth: '350px',
        maxWidth: '350px',
      })
      .afterClosed();
  }

  inviteCompany(data: number): Observable<number> {
    return this.dialog
      .open(InviteCompanyDialogComponent, {
        data,
        minWidth: '350px',
        maxWidth: '350px',
      })
      .afterClosed();
  }

  updatePermission(title: string): Observable<{ role: string }> {
    return this.dialog
      .open(UpdatePermissionDialogComponent, {
        data: title,
        minWidth: '350px',
        maxWidth: '350px',
      })
      .afterClosed();
  }

  updateSubscription(err: HttpErrorResponse, fromTask: boolean, callback: () => void) {
    const ref = this.dialog.open(SubscriptionErrComponent, {
      data: { error: err, fromTask, callback },
      minWidth: '450px',
      maxWidth: '470px',
      //panelClass: 'border-overlay'
    });
    return ref.afterClosed();
  }

  // noMoneyDialog() {
  //   @TODO
  //   return this.dialog
  //     .open(NoMoneyDialogComponent, {
  //       minWidth: '490px',
  //       maxWidth: '185px',
  //     })
  //     .afterClosed();
  // }

  choiceBalance() {
    // @TODO
    return this.dialog
      .open(ChoiceBalanceComponent, {
        minWidth: '490px',
        maxWidth: '185px',
      })
      .afterClosed();
  }

  rejectTask(title: string) {
    // @TODO
    // return this.dialog
    //   .open(RejectTaskDialogComponent, {
    //     minWidth: '490px',
    //     maxWidth: '185px',
    //     data: { title },
    //   })
    //   .afterClosed();
  }

  deleteMessageDialog(message: ChatMessage): Observable<boolean> {
    return this.dialog
      .open(DeleteMessageDialogComponent, {
        data: message,
        minWidth: '423px',
      })
      .afterClosed();
  }
  editMessageDialog(message: ChatMessage): Observable<boolean> {
    return this.dialog
      .open(EditMessageDialogComponent, {
        data: message,
        width: '600px',
      })
      .afterClosed();
  }

  guideDialog(options: { id: string; title: string }): Observable<boolean> {
    return this.dialog
      .open(GuideDialogComponent, {
        data: options,
        minWidth: '894px',
        minHeight: '573px',
      })
      .afterClosed();
  }

  confirmOffer(companyName: string): Observable<boolean> {
    return this.dialog
      .open(ConfirmOfferDialogComponent, {
        data: { name: companyName },
        width: '555px',
        panelClass: 'confirm-offer-dialog',
      })
      .afterClosed();
  }

  deleteTaskDialog(task) {
    return this.dialog
      .open(DeleteTaskReasonDialogComponent, {
        data: { task },
        width: '555px',
        panelClass: 'delete-task-reason-dialog',
      })
      .afterClosed();
  }

  declineOfferDialog(serviceRequest: IServiceRequestEntity) {
    return this.dialog
      .open(DeclineOfferDialogComponent, {
        data: { serviceRequest },
        width: '555px',
        panelClass: 'decline-Offer-dialog',
      })
      .afterClosed();
  }

  rejectTaskDialog(task: ITaskEntity) {
    return this.dialog
      .open(RejectTaskDialogComponent, {
        data: { task },
        width: '555px',
        panelClass: 'reject-task-dialog',
      })
      .afterClosed();
  }

  subscriptionPricesDialog() {
    return this.selectedCompany.type === CompanyTypeEnum.PROVIDER
      ? this.pricingTable('PrimeProvider', '1380px', '1400px')
      : this.pricingTable('PrimeCustomer', '1380px', '1400px');
  }

  private customerPricesDialog() {
    return this.dialog
      .open(CustomerPricesDialogComponent, {
        data: {},
      })
      .afterClosed();
  }

  public providerPricesDialog() {
    return this.dialog
      .open(FreePlanComponent, {
        data: {},
        width: '696px',
        maxHeight: '850px',
      })
      .afterClosed();
  }

  public chatAi(lastChat, openChat?: any) {
    const config =
      window.innerWidth < 800
        ? {
            data: {
              lastChat,
              openChat,
            },
            position: {
              left: '0',
              top: '0',
              right: '0',
            },
            height: '100%',
            width: '100vw',
            panelClass: 'full-screen-modal',
          }
        : {
            data: {
              lastChat,
              openChat,
            },
            width: '700px',
            height: '800px',
            panelClass: 'ai-chat-dialog',
            disableClose: true,
          };

    return this.dialog.open(ChatAiComponent, config).afterClosed();
  }

  public pricingTable(pricingTable: PricingTableType, width: string, minWidth = '1000') {
    const config = {
      data: {
        pricingTable,
      },
      width,
      minWidth,
      maxWidth: '100vw',
      minHeight: '4000',
      disableClose: true,
    };
    return this.dialog.open(PricingTableComponent, config).afterClosed();
  }
}
