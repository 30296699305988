import { OipStageColors } from './enums/oip-colors.enum';
import { Actions, CustomActions } from './enums/oip-actions.enum';
import { FolderTypes, InputFolderTypes, OutputFolderTypes } from '../file/file-tags.types';
import { OIPStage } from './enums/oip-stages.enum';
import { OIPSubStage } from './enums/oip-sub-stages.enum';

// const denial = {
//   [OIPSubStage.DENIAL]: {
//     field: {
//       color: OipStageColors.ERROR,
//     },
//     bar: {
//       color: OipStageColors.ERROR,
//       description: 'start_denial_description',
//       actions: [
//         {
//           name: 'start_denial',
//           action: Actions.CUSTOM_ACTION,
//           actionForExecute: CustomActions.START_DENIAL,
//         },
//         {
//           name: 'work_stop',
//           action: Actions.CUSTOM_ACTION,
//           actionForExecute: CustomActions.DENIAL_STOP,
//         },
//       ],
//     },
//   },
//   [OIPSubStage.DENIAL_DISPUTE]: {
//     field: {
//       color: OipStageColors.WARNING,
//     },
//     bar: {
//       color: OipStageColors.WARNING,
//       description: 'denial_dispute_description',
//       actions: [
//         {
//           name: 'positive',
//           action: Actions.ADD_FILE,
//           type: FolderTypes.INPUT,
//           subType: InputFolderTypes.FIPS_DECISION,
//         },
//         {
//           name: 'negative',
//           action: Actions.ADD_FILE,
//           type: FolderTypes.INPUT,
//           subType: InputFolderTypes.FIPS_DENIED,
//         },
//       ],
//     },
//   },
//   [OIPSubStage.DO_NOT_TRACK]: {
//     field: {
//       color: OipStageColors.CANCEL,
//     },
//     bar: {
//       color: OipStageColors.CANCEL,
//       description: 'work_cancel',
//       actions: [
//         {
//           name: 'work_return',
//           action: Actions.CUSTOM_ACTION,
//           actionForExecute: CustomActions.DENIAL_RETURN,
//         },
//       ],
//     },
//   },
//   [OIPSubStage.FINALLY_DENIAL]: {
//     field: {
//       color: OipStageColors.CANCEL,
//     },
//     bar: {
//       color: OipStageColors.CANCEL,
//       description: 'finally_denial_description',
//       actions: [],
//     },
//   },
// };

// const withdraw = {
//   [OIPSubStage.WITHDRAW]: {
//     field: {
//       color: OipStageColors.ERROR,
//     },
//     bar: {
//       color: OipStageColors.ERROR,
//       description: 'document_recover_description',
//       actions: [
//         {
//           name: 'start_recovery',
//           action: Actions.CUSTOM_ACTION,
//           actionForExecute: CustomActions.START_WITHDRAW,
//         },
//         {
//           name: 'work_stop',
//           action: Actions.CUSTOM_ACTION,
//           actionForExecute: CustomActions.WITHDRAW_STOP,
//         },
//       ],
//     },
//   },
//   [OIPSubStage.WITHDRAW_DISPUTE]: {
//     field: {
//       color: OipStageColors.WARNING,
//     },
//     bar: {
//       color: OipStageColors.WARNING,
//       description: 'withdraw_dispute_description',
//       actions: [
//         {
//           name: 'positive',
//           action: Actions.ADD_FILE,
//           type: FolderTypes.INPUT,
//           subType: InputFolderTypes.WITHDRAW_DECISION,
//         },
//         {
//           name: 'negative',
//           action: Actions.ADD_FILE,
//           type: FolderTypes.INPUT,
//           subType: InputFolderTypes.WITHDRAW_DENIED,
//         },
//       ],
//     },
//   },
//   [OIPSubStage.DO_NOT_TRACK]: {
//     field: {
//       color: OipStageColors.CANCEL,
//     },
//     bar: {
//       color: OipStageColors.CANCEL,
//       description: 'work_cancel',
//       actions: [
//         {
//           name: 'work_return',
//           action: Actions.CUSTOM_ACTION,
//           actionForExecute: CustomActions.WITHDRAW_RETURN,
//         },
//       ],
//     },
//   },
//   [OIPSubStage.FINALLY_WITHDRAWN]: {
//     field: {
//       color: OipStageColors.CANCEL,
//     },
//     bar: {
//       color: OipStageColors.CANCEL,
//       description: 'finally_withdrawn_description',
//       actions: [],
//     },
//   },
// };

export const stageAttributes = {
  [OIPStage.OPPOSITION_PERIOD]: {
    [OIPSubStage.START]: {
      field: {
        color: OipStageColors.SUCCESS,
      },
      bar: {
        color: OipStageColors.INFO,
        description: 'request_sent_description',
        actions: [
          {
            name: 'attach',
            action: Actions.ADD_FILE,
            type: FolderTypes.INPUT,
            subType: InputFolderTypes.ADMISSION_NOTICE,
          },
          {
            name: 'work_stop',
            action: Actions.CUSTOM_ACTION,
            actionForExecute: CustomActions.STOP,
          },
        ],
      },
    },
    [OIPSubStage.DO_NOT_TRACK]: {
      field: {
        color: OipStageColors.CANCEL,
      },
      bar: {
        color: OipStageColors.CANCEL,
        description: 'work_cancel',
        actions: [
          {
            name: 'work_return',
            action: Actions.CUSTOM_ACTION,
            actionForExecute: CustomActions.RETURN,
          },
        ],
      },
    },
  },
  [OIPStage.REQUEST]: {
    [OIPSubStage.START]: {
      field: {
        color: OipStageColors.SUCCESS,
      },
      bar: {
        color: OipStageColors.INFO,
        description: 'request_sent_description',
        actions: [
          {
            name: 'attach',
            action: Actions.ADD_FILE,
            type: FolderTypes.INPUT,
            subType: InputFolderTypes.ADMISSION_NOTICE,
          },
          {
            name: 'work_stop',
            action: Actions.CUSTOM_ACTION,
            actionForExecute: CustomActions.STOP,
          },
        ],
      },
    },
    [OIPSubStage.DO_NOT_TRACK]: {
      field: {
        color: OipStageColors.CANCEL,
      },
      bar: {
        color: OipStageColors.CANCEL,
        description: 'work_cancel',
        actions: [
          {
            name: 'work_return',
            action: Actions.CUSTOM_ACTION,
            actionForExecute: CustomActions.RETURN,
          },
        ],
      },
    },
  },
  [OIPStage.EXAMINATION]: {
    [OIPSubStage.START]: {
      field: {
        color: OipStageColors.SUCCESS,
      },
      bar: {
        color: OipStageColors.INFO,
        description: 'examination_start_description',
        actions: [
          {
            name: 'attach',
            action: Actions.ADD_FILE,
            type: FolderTypes.INPUT,
            subType: InputFolderTypes.REGISTRATION_DECISION,
          },
          {
            name: 'work_stop',
            action: Actions.CUSTOM_ACTION,
            actionForExecute: CustomActions.STOP,
          },
        ],
      },
    },
    [OIPSubStage.SUBSTANTIVE_REQUEST]: {
      field: {
        color: OipStageColors.WARNING,
      },
      bar: {
        color: OipStageColors.WARNING,
        description: 'substantive_examination_description',
        actions: [
          {
            name: 'attach',
            action: Actions.ADD_FILE,
            type: FolderTypes.OUTPUT,
            subType: OutputFolderTypes.SUBSTANTIVE_REQUEST_RESPONSE,
          },
          {
            name: 'work_stop',
            action: Actions.CUSTOM_ACTION,
            actionForExecute: CustomActions.STOP,
          },
        ],
      },
    },
    [OIPSubStage.FORMAL_EXAMINATION_REQUEST]: {
      field: {
        color: OipStageColors.ERROR,
      },
      bar: {
        color: OipStageColors.ERROR,
        description: 'formal_examination_description',
        actions: [
          {
            name: 'attach',
            action: Actions.ADD_FILE,
            type: FolderTypes.OUTPUT,
            subType: OutputFolderTypes.SUBSTANTIVE_REQUEST_RESPONSE,
          },
          {
            name: 'work_stop',
            action: Actions.CUSTOM_ACTION,
            actionForExecute: CustomActions.STOP,
          },
        ],
      },
    },
    [OIPSubStage.DO_NOT_TRACK]: {
      field: {
        color: OipStageColors.CANCEL,
      },
      bar: {
        color: OipStageColors.CANCEL,
        description: 'work_cancel',
        actions: [
          {
            name: 'work_return',
            action: Actions.CUSTOM_ACTION,
            actionForExecute: CustomActions.RETURN,
          },
        ],
      },
    },
  },
  [OIPStage.GET_DOCUMENTS]: {
    [OIPSubStage.START]: {
      field: {
        color: OipStageColors.INFO,
      },
      bar: {
        color: OipStageColors.INFO,
        description: 'guard_document_attach_description',
        actions: [
          {
            name: 'attach',
            action: Actions.ADD_FILE,
            type: FolderTypes.INPUT,
            subType: InputFolderTypes.SECURITY_DOCUMENT,
          },
          {
            name: 'work_stop',
            action: Actions.CUSTOM_ACTION,
            actionForExecute: CustomActions.STOP,
          },
        ],
      },
    },
    [OIPSubStage.DO_NOT_TRACK]: {
      field: {
        color: OipStageColors.CANCEL,
      },
      bar: {
        color: OipStageColors.CANCEL,
        description: 'work_cancel',
        actions: [
          {
            name: 'work_return',
            action: Actions.CUSTOM_ACTION,
            actionForExecute: CustomActions.RETURN,
          },
        ],
      },
    },
  },
  [OIPStage.RECEIVED]: {
    [OIPSubStage.START]: {
      field: {
        color: OipStageColors.SUCCESS,
      },
      bar: {
        color: OipStageColors.SUCCESS,
        description: 'continue_to_support_description',
        actions: [
          {
            name: 'work_stop',
            action: Actions.CUSTOM_ACTION,
            actionForExecute: CustomActions.STOP,
          },
        ],
      },
    },
    [OIPSubStage.DO_NOT_TRACK]: {
      field: {
        color: OipStageColors.CANCEL,
      },
      bar: {
        color: OipStageColors.CANCEL,
        description: 'work_cancel',
        actions: [
          {
            name: 'work_return',
            action: Actions.CUSTOM_ACTION,
            actionForExecute: CustomActions.RETURN,
          },
        ],
      },
    },
  },
};
