import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { BaseUrlService } from '../../../../app-common/services/base-url/base-url.service';
import { IUserEntity } from '@ipnote/interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: BehaviorSubject<IUserEntity> = new BehaviorSubject(null);

  constructor(private http: HttpClient, private baseUrl: BaseUrlService) {
  }
}
