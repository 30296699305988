import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.states';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { selectStateUserInfo } from '../../store/selectors';
import { PermissionType } from '@ipnote/type';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  constructor(
    private store: Store<AppState>
  ) {
  }

  public checkRole(permissions: PermissionType[]): Observable<boolean> {
    return this.store.select(selectStateUserInfo).pipe(
      map(userState => {
        if (!userState) {
          return false;
        }
        return permissions.every((np) => userState?.flatPermissions.includes(np));
      })
    );
  }

}
