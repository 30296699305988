export enum Territory {
  'EUROPE' = 'Europe',
  'ASIA' = 'Asia',
  'EURASIA' = 'Eurasia',
  'AFRICA' = 'Africa',
  'ARABIC' = 'Arabic',
  'BENELUX' = 'Benelux',
  'WORLD' = 'World',
  'EUIPO' = 'EUIPO',
}
