<div @fadeInUp class="basis-[381px] h-full" fxLayout="column" fxLayoutAlign="center center">
  <ipnote-first-page-header></ipnote-first-page-header>

  <div class="overflow-hidden">
    <div class="w-full block">
      <div class="mt-2">
        <h4
          class="body-2 text-secondary m-0"
          *ngIf="!(forgotPasswordStore$ | async).isSendingRecoveryPassword || (forgotPasswordStore$ | async).error"
        >
          {{ 'enter-your-email-for-password-recovery' | transloco }}
        </h4>
        <h4
          class="body-2 text-secondary m-0 text-center"
          *ngIf="(forgotPasswordStore$ | async).isSendingRecoveryPassword && !(forgotPasswordStore$ | async).error"
          [innerHTML]="'form_forgot_password_success_sending' | transloco : { seconds: seconds | async }"
        ></h4>
      </div>

      <div [formGroup]="form" class="mt-4" fxLayout="column" fxLayoutGap="16px">
        <div
          fxFlex="auto"
          fxLayout="column"
          *ngIf="!(forgotPasswordStore$ | async).isSendingRecoveryPassword || (forgotPasswordStore$ | async).error"
        >
          <mat-form-field fxFlex="grow">
            <mat-label>{{ 'e-mail' | transloco }}</mat-label>
            <input formControlName="email" matInput required />
            <mat-error *ngIf="form.get('email').hasError('required')">
              {{ 'we-cant-recover-your-password-without-your-email' | transloco }}</mat-error
            >
          </mat-form-field>
        </div>
        <button
          (click)="send()"
          color="primary"
          mat-raised-button
          type="button"
          [disabled]="form.invalid || (forgotPasswordStore$ | async).waitingServerResponse"
          *ngIf="!(forgotPasswordStore$ | async).isSendingRecoveryPassword || (forgotPasswordStore$ | async).error"
          class="btn-icon__spinner mb-8 w-full"
        >
          {{ 'recover_password' | transloco }}
          <mat-spinner *ngIf="(forgotPasswordStore$ | async).waitingServerResponse" [diameter]="25"></mat-spinner>
        </button>
        <div fxFlex="auto" fxLayout="row" class="pb-8" fxLayoutAlign="center">
          <span class="pr-1">{{ 'Return to' | transloco }}</span
          ><br />
          <a [routerLink]="['/auth/log-in']">{{ 'log_in' | transloco }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
